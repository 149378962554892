import React, { Component } from "react";
// lidar com criptografias Base64
// import {decode as base64_decode, encode as base64_encode} from 'base-64';
// lidar com criptografias MD5
// eslint-disable-next-line no-undef
const md5 = require("md5");
// reactstrap components
import { Button, Col, TabContent, TabPane, Row } from "reactstrap";

//componentes
import { getUserIdFromSession } from "../../services/utils";
import BuscaCliente from "views/Clientes/BuscaCliente";
import SimuladorCliente from "./Cliente/SimuladorCliente";
import SimuladorValidacao from "./Validacao/SimuladorValidacao";
import ProdutosListagem from "./Produtos/ProdutosListagem";
import SimuladorVerbas from "./Verbas/SimuladorVerbas";
import { SimuladorResumo } from "./Resumo/SimuladorResumo";

export default class SimuladorBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idUsuario: getUserIdFromSession(sessionStorage.getItem(md5("CODUSU"))),
    };
  }

  render() {
    const {
      activeTab,
      iniciouSimulador,
      cabNuSim,
      cabNuSimCli,
      cabCodParc,
      cabCargaCompartilhada,
      cabStatusSimulacao,
      cabImcGeral,
      cabCliente,
      cabOptionEmpresa,
      cabOptionOperLogistico,
      cabEmpresa,
      cabOptionTipoPagDescarga,
      cabVlrDegustacao,
      cabObservacao,
      cabOrdemCompra,
      cabOperLogistico,
      cabTipoPagDescarga,
      cabVlrDescarga,
      cabProdutos,
      cabVerbas,
      novaVerba,
      tabNames,
      cabResume,
      cabClientesBusca,
      cabOptionTipoNegociacao,
      ////
      handleSetCabResume,
      handleSetCabStatusSimulacao,
      handleSetCabCargaCompartilhada,
      handleGoToProduto,
      handleSetCabNuSim,
      handleSetCabNuSimCli,
      handleSetCabCliente,
      handleSetCabOptionEmpresa,
      handleSetCabOptionOperLogistico,
      handleSetCabOptionTipoPagDescarga,
      handleSetCabImcGeral,
      handleSetCabCodParc,
      handleSetCabEmpresa,
      handleSetCabOperLogistico,
      handleSetCabTipoPagDescarga,
      handleSetCabVlrDescarga,
      handleSetCabVlrDegustacao,
      handleSetCabObservacao,
      handleSetCabOrdemCompra,
      handleNovaVerba,
      handleSetCabProduto,
      handleSetCabVerbas,
      handleSetCabClientesBusca,
      handleSetCabOptionTipoNegociacao,
      ////// SET VALIDATION
      handleSetCabValidacaoProduto,
      handleSetCabValidacaoCliente,
      handleSetCabValidacaoFrete,
      handleSetCabValidacaoVerba,
      //////// GET VALIDATION
      cabValidacaoProduto,
      cabValidacaoCliente,
      cabValidacaoFrete,
      cabValidacaoVerba,
      handleAddSimCli,
      //
    } = this.props;

    return (
      <>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={tabNames[0]}>
            {cabCodParc === null && cabNuSimCli === null ? (
              <BuscaCliente
                setCabCodParc={handleSetCabCodParc}
                setCabClientes={handleSetCabClientesBusca}
                getCabClientes={cabClientesBusca}
                handleAddSimCli={handleAddSimCli}
              /> //setCabCodParc
            ) : (
              <>
                {/* cliente que foi incluído no simulador não pode ser alterado, precisa ser excluído caso não seja este que irá compor a simulação */}
                {/* alteração simplória implica em diversas partes, exemplo: Tabela de Preço/Produtos; Logística e N outras possibilidades */}
                {!iniciouSimulador ? (
                  <Row>
                    <Col>
                      <Button
                        color="primary"
                        className="float-right"
                        onClick={() => handleSetCabCodParc(null)}
                      >
                        Alterar Cliente
                      </Button>
                    </Col>
                  </Row>
                ) : null}
                <Col>
                  <SimuladorCliente
                    //Sets
                    setCabNuSim={handleSetCabNuSim}
                    setCabNuSimCli={handleSetCabNuSimCli}
                    setCabCliente={handleSetCabCliente}
                    setCabEmpresa={handleSetCabEmpresa}
                    setCabOptionEmpresa={handleSetCabOptionEmpresa}
                    setCabOptionOperLogistico={handleSetCabOptionOperLogistico}
                    setCabOperLogistico={handleSetCabOperLogistico}
                    setCabOptionTipoPagDescarga={
                      handleSetCabOptionTipoPagDescarga
                    }
                    setCabTipoPagDescarga={handleSetCabTipoPagDescarga}
                    setCabVlrDescarga={handleSetCabVlrDescarga}
                    setCabVlrDegustacao={handleSetCabVlrDegustacao}
                    setCabObservacao={handleSetCabObservacao}
                    setCabOrdemCompra={handleSetCabOrdemCompra}
                    setCabImcGeral={handleSetCabImcGeral}
                    setCabStatusSimulacao={handleSetCabStatusSimulacao}
                    setCabCargaCompartilhada={handleSetCabCargaCompartilhada}
                    setCabResume={handleSetCabResume}
                    setCabProduto={handleSetCabProduto}
                    setCabOptionTipoNegociacao={
                      handleSetCabOptionTipoNegociacao
                    }
                    ////// SET VALIDATION
                    setCabValidacaoProduto={handleSetCabValidacaoProduto}
                    setCabValidacaoCliente={handleSetCabValidacaoCliente}
                    setCabValidacaoFrete={handleSetCabValidacaoFrete}
                    setCabValidacaoVerba={handleSetCabValidacaoVerba}
                    //////// GET VALIDATION
                    cabValidacaoProduto={cabValidacaoProduto}
                    cabValidacaoCliente={cabValidacaoCliente}
                    cabValidacaoFrete={cabValidacaoFrete}
                    cabValidacaoVerba={cabValidacaoVerba}
                    //Gets
                    getCabOptionTipoNegociacao={cabOptionTipoNegociacao}
                    getCabCodParc={cabCodParc}
                    getCabNuSim={cabNuSim}
                    getCabNuSimCli={cabNuSimCli}
                    getCabCliente={cabCliente}
                    getCabEmpresa={cabEmpresa}
                    getCabOptionEmpresa={cabOptionEmpresa}
                    getCabOperLogistico={cabOperLogistico}
                    getCabOptionOperLogistico={cabOptionOperLogistico}
                    getCabTipoPagDescarga={cabTipoPagDescarga}
                    getCabOptionTipoPagDescarga={cabOptionTipoPagDescarga}
                    getCabVlrDescarga={cabVlrDescarga}
                    getCabVlrDegustacao={cabVlrDegustacao}
                    getCabObservacao={cabObservacao}
                    getCabOrdemCompra={cabOrdemCompra}
                    getCabCargaCompartilhada={cabCargaCompartilhada}
                    getCabStatusSimulacao={cabStatusSimulacao}
                    getCabImcGeral={cabImcGeral}
                    //outros
                    goToProduto={handleGoToProduto}
                  />
                </Col>
              </>
            )}
          </TabPane>
          <TabPane tabId={tabNames[1]}>
            {cabNuSimCli > 0 ? (
              // ? <ProdutosCarregados getNuSim={cabNuSim} getNuSimCli={cabNuSimCli} />
              <ProdutosListagem
                setCabImcGeral={handleSetCabImcGeral}
                handleSetCabProduto={handleSetCabProduto}
                setCabResume={handleSetCabResume}
                ///////////////////
                getCabNuSim={cabNuSim}
                getCabNuSimCli={cabNuSimCli}
                getCabProdutos={cabProdutos}
                getCabStatusSimulacao={cabStatusSimulacao}
                getCabCargaCompartilhada={cabCargaCompartilhada}
                ////// SET VALIDATION
                setCabValidacaoProduto={handleSetCabValidacaoProduto}
                setCabValidacaoCliente={handleSetCabValidacaoCliente}
                setCabValidacaoFrete={handleSetCabValidacaoFrete}
                setCabValidacaoVerba={handleSetCabValidacaoVerba}
                //////// GET VALIDATION
                cabValidacaoProduto={cabValidacaoProduto}
                cabValidacaoCliente={cabValidacaoCliente}
                cabValidacaoFrete={cabValidacaoFrete}
                cabValidacaoVerba={cabValidacaoVerba}
              />
            ) : null}
          </TabPane>
          <TabPane tabId={tabNames[2]}>
            {cabNuSimCli > 0 ? (
              <SimuladorVerbas
                getCabNuSim={cabNuSim}
                getCabNuSimCli={cabNuSimCli}
                setNovaVerba={handleNovaVerba}
                getNovaVerba={novaVerba}
                setCabVerbas={handleSetCabVerbas}
                getCabVerbas={cabVerbas}
                getCabStatusSimulacao={cabStatusSimulacao}
                getCabCargaCompartilhada={cabCargaCompartilhada}
                ////// SET VALIDATION
                setCabValidacaoProduto={handleSetCabValidacaoProduto}
                setCabValidacaoCliente={handleSetCabValidacaoCliente}
                setCabValidacaoFrete={handleSetCabValidacaoFrete}
                setCabValidacaoVerba={handleSetCabValidacaoVerba}
                setCabImcGeral={handleSetCabImcGeral}
                //////// GET VALIDATION
                cabValidacaoProduto={cabValidacaoProduto}
                cabValidacaoCliente={cabValidacaoCliente}
                cabValidacaoFrete={cabValidacaoFrete}
                cabValidacaoVerba={cabValidacaoVerba}
              />
            ) : null}
          </TabPane>
          <TabPane tabId={tabNames[3]}>
            {cabNuSimCli > 0 ? (
              <SimuladorValidacao
                getCabNuSim={cabNuSim}
                getCabNuSimCli={cabNuSimCli}
                getCabStatusSimulacao={cabStatusSimulacao}
                getCabCargaCompartilhada={cabCargaCompartilhada}
                ////// SET VALIDATION
                setCabValidacaoProduto={handleSetCabValidacaoProduto}
                setCabValidacaoCliente={handleSetCabValidacaoCliente}
                setCabValidacaoFrete={handleSetCabValidacaoFrete}
                setCabValidacaoVerba={handleSetCabValidacaoVerba}
                //////// GET VALIDATION
                cabValidacaoProduto={cabValidacaoProduto}
                cabValidacaoCliente={cabValidacaoCliente}
                cabValidacaoFrete={cabValidacaoFrete}
                cabValidacaoVerba={cabValidacaoVerba}
              />
            ) : null}
          </TabPane>
          <TabPane tabId={tabNames[4]} key={4}>
            {cabNuSimCli > 0 ? (
              <SimuladorResumo
                cabNuSim={cabNuSim}
                cabNuSimCli={cabNuSimCli}
                cabResume={cabResume}
                ////////
                handleSetCabResume={handleSetCabResume}
              />
            ) : null}
          </TabPane>
        </TabContent>
      </>
    );
  }
}
