import React from "react";
// lida com as requisições
import { api } from "services/api";
// lida com as toast/alertas
import { toast } from "react-toastify";
// lidar com criptografias MD5
// eslint-disable-next-line no-undef
const md5 = require("md5");
// reactstrap components
import { Button, Col, Table } from "reactstrap";

class BuscaVerba extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: "",
      verbas: [],
      loading: false,
      selected: false,
      idUsuario: sessionStorage.getItem(md5("CODUSU")),
    };
  }

  async componentDidMount() {
    try {
      const response = await api.get(
        `simulador-verba-manual/${this.state.idUsuario}`
      );
      if (response.data?.statusApi == 1) {
        this.setState({
          verbas: response.data.negPontuais,
        });
      } else {
        //console.log(response.data);
        toast.error(response.data.message, {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        });
      }
    } catch (error) {
      toast.error(
        `Não foi possível conectar ao servidor, atualize a página e tente novamente. \n#BVerba x01 ${error.message}`,
        {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        }
      );
      // notify("tc","danger","tim-icons icon-simple-remove",);
      console.error("error: ", error);
    }
  }

  render() {
    return (
      <Col>
        <Table responsive>
          <thead>
            <tr>
              <th className="text-center">Cód.</th>
              <th className="text-left">Descrição</th>
              <th className="text-left">Natureza</th>
              <th className="text-right">
                <i className="fa fa-bolt"></i>
                {"ㅤ"}
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.verbas.map((data, index) => {
              return (
                <tr key={index}>
                  <td className="text-center">{data.IDTIPONEG}</td>
                  <td className="text-left">{data.DESCRNEG}</td>
                  <td className="text-left">{data.DESCRNAT}</td>
                  <td className="text-right">
                    <Button
                      className="btn-icon btn-round"
                      color="primary"
                      size="sm"
                      onClick={() =>
                        this.props.setVerbaSel(this.state.verbas[index])
                      }
                      disabled={this.state.selected}
                    >
                      <i className="fa fa-share"></i>
                    </Button>
                    {` `}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Col>
    );
  }
}

export default BuscaVerba;
