import React from "react";
// eslint-disable-next-line no-undef
const md5 = require("md5");
// reactstrap components
import { Col } from "reactstrap";
import CollapseValidaProduto from "./CollapseValidaProduto";
import CollapseValidaCliente from "./CollapseValidaCliente";
import CollapseValidaFrete from "./CollapseValidaFrete";
import CollapseValidaVerba from "./CollapseValidaVerba";

class SimuladorValidacao extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openedCollapses: [],
      idUsuario: sessionStorage.getItem(md5("CODUSU")),
    };
  }
  collapsesToggle(collapse) {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: [],
      });
    } else {
      this.setState({
        openedCollapses: [collapse],
      });
    }
  }
  render() {
    const {
      ////// SET VALIDATION
      // setCabValidacaoProduto,
      // setCabValidacaoCliente,
      // setCabValidacaoFrete,
      // setCabValidacaoVerba,
      //////// GET VALIDATION
      cabValidacaoProduto,
      cabValidacaoCliente,
      cabValidacaoFrete,
      cabValidacaoVerba,
    } = this.props;

    return (
      <Col style={{ padding: "15px 0px" }}>
        {cabValidacaoProduto != null ? (
          <CollapseValidaProduto cabValidacaoProduto={cabValidacaoProduto} />
        ) : null}
        {cabValidacaoCliente != null ? (
          <CollapseValidaCliente cabValidacaoCliente={cabValidacaoCliente} />
        ) : null}
        {cabValidacaoFrete != null && cabValidacaoFrete != 0 ? (
          <CollapseValidaFrete cabValidacaoFrete={cabValidacaoFrete} />
        ) : null}
        {cabValidacaoVerba != null ? (
          <CollapseValidaVerba cabValidacaoVerba={cabValidacaoVerba} />
        ) : null}
      </Col>
    );
  }
}

export default SimuladorValidacao;
