import React from "react";
//componentes
import BuscaVerba from "./BuscaVerba";
import ListagemVerbas from "./ListagemVerbas";
import ManipulaVerba from "./ManipulaVerba";
import { Col } from "reactstrap";

// lida com as toast/alertas
import { toast } from "react-toastify";
// lida com as requisições
import { api } from "services/api";
import { proxyApi } from "services/proxyApi";
// lidar com criptografias MD5
const md5 = require("md5");

class SimuladorVerbas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // buscaVerba: true,
      verbaSel: [],
      validacoesVerba: [],
      optionTipoTitulo: [],
      idUsuario: sessionStorage.getItem(md5("CODUSU")),
    };
  }

  async componentDidMount() {
    try {
      const response = await proxyApi.get(
        `simulador-verbas/${this.state.idUsuario}/${this.props.getCabNuSimCli}`
      );
      if (response.data?.statusApi == 1) {
        // //console.log(response.data.simuladorVer);
        const { simuladorVer, tipoTitulo } = response.data;
        // //console.log(response.data);
        this.props.setCabVerbas(simuladorVer);
        this.setCabOptionTipoTitulo(tipoTitulo);
      } else {
        // //console.log(response.data);
        toast.warn(response.data.message, {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        });
      }
    } catch (error) {
      // console.error(error);
      toast.error(
        `Não foi possível conectar ao servidor, atualize a página e tente novamente. ${error.message}`,
        {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        }
      );
    }
  }

  // handleBuscaVerba = () => {
  //   this.setState({buscaVerba: !this.state.buscaVerba});
  // }

  setCabOptionTipoTitulo = (titulos) => {
    this.setState({
      optionTipoTitulo: titulos,
    });
  };

  handleVerbaSel = (verbaSel) => {
    this.setState({ verbaSel: verbaSel });
  };
  handleValidacoesVerba = (validacoesVerba) => {
    this.setState({ validacoesVerba: validacoesVerba });
  };

  render() {
    const {
      getCabVerbas,
      getNovaVerba,
      getCabNuSim,
      getCabNuSimCli,
      getCabStatusSimulacao,
      ///////////////////
      setCabVerbas,
      setNovaVerba,
    } = this.props;
    const { optionTipoTitulo } = this.state;
    return (
      <>
        <Col style={{ padding: "15px 0" }}>
          {getNovaVerba == true ? ( //verifica se é para iniciar o processo de cadastro de verba
            this.state.verbaSel?.length == 0 ? ( //verifica se está buscando a verba para iniciar a manipulação e cadastro
              <BuscaVerba setVerbaSel={this.handleVerbaSel} />
            ) : (
              <>
                <ManipulaVerba
                  setVerbaSel={this.handleVerbaSel}
                  setVerbas={setCabVerbas}
                  setValidacoesVerba={this.handleValidacoesVerba}
                  getVerbaSel={this.state.verbaSel}
                  setNovaVerba={setNovaVerba}
                  getCabNuSim={getCabNuSim}
                  getCabNuSimCli={getCabNuSimCli}
                  getCabStatusSimulacao={getCabStatusSimulacao}
                  getOptionTipoTitulo={optionTipoTitulo}
                  update={false}
                />
              </>
            )
          ) : getCabVerbas?.length == 0 ? (
            <Col>
              <p>
                Nenhuma verba prefixada foi encontrada para esta simulação,
                outras verbas podem ser adicionadas manualmente mediante
                contrato especificado.
              </p>
            </Col>
          ) : (
            <ListagemVerbas
              getCabNuSim={getCabNuSim}
              getCabNuSimCli={getCabNuSimCli}
              getVerbas={getCabVerbas}
              getCabStatusSimulacao={getCabStatusSimulacao}
              getOptionTipoTitulo={optionTipoTitulo}
              ////////////
              setVerbas={setCabVerbas}
              setValidacoesVerba={this.handleValidacoesVerba}
            />
          )}
        </Col>
      </>
    );
  }
}

export default SimuladorVerbas;
