import React from "react";
import { toast } from "react-toastify";
// reactstrap components
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

// lida com as requisições
import { api } from "services/api";
import { decode as base64_decode } from "base-64";
import { LogAirbrake } from "services/log.airbrake";
// lidar com criptografias MD5
const md5 = require("md5");

class MenuConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idUsuario: sessionStorage.getItem(md5("CODUSU")),
      codVend: base64_decode(sessionStorage.getItem(md5("CODVEND"))),
      setorUsuario: base64_decode(sessionStorage.getItem(md5("NUSETOR"))),
    };
  }

  handleUpdateVerbas = async () => {
    const { cabNuSim, cabNuSimCli } = this.props;
    let raw = {
      nuSim: cabNuSim,
      nuSimCli: cabNuSimCli,
    };
    // console.log("raw:::::", raw);
    try {
      const response = await api.put(
        `simulador-verba-automatica/${this.state.idUsuario}`,
        raw
      );
      if (response.data?.statusApi === 1) {
        // //console.log(response.data.simuladorVer);
        const { simuladorVer, imc_cli } = response.data;
        this.props.handleSetCabVerbas(simuladorVer);
        this.props.handleSetCabImcGeral(imc_cli);
        toast.success("Verbas atualizadas com sucesso.", { autoClose: 7000 });
      } else {
        //console.log(response.data);
        toast.warn(response.data.message, {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        });
      }
    } catch (error) {
      LogAirbrake(error, {
        url: `simulador-verba-automatica/${this.state.idUsuario}`,
        method: "PUT",
        data: raw,
      });
      // console.error(error);
      toast.error(
        `Não foi possível conectar ao servidor, atualize a página e tente novamente. ${error.message}`,
        {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        }
      );
    }
  };

  handleUpdateProdutos = async () => {
    const { cabNuSimCli } = this.props;
    let raw = {
      nuSimCli: cabNuSimCli,
      codVend: this.state.codVend,
    };
    // console.log("raw:", raw);
    try {
      const response = await api.put(
        `simulador-item-update/${this.state.idUsuario}`,
        raw
      );
      if (response.data?.statusApi == 1) {
        // console.log("response.data::", response.data);
        const { simuladorIte, imc_cli } = response.data;
        this.props.handleSetCabProduto(simuladorIte);
        this.props.handleSetCabImcGeral(imc_cli);
        toast.success(
          "As informações dos produtos foram atualizadas com sucesso.",
          { autoClose: 7000 }
        );
        location.reload();
      } else {
        //console.log(response.data);
        toast.warn(response.data.message, {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        });
      }
    } catch (error) {
      // console.error(error);
      LogAirbrake(error, {
        url: `simulador-item-update/${this.state.idUsuario}`,
        method: "PUT",
        data: raw,
      });

      toast.error(
        `Não foi possível conectar ao servidor, atualize a página e tente novamente. ${error.message}`,
        {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        }
      );
    }
  };

  handleDestroyCli = async () => {
    const { cabNuSimCli, handleAddSimCli } = this.props;
    const { idUsuario } = this.state;
    try {
      const response = await api.delete(
        `simulador-cliente/${idUsuario}/${cabNuSimCli}`
      );

      const { data } = response;
      if (data["cliente"] > 0) {
        handleAddSimCli();
      } else {
        //console.log(response);
        toast.error("Não foi possível excluir o cliente da simulação.", {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        });
      }
    } catch (error) {
      // console.error(error);
      toast.error(
        `Não foi possível conectar ao servidor, atualize a página e tente novamente. ${error.message}`,
        {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        }
      );
    }
  };

  render() {
    const {
      activeTab,
      novaVerba,
      tabNames,
      ///////////////
      getCabStatusSimulacao,
      handleNovaVerba,
    } = this.props;
    let menuItem = [];
    if (activeTab == tabNames[0]) {
      /* Ciente */
      menuItem.push(
        <DropdownItem
          key={1}
          disabled={
            getCabStatusSimulacao != 0 || this.state.setorUsuario == "1"
          }
          onClick={() => this.handleDestroyCli()}
        >
          Excluir Cliente
        </DropdownItem>
      );
    } else if (activeTab == tabNames[1]) {
      /* Produtos */
      menuItem.push(
        <DropdownItem
          key={2}
          disabled={
            getCabStatusSimulacao != 0 || this.state.setorUsuario == "1"
          }
          onClick={() => this.handleUpdateProdutos()}
        >
          Atualizar Produtos
        </DropdownItem>
      );
    } else if (activeTab == tabNames[2]) {
      /* Verbas */
      menuItem.push(
        <>
          <DropdownItem
            key={3}
            disabled={
              getCabStatusSimulacao != 0 || this.state.setorUsuario == "1"
            }
            onClick={() => handleNovaVerba()}
          >
            {novaVerba ? "Cancelar Adição" : "Adicionar Verba"}
          </DropdownItem>
          <DropdownItem key={4} divider />
          <DropdownItem
            key={5}
            disabled={
              novaVerba ||
              getCabStatusSimulacao != 0 ||
              this.state.setorUsuario == "1"
            }
            onClick={() => this.handleUpdateVerbas()}
          >
            Atualizar Verbas
          </DropdownItem>
        </>
      );
    }
    return (
      <>
        <UncontrolledDropdown group className="float-right">
          <DropdownToggle
            caret
            color="primary"
            size="sm"
            style={{ padding: "7px", marginTop: ".3em" }}
            data-toggle="dropdown"
            disabled={
              getCabStatusSimulacao != 0 || this.state.setorUsuario == "1"
            }
          >
            <i className="fa fa-cog" /> {activeTab}
          </DropdownToggle>
          <DropdownMenu>{menuItem}</DropdownMenu>
        </UncontrolledDropdown>
      </>
    );
  }
}

export { MenuConfig };
