import React from "react";
// reactstrap components
import { 
  Col,
  Modal, ModalBody
} from "reactstrap";

import GridLoader from "react-spinners/GridLoader";
export default function ModalLoading(props){
  // spinners/loaders do react-spinners (tem vários estilos)

  return (
    <>
      <Modal
        isOpen={props.loading}
        // isOpen={false}
        backdrop='static'
        size="mini"
        modalClassName="modal-black"
        style={{marginTop:"20%"}}
      >
        <ModalBody style={{textAlign: "center"}}>
            <Col>
              {props.mensagem}
            </Col>
            <Col>
              <GridLoader size={10} color="#FFF"/>
            </Col>
        </ModalBody>
      </Modal>
    </>
  );
}