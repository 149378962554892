import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { api } from "services/api";
import { logoBase64 } from "assets/img/logo_base64";
import { formatCpfCnpj, formatValue, formatValue2 } from "services/globals";
// lidar com criptografias Base64
import { decode as base64_decode } from "base-64";
import { get } from "jquery";
import { enterpriseUseDelivery } from "services/utils";
// lidar com criptografias MD5
const md5 = require("md5");
const moment = require("moment");

async function loadToPrint(cabNuSim, cabNuSimCli) {
  try {
    //console.log(sessionStorage.getItem(md5("CODUSU")));
    //console.log(cabNuSim);
    //console.log(cabNuSimCli);
    const response = await api.get(
      `simulador/print/${sessionStorage.getItem(
        md5("CODUSU")
      )}/${cabNuSim}/${cabNuSimCli}`
    );
    //console.log(response.data)
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function SimuladorPDF(cabNuSim, cabNuSimCli) {
  const response = await loadToPrint(cabNuSim, cabNuSimCli);

  const {
    simulador,
    simuladorCli,
    simuladorSomaIte,
    simuladorIte,
    simuladorVer,
  } = response.data;

  // console.log("simuladorCli", simuladorCli, simulador, simuladorIte);

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  let status;
  if (simulador.STATUS == 0) {
    status = "Em elaboração";
  } else if (simulador.STATUS == 1) {
    status = "Aguardando Aprovação";
  } else if (simulador.STATUS == 2) {
    status = "Aprovado";
  } else if (simulador.STATUS == 3) {
    status = "Pedido Gerado";
  } else if (simulador.STATUS == 4) {
    status = "Pedido Faturado";
  }

  //console.log(simulador.STATUS)

  const header = {
    columns: [
      {
        stack: [
          {
            image: "logo",
            width: 70,
            margin: [10, 10, 20, 0],
          },
        ],
        width: 100,
      },
      {
        stack: [
          { text: `SEMALO IND E COMERCIO DE ALIMENTOS LTDA`, bold: true },
          { text: `CNPJ: 36.804.268/001-23  I.E.: 282.702.326` },
          { text: `Av. Guaicurus, Nro. 2348  Bairro: JD Itamaracá` },
          { text: `Campo Grande - MS  CEP 79062-146` },
          { text: `Tel: (67) 3348-4700` },
          {
            text: [
              { text: `Usuário: `, bold: true },
              {
                text: `${base64_decode(
                  sessionStorage.getItem(md5("NOMEUSU"))
                )} - ${base64_decode(sessionStorage.getItem(md5("EMAIL")))}`,
              },
            ],
          },
        ],
        width: 530,
      },
      {
        stack: [
          {
            text: `Simulador: ${simulador.NUSIM}`,
            fontSize: 13,
            margin: [0, 5, 0, 0],
            bold: true,
          },
          { text: `${status}` },
          {
            text: `Lançamento: ${moment(simulador.DHINC).format(
              "DD/MM/YYYY HH:mm:ss"
            )}
                   Alteração: ${moment(simulador.DHALTER).format(
                     "DD/MM/YYYY HH:mm:ss"
                   )}`,
            fontSize: 8,
            margin: [0, 2, 0, 0],
          },
        ],
        width: 200,
        fontSize: 12,
        alignment: "center",
      },
    ],
  };

  const titulo1 = [
    {
      margin: [0, 2, 0, 2],
      text: "Resumo",
      bold: true,
      fontSize: 10,
      alignment: "center",
    },
  ];

  const titulo2 = [
    {
      margin: [0, 2, 0, 2],
      text: "Verbas",
      bold: true,
      fontSize: 10,
      alignment: "center",
    },
  ];

  const tituloVerbaPromotor = [
    {
      margin: [0, 2, 0, 2],
      text: "Verbas Promotor",
      bold: true,
      fontSize: 10,
      alignment: "center",
    },
  ];

  const titulo3 = [
    {
      margin: [0, 2, 0, 2],
      text: "Produtos",
      bold: true,
      fontSize: 10,
      alignment: "center",
    },
  ];

  const linha0 = [
    {
      margin: [0, 3, 0, 0],
      table: {
        headerRows: 1,
        widths: [100, "*"],
        // dontBreakRows: true,
        // keepWithHeaderRows: 1,
        body: [
          [
            { text: "Empresa", bold: true, fillColor: "#dddddd" },
            {
              text: `${simuladorCli?.NOMEFANTASIA}`,
            },
          ],
        ],
      },
    },
  ];

  const linha1 = [
    {
      margin: [0, -1, 0, 0],
      table: {
        headerRows: 1,
        widths: [100, "*", 100, 160, 20, 20],
        // dontBreakRows: true,
        // keepWithHeaderRows: 1,
        body: [
          [
            { text: "Supervisor / RCA", bold: true, fillColor: "#dddddd" },
            {
              text: `${simuladorCli.SUPERVISOR.trim()} / ${simuladorCli.REPRESENTANTE.trim()}`,
            },
            // { text: "Empresa", bold: true, fillColor: "#dddddd" },
            // {
            //   text: `${simuladorCli?.NOMEFANTASIA}`,
            // },
            { text: "Cidade", bold: true, fillColor: "#dddddd" },
            { text: `${simuladorCli.NOMECID}` },
            { text: "UF", bold: true, fillColor: "#dddddd" },
            { text: `${simuladorCli.UF}` },
          ],
        ],
      },
    },
  ];

  let ordemCompra = simuladorCli.ORDEMCOMPRA;

  if (!ordemCompra) {
    ordemCompra = " ";
  }

  const linha2 = [
    {
      margin: [0, -1, 0, 0],
      table: {
        headerRows: 1,
        widths: [70, "*", 60, 80, 90, 70],
        border: [true, false, true, true],
        // dontBreakRows: true,
        // keepWithHeaderRows: 1,
        body: [
          [
            { text: "Cliente", bold: true, fillColor: "#dddddd" },
            { text: `${simuladorCli.CODPARC} - ${simuladorCli.RAZAO.trim()}` },
            { text: "CNPJ", bold: true, fillColor: "#dddddd" },
            { text: `${formatCpfCnpj(simuladorCli.CGC_CPF.trim())}` },
            { text: "ORDEM DE COMPRA", bold: true, fillColor: "#dddddd" },
            { text: `${ordemCompra}` },
          ],
        ],
      },
    },
  ];

  const linha3 = [
    {
      margin: [0, 0, 0, 0],
      table: {
        headerRows: 1,
        widths: [100, 250, 80, 40, 100, "*"],
        border: [true, false, true, true],
        // dontBreakRows: true,
        // keepWithHeaderRows: 1,
        body: [
          [
            {
              text: `${
                enterpriseUseDelivery(simuladorCli.CODEMP)
                  ? "Tipo entrega"
                  : "Faturamento"
              }`,
              bold: true,
              fillColor: "#dddddd",
            },
            {
              text: `${
                enterpriseUseDelivery(simuladorCli.CODEMP)
                  ? simuladorCli.TIPO_ENTREGA?.trim()
                  : simuladorCli.TIPO_FATURAMENTO?.trim()
              }`,
            },
            { text: "Prazo de Pagamento", bold: true, fillColor: "#dddddd" },
            { text: `${simuladorCli.PRAZOMEDIO.trim()}` },
            { text: "Tipo de Negociação", bold: true, fillColor: "#dddddd" },
            {
              text: `${
                simuladorCli.CODTIPNEG + " - " + simuladorCli.DESCRTIPVENDA
              }`,
            },
          ],
        ],
      },
    },
  ];
  const linha4 = [
    {
      margin: [0, -1, 0, 0],
      table: {
        headerRows: 1,
        widths: [100, 62, 70, 100, 80, "*"],
        border: [true, false, true, true],
        // dontBreakRows: true,
        // keepWithHeaderRows: 1,
        body: [
          [
            { text: "Tipo de Frete", bold: true, fillColor: "#dddddd" },
            { text: `${simuladorCli?.TIPOFRETE?.trim()}` },
            { text: "Frete M³", bold: true, fillColor: "#dddddd" },
            {
              text: `${formatValue(
                "currency",
                simuladorCli.VLRFRETELOG ?? simuladorCli.VLRFRETECID
              )}`,
            },
            { text: "Cidade Logística", bold: true, fillColor: "#dddddd" },
            { text: `${simuladorCli.NOMECIDLOG ?? ""}` },
          ],
        ],
      },
    },
  ];
  //precisa disso pois o custo de venda a prazo estava arrendodando errado
  const juros = Number(simuladorCli.TXJURODIASIMUL);
  const prazo = Number(simuladorCli.PRAZOMEDIO);
  const faturamento = Number(simuladorCli.VLR_FATURAMENTO);
  const conta = ((juros * prazo) / 100) * faturamento;
  const linha5 = [
    {
      margin: [0, -1, 0, 0],
      table: {
        headerRows: 1,
        widths: [80, 80, 80, 80, 80, 80, 80, 80, "*"],
        alignment: "center",
        // dontBreakRows: true,
        // keepWithHeaderRows: 1,
        body: [
          [
            {
              text: `${
                enterpriseUseDelivery(simuladorCli.CODEMP)
                  ? "Vlr Entrega"
                  : "Frete"
              }`,
              bold: true,
              fillColor: "#dddddd",
            },
            { text: "Logística", bold: true, fillColor: "#dddddd" },
            { text: "Gestor", bold: true, fillColor: "#dddddd" },
            { text: "Juros", bold: true, fillColor: "#dddddd" },
            { text: "Descarga", bold: true, fillColor: "#dddddd" },
            { text: "Desgustação", bold: true, fillColor: "#dddddd" },
            { text: "RCA", bold: true, fillColor: "#dddddd" },
            {
              text: "% IMC TOTAL",
              fontSize: 9,
              alignment: "center",
              bold: true,
              fillColor: "#dddddd",
            },
            {
              text: "R$ Faturamento",
              fontSize: 9,
              alignment: "center",
              bold: true,
              fillColor: "#dddddd",
            },
          ],
          [
            `${
              enterpriseUseDelivery(simuladorCli.CODEMP)
                ? formatValue("decimal", simuladorCli.PER_ENTREGA ?? 0)
                : formatValue("decimal", (simuladorCli.FRETE_PER ?? 0) * 100)
            }  %`,
            `${formatValue(
              "decimal",
              (simuladorCli.PERLOGISTICA ?? 0) * 100
            )} %`,
            `${formatValue(
              "decimal",
              simuladorCli.PER_COMISSAO_GESTOR ?? 0
            )} %`,
            `${formatValue("decimal", simuladorCli.TXJURODIASIMUL)} %`,
            `${formatValue(
              "decimal",
              Number(
                (simuladorCli?.DESCARGA > 0
                  ? simuladorCli?.DESCARGA / simuladorCli.VLR_FATURAMENTO
                  : 0) * 100
              ).toFixed(2)
            )} %`,
            `${formatValue("decimal", simuladorCli.DEGUSTACAO_PER * 100)} %`,
            `${formatValue2("decimal", simuladorCli.PER_COMISSAO_RCA ?? 0)} %`,
            {
              colSpan: 1,
              rowSpan: 2,
              text: `${formatValue("decimal", simuladorCli.IMC_CLI ?? 0)} %`,
              bold: true,
              margin: [0, 5, 0, 0],
              fontSize: 10,
              fillColor: "#dddddd",
              alignment: "center",
            },
            {
              colSpan: 1,
              rowSpan: 2,
              text: `${formatValue(
                "currency",
                simuladorCli.VLR_FATURAMENTO ?? 0
              )}`,
              bold: true,
              margin: [0, 5, 0, 0],
              fontSize: 10,
              fillColor: "#dddddd",
              alignment: "center",
            },
          ],
          [
            `${
              enterpriseUseDelivery(simuladorCli.CODEMP)
                ? formatValue("currency", simuladorCli.VLR_ENTREGA ?? 0)
                : formatValue("currency", simuladorCli.VLRFRETECTE ?? 0)
            }`,

            `${formatValue(
              "currency",
              simuladorCli.VLRFRETELOG
                ? simuladorCli.PERLOGISTICA * simuladorCli.VLR_FATURAMENTO
                : 0
            )}`,
            `${formatValue(
              "currency",
              (simuladorCli?.PER_COMISSAO_GESTOR / 100) *
                simuladorCli?.VLR_FATURAMENTO ?? 0
            )}`,
            `${formatValue("currency", conta)}`,
            `${formatValue("currency", simuladorCli?.DESCARGA ?? 0)}`,
            `${formatValue("currency", simuladorCli.DEGUSTACAO ?? 0)}`,
            `${formatValue("currency", simuladorCli.VLRCOMISSAORCA ?? 0)}`,
          ],
        ],
      },
    },
  ];

  const getVerbas = ({ onlyID, exceptID }) => {
    let simuladorVerFiltered;

    if (onlyID)
      simuladorVerFiltered = simuladorVer.filter(
        (verba) => verba.IDTIPONEG == onlyID
      );
    if (exceptID)
      simuladorVerFiltered = simuladorVer.filter(
        (verba) => verba.IDTIPONEG != exceptID
      );

    // console.log("----------------:::", simuladorVerFiltered);

    return simuladorVerFiltered.map((verba) => {
      let valorVerba = 0;
      let percentualVerba = 0;

      if (verba.PERCENTUAL > 0) {
        percentualVerba = verba.PERCENTUAL;
        valorVerba = simuladorCli.VLR_FATURAMENTO * (verba.PERCENTUAL / 100);
      }

      if (verba.VALOR > 0) {
        valorVerba = verba.VALOR;
        percentualVerba = (verba.VALOR * 100) / simuladorCli.VLR_FATURAMENTO;
        percentualVerba = Number(percentualVerba).toFixed(2);
      }

      return [
        {
          text: `${verba.NUSIMVER}`,
          fontSize: 7,
        },
        {
          text: `${verba.NUMCONTRATO ?? "-"}`,
          fontSize: 7,
        },
        {
          text: `${verba.CODPARCVERBA} - ${verba.NOMEPARC.trim()}`,
          fontSize: 7,
        },
        {
          text: `${verba.IDTIPONEG} - ${verba.DESCRNEG}`,
          fontSize: 7,
        },
        {
          text: `${formatValue("decimal", percentualVerba)}`,
          fontSize: 7,
        },
        {
          text: `${formatValue("currency", valorVerba)}`,
          fontSize: 7,
        },
        {
          text: `${
            verba.DATA_INICIAL != null
              ? moment(verba.DATA_INICIAL).format("DD/MM/YYYY")
              : "-"
          }`,
          fontSize: 7,
        },
        {
          text: `${
            verba.DATA_FINAL != null
              ? moment(verba.DATA_FINAL).format("DD/MM/YYYY")
              : "-"
          }`,
          fontSize: 7,
        },
        {
          text: `${verba.CODTIPTIT + " - " + verba.DESCRTIPTIT}`,
          fontSize: 7,
        },
        {
          text: `${verba.TIPOINCLUSAO == "A" ? "AUTOMÁTICA" : "MANUAL"}`,
          fontSize: 7,
        },
      ];
    });
  };

  const allVerbas = getVerbas({ exceptID: 9 });
  const verbasPromotor = getVerbas({ onlyID: 9 });

  const getLinhaVerbas = (arrayVerbas) => {
    return [
      {
        margin: [0, -1, 0, 0],
        table: {
          headerRows: 1,
          widths: [30, 30, "*", 170, 30, 50, 42, 42, 90, 50],
          alignment: "center",
          // dontBreakRows: true,
          // keepWithHeaderRows: 1,
          body: [
            [
              { text: "Cod.", bold: true, fillColor: "#dddddd", fontSize: 7 },
              {
                text: "Contrato",
                bold: true,
                fillColor: "#dddddd",
                fontSize: 7,
              },
              {
                text: "Parceiro da Verba",
                bold: true,
                fillColor: "#dddddd",
                fontSize: 7,
              },
              { text: "Verba", bold: true, fillColor: "#dddddd", fontSize: 7 },
              {
                text: "% Atual",
                bold: true,
                fillColor: "#dddddd",
                fontSize: 7,
              },
              {
                text: "R$ Valor",
                bold: true,
                fillColor: "#dddddd",
                fontSize: 7,
              },
              {
                text: "Data Inicial",
                bold: true,
                fillColor: "#dddddd",
                fontSize: 7,
              },
              {
                text: "Data Final",
                bold: true,
                fillColor: "#dddddd",
                fontSize: 7,
              },
              {
                text: "Tipo de Título",
                bold: true,
                fillColor: "#dddddd",
                fontSize: 7,
              },
              {
                text: "Tipo Inclusão",
                bold: true,
                fillColor: "#dddddd",
                fontSize: 7,
              },
            ],
            ...arrayVerbas,
          ],
        },
      },
    ];
  };
  // ...allVerbas,
  // ...verbasPromotor,

  const linhaVerbas = getLinhaVerbas(allVerbas);
  const linhaVerbasPromotor = getLinhaVerbas(verbasPromotor);

  const produtos = simuladorIte.map((produto) => {
    return [
      // `${produto.CODGRUPAI}`,
      `${produto.CODPROD} - ${produto.DESCRPROD}`,
      `${formatValue("decimal", produto.QTDNEG)}`,
      `${formatValue("decimal", produto.M3)}`,
      `${formatValue("decimal", produto.M3TOTAL)}`,
      `${formatValue("currency", produto.PRECONEG)}`,
      `${formatValue("currency", produto.VLRTAB)}`,
      `${formatValue("currency", produto.VLRTABPADRAO)}`,
      `${formatValue("currency", produto.VLRTOTAL)}`,
      `${formatValue("decimal", produto.IMC_ITEM) + " %"}`,
      `${formatValue("decimal", produto.PERCRCA) + " %"}`,
    ];
  });

  const linhaProdutos = [
    {
      margin: [0, -1, 0, 0],
      table: {
        headerRows: 1,
        widths: ["*", 40, 40, 40, 50, 50, 50, 70, 30, 25],
        alignment: "center",
        // dontBreakRows: true,
        // keepWithHeaderRows: 1,
        body: [
          [
            // {text: 'Grupo de Produtos', bold:true},
            { text: "Descrição do Produto", bold: true, fillColor: "#dddddd" },
            { text: "Quant.", bold: true, fillColor: "#dddddd" },
            { text: "M³", bold: true, fillColor: "#dddddd" },
            { text: "Total M³", bold: true, fillColor: "#dddddd" },
            { text: "R$ Neg.", bold: true, fillColor: "#dddddd" },
            { text: "R$ Tabela", bold: true, fillColor: "#dddddd" },
            { text: "R$ Padrão", bold: true, fillColor: "#dddddd" },
            { text: "R$ Fat.", bold: true, fillColor: "#dddddd" },
            { text: "% IMC", bold: true, fillColor: "#dddddd" },
            { text: "% RCA", bold: true, fillColor: "#dddddd" },
          ],
          ...produtos,
          [
            // {text: 'Grupo de Produtos', bold:true},
            { text: "Totais", fillColor: "#dddddd" },
            { text: `${formatValue("decimal", simuladorSomaIte)}` },
            { text: "", border: [false] },
            { text: `${formatValue("decimal", simulador.M3_SIM)}` },
            { text: "", border: [false] },
            { text: "", border: [false] },
            { text: "", border: [false] },
            { text: "", border: [false] },
            { text: "", border: [false] },
            { text: "", border: [false] },
          ],
        ],
      },
    },
  ];

  let titulo4, linhaObservacao;

  if (simuladorCli.OBSERVACAO) {
    titulo4 = [
      {
        margin: [0, 2, 0, 2],
        text: "Observação",
        bold: true,
        fontSize: 10,
        alignment: "center",
      },
    ];

    linhaObservacao = [
      {
        margin: [0, -1, 0, 0],
        table: {
          headerRows: 1,
          widths: ["*"],
          border: [true, false, true, true],
          body: [[{ text: `${simuladorCli.OBSERVACAO}` }]],
        },
      },
    ];
  }

  // console.log("linhaVerbasPromotor.length ", linhaVerbasPromotor.length);

  const details = [
    header,
    linha0,
    linha1,
    linha2,
    titulo1,
    linha3,
    linha4,
    linha5,
    titulo2,
    linhaVerbas,
    tituloVerbaPromotor,
    linhaVerbasPromotor,
    titulo3,
    linhaProdutos,
    titulo4,
    linhaObservacao,
  ];

  const docDefinitions = {
    pageSize: "A4", //A4 (595.28 x 841.89)
    pageOrientation: "landscape",
    pageMargins: [25, 20, 25, 14], //left, top, right, bottom
    defaultStyle: {
      fontSize: 8,
      bold: false,
    },
    content: details,
    images: {
      logo: logoBase64,
    },
    footer: function (currentPage, pageCount) {
      return {
        columns: [
          {
            text: `Página ${currentPage.toString()} de ${pageCount}  -  Simulador: ${cabNuSim}`,
            alignment: "center",
          },
        ],
        margin: [25, 0], // Margem para centralizar o rodapé horizontalmente
      };
    },
  };

  pdfMake.createPdf(docDefinitions).open(); //download()
}
