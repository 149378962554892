import React, { Component, useEffect } from "react";
import { decode as base64_decode, encode as base64_encode } from "base-64";
// lidar com criptografias MD5
// eslint-disable-next-line no-undef
const md5 = require("md5");
// reactstrap components
import { Button, CardHeader, Col, TabContent, TabPane, Row } from "reactstrap";

//componentes
import { MenuConfig } from "./Components/MenuConfig";
import MenuSimulador from "./Components/MenuSimulador";
import SimuladorCabCompleto from "./Cabecalho/SimuladorCabCompleto";
import SimuladorCabInicio from "./Cabecalho/SimuladorCabInicio";
import { useProductContext } from "contexts/ProdutosSimuladorContext";

const SimuladorHeader = (props) => {
  const { setDataProducts, dataProducts } = useProductContext();

  const handleCleanItemsQuantity = () => {
    const newProducts = dataProducts.map((product) => {
      return { ...product, QTDNEG: null, updated: true };
    });
    setDataProducts(newProducts);
  };

  // useEffect(() => {
  //   console.log("dataProducts:", dataProducts);
  // }, [dataProducts]);

  const handleRestoreProductsDataFromCache = () => {
    const productsFromCache = JSON.parse(
      localStorage.getItem("cache-produtos")
    );
    const newProducts = dataProducts.map((product) => {
      const productFound = productsFromCache.find(
        (productCached) => productCached.CODPROD === product.CODPROD
      );
      return productFound ? productFound : product;
    });
    setDataProducts(newProducts);
  };

  const {
    activeTab,
    iniciouSimulador,
    cabNuSim,
    cabNuSimCli,
    cabCodParc,
    cabCargaCompartilhada,
    cabStatusSimulacao,
    cabImcGeral,
    cabCliente,
    cabOptionEmpresa,
    cabOptionOperLogistico,
    cabEmpresa,
    cabOptionTipoPagDescarga,
    cabVlrDegustacao,
    cabOperLogistico,
    cabTipoPagDescarga,
    cabVlrDescarga,
    novaVerba,
    tabNames,
    cabObservacao,
    cabOrdemCompra,

    ////
    handleSetCabResume,
    handleSetCabStatusSimulacao,
    handleToggleTab,
    handleModalListaSimCli,
    handleSetCabCargaCompartilhada,
    handleSetCabEmpresa,
    handleSetCabOperLogistico,
    handleSetCabTipoPagDescarga,
    handleSetCabVlrDescarga,
    handleSetCabVlrDegustacao,
    handleNovaVerba,
    handleSetCabProduto,
    handleSetCabVerbas,
    handleSetCabImcGeral,
    handleAddSimCli,
    ////// SET VALIDATION
    setCabValidacaoProduto,
    setCabValidacaoCliente,
    setCabValidacaoFrete,
    setCabValidacaoVerba,
    //////// GET VALIDATION
    cabValidacaoProduto,
    cabValidacaoCliente,
    cabValidacaoFrete,
    cabValidacaoVerba,
    cabProdutos,
  } = props;

  let tabHeader = [];

  for (let i = 0; i < tabNames.length; i++) {
    tabHeader.push(
      <Button
        key={i}
        color={activeTab == tabNames[i] ? "primary" : ""}
        className={activeTab == tabNames[i] ? "btn-simple" : "btn-link"}
        onClick={() => handleToggleTab(tabNames[i])}
        disabled={i == 0 ? false : !iniciouSimulador}
      >
        {activeTab == tabNames[i] ? (
          tabNames[i]
        ) : (
          <p>
            <b>{tabNames[i]}</b>
          </p>
        )}
      </Button>
    );
  }

  // console.log("cabNuSim--->", cabNuSim, activeTab);
  // const cabNuSim = base64_decode(
  //   sessionStorage.getItem(md5("SIMULADOR_NUSIM"))
  // );

  return (
    <>
      <CardHeader style={{ borderBottom: "1px solid #344675" }}>
        {cabNuSimCli > 0 ? (
          <Row>
            <Col
              className=""
              style={{ display: "inline-flex", alignItems: "center" }}
            >
              <p style={{ padding: "0px", fontWeight: "bold" }}>
                Simulação: {cabNuSim}
              </p>
              <MenuSimulador
                getCabNuSim={cabNuSim}
                getCabNuSimCli={cabNuSimCli}
                getCabStatusSimulacao={cabStatusSimulacao}
                ////////////////////
                handleSetCabProduto={handleSetCabProduto}
                handleSetCabResume={handleSetCabResume}
                handleModalListaSimCli={handleModalListaSimCli}
                handleAddSimCli={handleAddSimCli}
                handleSetCabStatusSimulacao={handleSetCabStatusSimulacao}
                ////// SET VALIDATION
                setCabValidacaoProduto={setCabValidacaoProduto}
                setCabValidacaoCliente={setCabValidacaoCliente}
                setCabValidacaoFrete={setCabValidacaoFrete}
                setCabValidacaoVerba={setCabValidacaoVerba}
                //////// GET VALIDATION
                cabValidacaoProduto={cabValidacaoProduto}
                cabValidacaoCliente={cabValidacaoCliente}
                cabValidacaoFrete={cabValidacaoFrete}
                cabValidacaoVerba={cabValidacaoVerba}
                getCabVlrDegustacao={cabVlrDegustacao}
                getCabProdutos={cabProdutos}
                handleSetCabImcGeral={handleSetCabImcGeral}
              />
              {/* <div
                style={{
                  padding: "16px",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "-3px",
                }}
              >
                <Button
                  onClick={handleRestoreProductsDataFromCache}
                  color="primary"
                  style={{
                    height: "32px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <i
                    className="fa fa-sync fa-1x"
                    style={{ marginRight: "8px" }}
                  />
                  Recuperar produtos
                </Button>
              </div> */}
              <div
                style={{
                  padding: "16px",
                  // paddingLeft: "0px",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "-3px",
                }}
              >
                <Button
                  onClick={handleCleanItemsQuantity}
                  color="primary"
                  style={{
                    height: "32px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <i
                    className="fa fa-brush fa-1x"
                    style={{ marginRight: "8px" }}
                  />
                  Limpar quantidades
                </Button>
              </div>
            </Col>
          </Row>
        ) : null}
        <TabContent activeTab={activeTab}>
          <TabPane tabId={tabNames[0]} key={1}>
            {cabCodParc > 0 || cabNuSimCli > 0 ? (
              <SimuladorCabInicio
                setCabCargaCompartilhada={handleSetCabCargaCompartilhada}
                //////////////////
                getCabNuSim={cabNuSim}
                getCabCargaCompartilhada={cabCargaCompartilhada}
                getCabStatusSimulacao={cabStatusSimulacao}
                getCabImcGeral={cabImcGeral}
                setCabStatusSimulacao={handleSetCabStatusSimulacao}
              />
            ) : null}
          </TabPane>
          <TabPane tabId={activeTab != tabNames[0] ? activeTab : null} key={2}>
            {cabNuSimCli > 0 ? (
              <SimuladorCabCompleto
                setCabCargaCompartilhada={handleSetCabCargaCompartilhada}
                setCabEmpresa={handleSetCabEmpresa}
                setCabOperLogistico={handleSetCabOperLogistico}
                setCabTipoPagDescarga={handleSetCabTipoPagDescarga}
                setCabVlrDescarga={handleSetCabVlrDescarga}
                setCabVlrDegustacao={handleSetCabVlrDegustacao}
                //gets
                getCabImcGeral={cabImcGeral}
                getCabNuSim={cabNuSim}
                getCabNuSimCli={cabNuSimCli}
                getCabCliente={cabCliente}
                getCabEmpresa={cabEmpresa}
                getCabOptionEmpresa={cabOptionEmpresa}
                getCabOperLogistico={cabOperLogistico}
                getCabOptionOperLogistico={cabOptionOperLogistico}
                getCabTipoPagDescarga={cabTipoPagDescarga}
                getCabOptionTipoPagDescarga={cabOptionTipoPagDescarga}
                getCabVlrDescarga={cabVlrDescarga}
                getCabVlrDegustacao={cabVlrDegustacao}
                getCabCargaCompartilhada={cabCargaCompartilhada}
                getCabStatusSimulacao={cabStatusSimulacao}
                getCabObservacao={cabObservacao}
                getCabOrdemCompra={cabOrdemCompra}
              />
            ) : null}
          </TabPane>
        </TabContent>
        {tabHeader}

        {cabNuSimCli > 0 &&
        (activeTab == tabNames[0] ||
          activeTab == tabNames[1] ||
          activeTab == tabNames[2]) ? (
          <MenuConfig
            activeTab={activeTab}
            novaVerba={novaVerba}
            tabNames={tabNames}
            cabNuSim={cabNuSim}
            cabNuSimCli={cabNuSimCli}
            getCabStatusSimulacao={cabStatusSimulacao}
            //////////////
            handleNovaVerba={handleNovaVerba}
            handleSetCabProduto={handleSetCabProduto}
            handleSetCabVerbas={handleSetCabVerbas}
            handleSetCabImcGeral={handleSetCabImcGeral}
            handleAddSimCli={handleAddSimCli}
            ////// SET VALIDATION
            setCabValidacaoProduto={setCabValidacaoProduto}
            setCabValidacaoCliente={setCabValidacaoCliente}
            setCabValidacaoFrete={setCabValidacaoFrete}
            setCabValidacaoVerba={setCabValidacaoVerba}
            //////// GET VALIDATION
            cabValidacaoProduto={cabValidacaoProduto}
            cabValidacaoCliente={cabValidacaoCliente}
            cabValidacaoFrete={cabValidacaoFrete}
            cabValidacaoVerba={cabValidacaoVerba}
          />
        ) : null}
      </CardHeader>
    </>
  );
};

export default SimuladorHeader;
