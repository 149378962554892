import Home from "views/Home.js";
import Clientes from "views/Clientes/Clientes.jsx";
import Login from "views/Auth/Login.js";
import PrimeiroAcesso from "views/Auth/PrimeiroAcesso.js";
import Simulador from "views/Simulador/Simulador.js";
import SimuladorVenda from "views/Simulador/SimuladorVenda";
import UserProfile from "views/UserProfile.js";
import Consultas from "views/Relatorios/Relatorios";
import Configuracao from "./views/Configuracao";
import { ClientesInadimplentes } from "views/Relatorios/Financeiros/ClientesInadimplentes";
import { ComissaoProvisionada } from "views/Relatorios/Financeiros/ComissaoProvisionada";
import Pendentes from "./views/Pendentes/Pendentes";
import CadastroCliente from "views/Clientes/CadastroCliente";

var routes = [
  {
    path: "/home",
    name: "Home",
    icon: "tim-icons icon-chart-pie-36",
    component: Home,
    layout: "/admin",
  },
  {
    path: "/simulador",
    name: "Simulador de Vendas",
    icon: "tim-icons icon-spaceship",
    component: Simulador,
    layout: "/admin",
  },
  {
    path: "/simulacao-de-vendas",
    name: "Simulação de Venda",
    icon: "tim-icons icon-spaceship",
    component: SimuladorVenda,
    layout: "/admin",
  },
  {
    path: "/clientes",
    name: "Clientes / Parceiros",
    icon: "tim-icons icon-single-02",
    component: Clientes,
    layout: "/admin",
  },
  {
    path: "/cadastroCliente",
    name: "Cadastro de Cliente",
    icon: "tim-icons icon-single-02",
    component: CadastroCliente,
    layout: "/admin",
  },
  {
    path: "/consultas",
    name: "Consultas / Relatórios",
    icon: "tim-icons icon-single-02",
    component: Consultas,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "Usuário",
    icon: "tim-icons icon-single-02",
    component: UserProfile,
    layout: "/admin",
  },

  {
    path: "/configuracao",
    name: "Configuração",
    icon: "tim-icons icon-single-02",
    component: Configuracao,
    layout: "/admin",
  },

  {
    path: "/pendentes",
    name: "Simuladores Pendentes",
    icon: "tim-icons icon-single-02",
    component: Pendentes,
    layout: "/admin",
  },

  ///////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////
  /////////////////////  RELATÓRIOS  ////////////////////////
  ///////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////
  {
    path: "/relatorios/clientes-inadimplentes",
    name: "Clientes Inadimplentes",
    icon: "tim-icons icon-single-02",
    component: ClientesInadimplentes,
    layout: "/admin",
  },
  {
    path: "/relatorios/comissoes-provisionadas",
    name: "Comissões Provisionadas",
    icon: "tim-icons icon-single-02",
    component: ComissaoProvisionada,
    layout: "/admin",
  },
  ///////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////
  /////////////////LAYOUT DE AUTENTICAÇÃO////////////////////
  ///////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////
  {
    path: "/primeiro-acesso",
    name: "PrimeiroAcesso",
    icon: "tim-icons icon-lock-circle",
    component: PrimeiroAcesso,
    layout: "/auth",
  },
  {
    path: "/login",
    name: "Login",
    icon: "tim-icons icon-lock-circle",
    component: Login,
    layout: "/auth",
  },
];
export default routes;
