// lida com as requisições
import { api } from "services/api";
import React, { useState, useEffect, Component } from "react";
// spinners/loaders do react-spinners (tem vários estilos)
// import ClockLoader from "react-spinners/ClockLoader";
// alteração no título da página no navegador
import { decode as base64_decode } from "base-64";
import { Helmet } from "react-helmet";
import {
  StatusTypes,
  getUserIdFromSession,
  userCantInclude,
} from "../../services/utils";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
} from "reactstrap";
// lida com reactjs

import { toast } from "react-toastify";
// lida com rotas
import { NavLink } from "react-router-dom";
// Componente ModalSimCli
import ModalExtSimCli from "views/Simulador/Components/ModalExtSimCli";
import ModalLoading from "components/Loading/ModalLoading";
import { proxyApi } from "services/proxyApi";

// eslint-disable-next-line no-undef
const md5 = require("md5");
// eslint-disable-next-line no-undef
const moment = require("moment");

export const SimuladorListagem = (props) => {
  const defaultState = {
    tituloPagina: "Simulador Listagem",
    idUsuario: getUserIdFromSession(sessionStorage.getItem(md5("CODUSU"))),
    setorUsuario: base64_decode(sessionStorage.getItem(md5("NUSETOR"))),
    loading: false,
    modalSimCli: false,
    nuSimSelecionado: null,
    simuladores: [],
    per_page: 10,
    first_page_url: null,
    current_page: 1,
    prev_page_url: null,
    last_page: null,
    last_page_url: null,
    path: null,
    nextPage1: null,
    nextPage2: null,
    total: null,
    textSearchNuSim: "",
    textSearchPartner: "",
    statusSimuladorFilter: null,
  };

  const [state, setState] = useState({ ...defaultState });

  sessionStorage.removeItem(md5("SIMULADOR_NUSIM"));
  sessionStorage.removeItem(md5("SIMULADOR_NUSIMCLI"));

  const handleModalSimCli = (nuSim) => {
    setState((currentState) => ({
      ...currentState,
      modalSimCli: !state.modalSimCli,
      nuSimSelecionado: nuSim,
    }));
  };
  const handleCopy = async (nuSim) => {
    setState((currentState) => ({ ...currentState, loading: true }));
    const response2 = await proxyApi.get(
      `simulador/${state.idUsuario}/${nuSim}`
    );

    const newSimulator = {
      ...response2.data.simulador,
    };

    delete newSimulator.NUSIM;

    const newSimulatorCustomer = {
      ...response2.data.simuladorCli,
    };

    // fazer loop pegar todos simcli

    const oldSimulatorItems = await proxyApi.get(
      `simulador-item/${state.idUsuario}/${nuSim}/${newSimulatorCustomer[0].NUSIMCLI}/0`
    );

    // criação do simulador
    const newSimulatorResponse = await api.post(
      `simulador/${state.idUsuario}`,
      {
        codUsuInc: base64_decode(sessionStorage.getItem(md5("CODUSU"))),
        cargaCompartilhada: newSimulator.CARGACOMPARTILHADA,
        statusSimulador: 0,
      }
    );
    const newNUSIM = newSimulatorResponse.data.simulador.NUSIM;

    // criação simulador cliente
    const customerSimulatorLoop = async (idx) => {
      const data = newSimulatorCustomer[idx];
      if (!data) return;

      const response = await proxyApi(
        `simulador-cliente/${state.idUsuario}/${data?.NUSIMCLI}`,
        { codParc: data?.CODPARC }
      );
      // console.log("aqui2--->");

      const oldSimulatorCustomerData = response?.data?.simuladorCli;
      delete oldSimulatorCustomerData.NUSIM;
      delete oldSimulatorCustomerData.NUSIMCLI;

      const dataSimulator = {
        codUser: base64_decode(sessionStorage.getItem(md5("CODUSU"))),
        codVend: base64_decode(sessionStorage.getItem(md5("CODVEND"))),
        codUsuInc: base64_decode(sessionStorage.getItem(md5("CODUSU"))),
        nuSim: newNUSIM,
        codParc: oldSimulatorCustomerData?.CODPARC,
        codEmp: oldSimulatorCustomerData?.CODEMP,
        nroReg: oldSimulatorCustomerData?.NROREG,
        tipoPagDescarga: oldSimulatorCustomerData?.TIPOPAGDESCARGA,
        tipoFaturamento: oldSimulatorCustomerData?.TIPOFAT,
        vlrDegustacao: oldSimulatorCustomerData?.DEGUSTACAO,
        vlrDescarga: oldSimulatorCustomerData?.DESCARGA,
        observacao: oldSimulatorCustomerData?.OBSERVACAO,
        ordemCompra: oldSimulatorCustomerData?.ORDEMCOMPRA,
        codTipVenda: oldSimulatorCustomerData?.CODTIPNEG,
        descrTipVenda: oldSimulatorCustomerData?.DESCRTIPVENDA,
        prazoMedio: oldSimulatorCustomerData?.PRAZOMEDIO,
        cargaCompartilhada: oldSimulatorCustomerData?.CARGACOMPARTILHADA,
        TIPOENTREGA: oldSimulatorCustomerData?.TIPOENTREGA,
        VLR_ENTREGA: oldSimulatorCustomerData?.VLR_ENTREGA,
        PER_ENTREGA: oldSimulatorCustomerData?.PER_ENTREGA,
      };
      const newSimulatorCustomerResponse = await proxyApi.post(
        `simulador-cliente/${state.idUsuario}`,
        dataSimulator
      );

      const newNUSIMCLI =
        newSimulatorCustomerResponse?.data?.simuladorCli?.NUSIMCLI;

      const row = {
        nuSim: newNUSIM.toString(),
        nuSimCli: newNUSIMCLI.toString(),
        codVend: base64_decode(sessionStorage.getItem(md5("CODVEND"))),
        produtos: oldSimulatorItems.data?.simuladorIte
          ?.filter((product) => product.QTDNEG > 0)
          .map((product) => ({
            ...product,
            QTDNEG: Number(product.QTDNEG),
            PRECONEG: Number(product.PRECONEG),
            M3: Number(product.M3),
            M3TOTAL: Number(product.M3TOTAL),
            PESOBRUTO: Number(product.PESOBRUTO),
            PESOBRUTOTOTAL: Number(product.PESOBRUTOTOTAL),
            CUSTOUNPRODUCAO: Number(product.CUSTOUNPRODUCAO),
            MC: Number(product.MC),
            MCT: Number(product.MCT),
            IMC_ITEM: Number(product.IMC_ITEM),
            PRECOMIN: Number(product.PRECOMIN),
            PRECOMAX: Number(product.PRECOMAX),
            VLRTABPADRAO: Number(product.VLRTABPADRAO),
            VLRTAB: Number(product.VLRTAB),
            VLRTABTRANSF: Number(product.VLRTABTRANSF),
            VLRTOTAL: Number(product.VLRTOTAL),
            PERCRCA: Number(product.PERCRCA),
            PERCRCAMAX: Number(product.PERCRCAMAX),
            VLRCOMISSAORCA: Number(product.VLRCOMISSAORCA),
            CREDICMS_PER: Number(product.CREDICMS_PER),
            CREDICMS_TOTAL: Number(product.CREDICMS_TOTAL),
            DEBICMS_TOTAL: Number(product.DEBICMS_TOTAL),
            DEBICMS_PER: Number(product.DEBICMS_PER),
            NUSIMITE: newSimulatorCustomerResponse.data.simuladorIte.find(
              (newProduct) => newProduct.CODPROD === product.CODPROD
            ).NUSIMITE,
            updated: true,
            NUSIM: newNUSIM,
            NUSIMCLI: newNUSIMCLI,
            CODPROD: Number(product.CODPROD),
            CODGRUPAI: Number(product.CODGRUPAI),
          })),
      };

      await api.put(`simulador-item/${state.idUsuario}`, row);

      await customerSimulatorLoop(idx + 1);
    };
    await customerSimulatorLoop(0);

    await handlePerPage(state.per_page);

    setState((currentState) => ({ ...currentState, loading: false }));
  };

  const [statusParam, setStatusParam] = useState("");

  useEffect(() => {
    handlePaginate(state.current_page);
  }, [statusParam]);

  useEffect(() => {
    setStatusParam(
      state.statusSimuladorFilter && state.statusSimuladorFilter != "Todos"
        ? `&status=${state.statusSimuladorFilter}`
        : ""
    );
  }, [state.statusSimuladorFilter]);

  const handleSearchByNumSim = async (nuSim) => {
    try {
      let response;
      if (nuSim.toString().trim() == "") {
        response = await proxyApi.get(
          `simulador/${state.idUsuario}/paginate/${state.per_page}/?page=1${statusParam}`
        );
      } else {
        response = await api.get(
          `simulador/showByNumber/${state.idUsuario}/${nuSim}`
        );
      }

      if (response.data?.statusApi == 1) {
        setState((currentState) => ({
          ...currentState,
          simuladores: response.data.simulador.data,
        }));
      } else {
        toast.error(response?.data?.statusMessage, {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        });
        // //console.log("teste", response.data);
      }
    } catch (error) {
      console.log("error 3", error);
      toast.error(
        `Não foi possível conectar ao servidor, atualize a página e tente novamente. //PagiSim-x1 ${error.message}`,
        {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        }
      );
      console.error("error: ", error);
    }
  };
  const handleSearchByPartner = async (partnerName) => {
    try {
      let response;
      if (partnerName.toString().trim() == "") {
        response = await proxyApi.get(
          `simulador/${state.idUsuario}/paginate/${state.per_page}/?page=1${statusParam}`
        );
      } else {
        response = await api.get(
          `simulador/showByPartner/${state.idUsuario}/${partnerName}/10`
        );
      }

      // //console.log("--", response);
      if (response.data?.statusApi == 1) {
        setState((currentState) => ({
          ...currentState,
          simuladores: response.data.simulador.data,
        }));
      } else {
        toast.error(response?.data?.statusMessage, {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        });
        // //console.log("teste", response.data);
      }
    } catch (error) {
      console.log("error 4", error);
      toast.error(
        `Não foi possível conectar ao servidor, atualize a página e tente novamente. //PagiSim-x1 ${error.message}`,
        {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        }
      );
      console.error("error: ", error);
    }
  };

  const handlePaginate = async (pagina) => {
    let nextPage = null;
    if (pagina == "first") {
      nextPage = 1;
    } else if (pagina == "last") {
      nextPage = state.last_page;
    } else if (pagina > 0) {
      nextPage = pagina;
    }
    try {
      setState((currentState) => ({ ...currentState, loading: true }));
      let response;

      if (state.textSearchPartner.toString().trim() == "") {
        response = await proxyApi.get(
          `simulador/${state.idUsuario}/paginate/${state.per_page}/?page=${nextPage}${statusParam}`
        );
      } else {
        response = await api.get(
          `simulador/showByPartner/${state.idUsuario}/${state.textSearchPartner}/` +
            state.per_page +
            "/?page=" +
            nextPage
        );
      }

      //      //console.log(response);
      if (response.data?.statusApi == 1) {
        // não precisa de informação adicional, apenas liberação do botão pra atualização, pois já tem armazenado no estado todas as informações do cliente
        setState((currentState) => ({
          ...currentState,
          simuladores: response.data.simulador.data,
          current_page: response.data.simulador.current_page,
          last_page: response.data.simulador.last_page,
          total: response.data.simulador.total,
        }));
        handleNextPage(response.data.simulador.last_page);
      } else {
        toast.error(response?.data?.statusMessage, {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        });
        //console.log("teste", response.data);
      }
      setState((currentState) => ({ ...currentState, loading: false }));
    } catch (error) {
      setState((currentState) => ({ ...currentState, loading: false }));
      console.log("error 5", error);
      toast.error(
        `Não foi possível conectar ao servidor, atualize a página e tente novamente. //PagiSim-x1 ${error.message}`,
        {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        }
      );
      console.error("error: ", error);
    }
  };

  const handleNextPage = (last_page) => {
    if (last_page < state.current_page + 1) {
      setState((currentState) => ({
        ...currentState,
        nextPage1: 1,
        nextPage2: last_page > 1 ? 2 : 1,
      }));
    } else {
      setState((currentState) => ({
        ...currentState,
        nextPage1: state.current_page + 1,
        nextPage2:
          last_page >= state.current_page + 2 ? state.current_page + 2 : 1,
      }));
    }
  };

  const handlePerPage = async (newPerPage) => {
    setState((currentState) => ({ ...currentState, per_page: newPerPage }));
    try {
      const response = await proxyApi.get(
        `simulador/${state.idUsuario}/paginate/${newPerPage}/?page=1${statusParam}`
      );
      if (response.data?.statusApi == 1) {
        setState((currentState) => ({
          ...currentState,
          simuladores: response.data.simulador.data,
          current_page: response.data.simulador.current_page,
          last_page: response.data.simulador.last_page,
          total: response.data.simulador.total,
        }));
        handleNextPage(response.data.simulador.last_page);
      } else {
        toast.error(response?.data?.statusMessage, {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        });
      }
    } catch (error) {
      console.log("error 6", error);
      toast.error(
        `Não foi possível conectar ao servidor, atualize a página e tente novamente. //ListSim-x1 ${error.message}`,
        {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        }
      );
      console.error("error: ", error);
    }
  };

  const keyDownSeachNumSim = (e) => {
    if (e.which == 13 || e.keyCode == 13) {
      handleSearchByNumSim(state.textSearchNuSim);
    }
  };

  const keyDownSeachPartner = (e) => {
    if (e.which == 13 || e.keyCode == 13) {
      handleSearchByPartner(state.textSearchPartner);
    }
  };

  const handleStatus = (value) => {
    setState((currentState) => ({
      ...currentState,
      statusSimuladorFilter: value,
    }));
  };

  return (
    <>
      <Helmet>
        <title>{state.tituloPagina} | Semalo</title>
      </Helmet>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                {state.setorUsuario != "1" && (
                  <Col>
                    {" "}
                    {!userCantInclude(state.setorUsuario) && (
                      <NavLink
                        to="/admin/simulacao-de-vendas"
                        className="float-right"
                      >
                        <Button color="primary" className="text-center">
                          Iniciar
                          <br />
                          simulação
                        </Button>
                      </NavLink>
                    )}
                  </Col>
                )}
              </CardHeader>
              <CardBody>
                <Table responsive striped>
                  <thead>
                    <tr>
                      <th
                        className="text-center"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span>Nº da Simulação</span>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "20px",
                          }}
                        >
                          <Input
                            value={state.textSearchNuSim}
                            onKeyDown={(e) => keyDownSeachNumSim(e)}
                            onChange={(e) =>
                              setState((currentState) => ({
                                ...currentState,
                                ...state,
                                textSearchNuSim: e.target.value,
                              }))
                            }
                            id="simulador-numero-simulacao"
                            style={{ width: "100px", marginRight: "2px" }}
                          ></Input>
                          <Button
                            className="btn-icon btn-round"
                            color="primary"
                            onClick={() =>
                              handleSearchByNumSim(state.textSearchNuSim)
                            }
                            size="sm"
                          >
                            <i className="fa fa-search"></i>
                          </Button>
                        </div>
                      </th>
                      <th className={"text-center"}>
                        <span> Cliente</span>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "20px",
                          }}
                        >
                          <Input
                            value={state.textSearchPartner}
                            onKeyDown={(e) => keyDownSeachPartner(e)}
                            onChange={(e) =>
                              setState((currentState) => ({
                                ...currentState,
                                ...state,
                                textSearchPartner: e.target.value,
                              }))
                            }
                            style={{ width: "150px", marginRight: "2px" }}
                            id="simulador-nome-cliente"
                          ></Input>
                          <Button
                            className="btn-icon btn-round"
                            color="primary"
                            onClick={() =>
                              handleSearchByPartner(state.textSearchPartner)
                            }
                            size="sm"
                          >
                            <i className="fa fa-search"></i>
                          </Button>
                        </div>
                      </th>
                      <th className="text-left">Dt. Inclusão</th>
                      <th className="text-left">Dt. Alteração</th>
                      <th
                        className="text-left"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>Status</span>
                        <div>
                          <Input
                            type="select"
                            id="id-status-select-filter"
                            name="status-select-filter"
                            onChange={(e) => handleStatus(e.target.value)}
                          >
                            <option value={null} key={-1}>
                              Todos
                            </option>
                            {StatusTypes.map((item) => (
                              <option value={item.id} key={item.id}>
                                {item.description}
                              </option>
                            ))}
                          </Input>
                        </div>
                      </th>
                      <th className="text-center">%IMC</th>
                      <th className="text-right"> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.simuladores.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-center">{item.NUSIM}</td>
                          <td className="text-center">
                            {item.CODPARC + " - " + item.NOMEPARC}
                          </td>
                          <td className="text-left">
                            {moment(item.DHINC).format("DD/MM/YYYY HH:mm:ss")}
                          </td>
                          <td className="text-left">
                            {moment(item.DHALTER).format("DD/MM/YYYY HH:mm:ss")}
                          </td>
                          <td className="text-left">{item.OPCAO}</td>
                          <td className="text-center">{item.IMCGERAL}</td>
                          <td
                            className="text-right"
                            style={{ gap: "8px", display: "flex" }}
                          >
                            {/* <Button
                                className="btn-icon btn-round"
                                color="primary"
                                onClick={() => handleCopy(item.NUSIM)}
                                size="sm"
                              >
                                <i className="fa fa-copy"></i>
                              </Button> */}
                            <Button
                              className="btn-icon btn-round"
                              color="primary"
                              onClick={() => handleModalSimCli(item.NUSIM)}
                              size="sm"
                            >
                              <i className="fa fa-search"></i>
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <Row>
                  <Col>
                    <Pagination
                      size="sm"
                      aria-label="Page navigation example"
                      className="float-right"
                    >
                      {"ㅤ"} {/*da um espaço fixo entre os componentes*/}
                      <PaginationItem>
                        <PaginationLink
                          first
                          onClick={() => handlePaginate("first")}
                        />
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          previous
                          onClick={() => handlePaginate(state.current_page - 1)}
                        />
                      </PaginationItem>
                      <PaginationItem active={true}>
                        <PaginationLink>{state.current_page}</PaginationLink>
                      </PaginationItem>
                      <PaginationItem active={false}>
                        <PaginationLink
                          onClick={() => handlePaginate(state.nextPage1)}
                        >
                          {state.nextPage1}
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem active={false}>
                        <PaginationLink
                          onClick={() => handlePaginate(state.nextPage2)}
                        >
                          {state.nextPage2}
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          next
                          onClick={() => handlePaginate(state.nextPage1)}
                        />
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          last
                          onClick={() => handlePaginate("last")}
                        />
                      </PaginationItem>
                    </Pagination>
                    <UncontrolledDropdown
                      direction="up"
                      className="float-right"
                    >
                      <DropdownToggle
                        caret
                        data-toggle="dropdown"
                        color="primary"
                        size="sm"
                        style={{ padding: "5px" }}
                      >
                        {state.per_page}
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-black">
                        <DropdownItem onClick={() => handlePerPage(10)}>
                          10
                        </DropdownItem>
                        <DropdownItem onClick={() => handlePerPage(15)}>
                          15
                        </DropdownItem>
                        <DropdownItem onClick={() => handlePerPage(20)}>
                          20
                        </DropdownItem>
                        <DropdownItem onClick={() => handlePerPage(30)}>
                          30
                        </DropdownItem>
                        <DropdownItem onClick={() => handlePerPage(50)}>
                          50
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <Row>
                      <p>Total de Registros: {state.total}</p>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <ModalExtSimCli
        toggle={handleModalSimCli}
        isOpen={state.modalSimCli}
        nuSim={state.nuSimSelecionado}
        copyRefresh={() => handlePerPage(state.per_page)}
      />
      <ModalLoading
        loading={state.loading}
        mensagem={"Carregando Simuladores de Vendas"}
      />
    </>
  );
};

export default SimuladorListagem;
