import React, { useState } from "react";
import { api } from "services/api";
import { toast } from "react-toastify";
const md5 = require("md5");
import { formatCpfCnpj } from "services/globals";
import ModalLoading from "components/Loading/ModalLoading";
import { Row, Button, Col, Form, Input, Table, Label } from "reactstrap";
import { MdCreate } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { LogAirbrake } from "services/log.airbrake";
import { useSimuladorContext } from "contexts/SimuladorContext";

export default function BuscaCliente(props) {
  const { setDataSimulador } = useSimuladorContext();

  const history = useHistory();
  const { getCabClientes, handleAddSimCli } = props;
  const defaultState = {
    focused: "",
    buscaCodParc: "",
    buscaCliente: "",
    buscaCnpj: "",
    selected: false,
    loading: false,
    idUsuario: sessionStorage.getItem(md5("CODUSU")),
  };

  const [state, setState] = useState(defaultState);

  function handleChange(target) {
    let name = target.name;
    let value = target.value;
    setState({ ...state, [name]: value });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { buscaCodParc, buscaCliente, buscaCnpj, idUsuario } = state;

    setState((state) => ({ ...state, loading: true, selected: false }));

    if (
      !(buscaCodParc == "" || buscaCodParc == null) ||
      !(buscaCliente == "" || buscaCliente == null) ||
      !(buscaCnpj == "" || buscaCnpj == null)
    ) {
      let raw = {
        codParc: buscaCodParc,
        nomeBusca: buscaCliente,
        cpfCnpj: buscaCnpj,
      };
      // //console.log(JSON.stringify(raw));
      try {
        const response = await api.post(`cliente/busca/${idUsuario}`, raw);
        if (response.data?.statusApi === 1) {
          // console.log(response.data, "busca-clientes");
          props.setCabClientes(response.data.cliente);
        } else {
          props.setCabClientes([]); //atualizando o estado para pesquisa atual, vazio pois não teve retorno.
          toast.error(response.data.message, {
            autoClose: 7000,
            icon: () => <i className="tim-icons icon-simple-remove" />,
          });
        }
      } catch (error) {
        console.log("error 1", error);
        LogAirbrake(error, {
          url: `cliente/busca/${idUsuario}`,
          method: "POST",
          data: raw,
        });

        toast.error(
          `Não foi possível conectar ao servidor, atualize a página e tente novamente. ${error.message}`,
          {
            autoClose: 7000,
            icon: () => <i className="tim-icons icon-simple-remove" />,
          }
        );
        // notify("tc","danger","tim-icons icon-simple-remove",);
        console.error("error: ", error);
      }
    } else {
      toast.error(
        "Informe corretamente os dados do cliente para busca otimizada.",
        {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        }
      );
    }
    setState((state) => ({ ...state, loading: false }));
  };
  const handleSetCabCodParc = (codParc, nomeFantasia) => {
    setDataSimulador((state) => ({ ...state, CODPARC: codParc }));

    setState((state) => ({ ...state, selected: true }));
    toast.success(
      <div>
        <b>Cliente Selecionado</b>
        <br />
        <b>Cód.: </b>
        {codParc}
        <br />
        <b>Nome Fantasia: </b>
        {nomeFantasia}
      </div>,
      { autoClose: 7000 }
    );
    props.setCabCodParc(codParc); //envia os dados para
  };

  return (
    <>
      <Col>
        <Row style={{ padding: "10px" }}>
          <Button
            className="float-right"
            color="primary"
            onClick={() => history.push("/admin/cadastroCliente")}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            <MdCreate /> Cadastrar
          </Button>
        </Row>
        <Row>
          <Col style={{ padding: "15px 10px" }}>
            <Form onSubmit={(e) => handleSubmit(e)}>
              <div className="form-row">
                <Col md={2}>
                  <Label for="buscaCodParc">Código</Label>
                  <Input
                    type="number"
                    id="buscaCodParc"
                    name="buscaCodParc"
                    placeholder="Informe o código do cliente."
                    value={state.buscaCodParc}
                    onChange={(e) => handleChange(e.target)}
                    min={0}
                  />
                </Col>
                <Col md={5}>
                  <Label for="buscaCliente">Código / Razão Social</Label>
                  <Input
                    type="text"
                    id="buscaCliente"
                    name="buscaCliente"
                    placeholder="Informe o nome do cliente"
                    value={state.buscaCliente}
                    onChange={(e) => handleChange(e.target)}
                  />
                </Col>
                <Col md={5}>
                  <Label for="buscaCnpj">CPF / CNPJ</Label>
                  <Input
                    type="text"
                    id="buscaCnpj"
                    name="buscaCnpj"
                    placeholder="Informe o CNPJ"
                    value={state.buscaCnpj}
                    onChange={(e) => handleChange(e.target)}
                  />
                </Col>
                <Col md={12} style={{ marginTop: "10px" }}>
                  <Button
                    className="float-right"
                    color="primary"
                    disabled={state.loading}
                  >
                    Buscar
                  </Button>
                </Col>
              </div>
            </Form>
          </Col>
        </Row>
        <Row>
          {getCabClientes?.length > 0 ? (
            <Table responsive style={{ fontSize: ".8rem" }}>
              <thead>
                <tr>
                  <th className="text-center">Cód.</th>
                  <th>Razão Social</th>
                  <th>Nome Fantasia</th>
                  <th>Cidade</th>
                  <th className="text-center">CPF/CNPJ</th>
                  <th className="text-right">Ações</th>
                </tr>
              </thead>
              <tbody>
                {getCabClientes.map((cliente) => {
                  return (
                    <tr key={cliente.CODPARC}>
                      <td className="text-center">{cliente.CODPARC}</td>
                      <td>{cliente.RAZAO}</td>
                      <td>{cliente.FANTASIA}</td>
                      <td>{cliente.NOMECID}</td>
                      <td className="text-right">
                        {formatCpfCnpj(cliente.CGC_CPF)}
                      </td>
                      <td className="text-right">
                        <Button
                          className="btn-icon btn-round"
                          color="primary"
                          size="sm"
                          onClick={() => {
                            handleAddSimCli();
                            handleSetCabCodParc(
                              cliente.CODPARC,
                              cliente.FANTASIA
                            );
                          }}
                          disabled={state.selected}
                        >
                          <i className="fa fa-share"></i>
                        </Button>
                        {` `}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : null}
        </Row>
      </Col>
      <ModalLoading loading={state.loading} mensagem={"Buscando Clientes"} />
    </>
  );
}
