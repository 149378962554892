import React from "react";
// trabalho as rotas
import {
  useHistory,
  Route,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";

import { useState, useEffect } from "react";
// alteração no título da página no navegador
import { Helmet } from "react-helmet";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  CardHeader,
  CardTitle,
  CardFooter,
} from "reactstrap";
import { api } from "services/api";
import NotificationAlert from "react-notification-alert";
import routes from "routes.js";
// lidar com criptografias
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { LogAirbrake } from "services/log.airbrake";

function PrimeiroAcesso() {
  const location = useLocation();
  const history = useHistory();
  //lidar com criptografias
  const md5 = require("md5");
  //lida com usuario
  const idUsuario = sessionStorage.getItem(md5("CODUSU"));
  //lida com os states
  const [ultimaSenha, setUltimaSenha] = useState(
    sessionStorage.getItem(md5("DTULTIMASENHA"))
  );
  const [toPage, setToPage] = useState("");
  const [senhaAtual, setSenhaAtual] = useState("");
  const [senhaAtualErrada, setSenhaAtualErrada] = useState(false);
  const [senhaNova, setSenhaNova] = useState("");
  const [senhaRepete, setSenhaRepete] = useState("");
  const [autenticando, setAutenticando] = useState(false);
  const [senhaValida, setSenhaValida] = useState(true);
  //lidar com datas
  const moment = require("moment");

  const notificationAlertRef = React.useRef(null);
  const notify = (place, type, icon, message) => {
    // type => "primary" || "success" || "danger" || "warning" || "info"
    let options = {};
    options = {
      place: place,
      message: message,
      type: type,
      icon: icon,
      autoDismiss: 8,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  React.useEffect(() => {
    if (
      sessionStorage.getItem(md5("DTULTIMASENHA")) !=
      base64_encode(md5("alterar"))
    ) {
      setToPage("login");
    } //verifica houve solicitação para alterar a senha
  }, [ultimaSenha]);

  async function handleSubmit(e) {
    e.preventDefault();
    setAutenticando(true);
    // notify("tc","info","tim-icons icon-badge",('mensagem aqui'));
    if (
      senhaNova.length >= 6 &&
      senhaNova == senhaRepete &&
      senhaNova != senhaAtual
    ) {
      try {
        const response = await api.post(`alteraSenha/${idUsuario}`, {
          codUsu: sessionStorage.getItem(md5("CODUSU")),
          login: sessionStorage.getItem(md5("NOMEUSU")),
          senhaAtual: base64_encode(senhaAtual),
          senhaNova: base64_encode(senhaNova),
          dataSenha: base64_encode(moment().format("DD/MM/YYYY HH:mm:ss")),
        });

        if (response.data?.statusApi === 1) {
          sessionStorage.setItem(
            md5("DTULTIMASENHA"),
            base64_encode(response.data.user.DTULTIMASENHA)
          ); // data da ultima troca de senha
          setToPage("home");
        } else {
          notify(
            "tc",
            "danger",
            "tim-icons icon-simple-remove",
            <div>
              <h5>
                <b>Erro:</b>
                {"ㅤ"}
                {response.data.message}
              </h5>
              <p>
                <b>Solução:</b>
                {"ㅤ"}
                {response.data.solucao}
              </p>
            </div>
          );
          setAutenticando(false);
        }
      } catch (error) {
        LogAirbrake(error, {
          url: `alteraSenha/${idUsuario}`,
          method: "POST",
        });

        notify(
          "tc",
          "danger",
          "tim-icons icon-simple-remove",
          `Não foi possível conectar ao servidor, atualize a página e tente novamente. ${error.message}`
        );
        setAutenticando(false);
        console.error("error: ", error);
      }
    } else {
      notify(
        "tc",
        "danger",
        "tim-icons icon-simple-remove",
        <div>
          <h5>
            <b>Erro:</b>
            {"ㅤ"}Senha inválida
          </h5>
          <p>
            <b>Solução:</b>
            {"ㅤ"}Nova senha não pode ser igual senha atual.
          </p>
        </div>
      );
      setSenhaValida(false);
    }
    setAutenticando(false);
  }
  return (
    <>
      <Switch>
        {getRoutes(routes)}
        {toPage == "home" ? (
          <Redirect to="/admin/home" />
        ) : toPage == "login" ? (
          <Redirect to="/" />
        ) : null}
      </Switch>
      <Helmet>
        <title>Primeiro Acesso | Semalo</title>
      </Helmet>
      <div className="react-notification-alert-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container>
        <Col
          className="ml-auto mr-auto"
          lg="4"
          md="6"
          style={{ marginTop: "4rem" }}
        >
          <Card className="card-lock">
            <CardHeader className="text-center">
              <CardTitle tag="h3">
                <b>Sua senha expirou</b>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit}>
                <FormGroup
                  className={senhaAtualErrada == true ? "has-danger" : null}
                >
                  <Label for="senhaAtual">Senha Atual</Label>
                  <Input
                    type="password"
                    name="senhaAtual"
                    id="senhaAtual"
                    placeholder="Digite a senha atual."
                    onChange={(e) => setSenhaAtual(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup
                  className={
                    senhaNova.length >= 6 &&
                    senhaNova == senhaRepete &&
                    senhaNova != senhaAtual &&
                    senhaValida
                      ? "has-success"
                      : "has-danger"
                  }
                >
                  <Label for="senhaNova">Nova Senha</Label>
                  <Input
                    type="password"
                    name="senhaNova"
                    id="senhaNova"
                    maxLength="30"
                    minLength="6"
                    placeholder="Digite a nova senha."
                    onChange={(e) => setSenhaNova(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup
                  className={
                    senhaNova.length >= 6 &&
                    senhaNova == senhaRepete &&
                    senhaRepete != senhaAtual &&
                    senhaValida
                      ? "has-success"
                      : "has-danger"
                  }
                >
                  <Label for="senhaRepete">Repita a Senha</Label>
                  <Input
                    type="password"
                    name="senhaRepete"
                    id="senhaRepete"
                    maxLength="30"
                    minLength="6"
                    placeholder="Repita a nova senha."
                    onChange={(e) => setSenhaRepete(e.target.value)}
                    required
                  />
                </FormGroup>
                <Button
                  className="btn-round"
                  color="primary"
                  size="lg"
                  block
                  disabled={autenticando}
                >
                  Prosseguir
                </Button>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </>
  );
}

export default PrimeiroAcesso;
