import md5 from "md5";
import { api } from "../../services/api";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import React from "react";
import { getUserIdFromSession } from "../../services/utils";
import { Button, Card, CardBody } from "reactstrap";
import moment from "moment";
import { formatValue } from "../../services/globals";
import { SimuladorPDF } from "../../Reports/Simulador/SimuladorPDF";
import ModalLoading from "../../components/Loading/ModalLoading";
import { decode as base64_decode } from "base-64";
import ModalExtReprovaSim from "../Simulador/Components/ModalExtReprovaSim";
import { LogAirbrake } from "services/log.airbrake";

export default class Pendentes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idUsuario: getUserIdFromSession(sessionStorage.getItem(md5("CODUSU"))),
      enviaEmail: sessionStorage.getItem(md5("ENVIAEMAIL")),
      codUsu: parseInt(base64_decode(sessionStorage.getItem(md5("CODUSU")))),
      loading: false,
      cabCodParc: null,
      modalReprovaSim: false,
      cabClientes: [],
      simulador: [],
    };
  }

  handleModalReprovaSim = (nuSim, reprovou) => {
    this.setState({
      modalReprovaSim: !this.state.modalReprovaSim,
      nuSimSelecionado: nuSim,
    });
    if (reprovou) {
      this.setState({
        ...this.state,
        simulador: this.state.simulador.filter((item) => item.NUSIM != nuSim),
      });
    }
  };

  ModalSimConf = () => {
    // const [simulador, setSimulador] = useState([""]);
    const idUsuario = sessionStorage.getItem(md5("CODUSU"));

    const centerText = {
      textAlign: "center",
    };
    const marginBottomRow = {
      marginBottom: 15,
    };

    React.useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await api.get(`simulador-confirma/${idUsuario}/`);
          if (response.data?.statusApi == 1) {
            this.setState({ simulador: response.data.simulacoes });
          } else {
            toast.error(response?.data?.statusMessage, {
              autoClose: 7000,
              icon: () => <i className="tim-icons icon-simple-remove" />,
            });
            // //console.log("teste", response.data);
          }
        } catch (error) {
          toast.error(
            `Não foi possível conectar ao servidor, atualize a página e tente novamente. ${error.message}`,
            {
              autoClose: 7000,
              icon: () => <i className="tim-icons icon-simple-remove" />,
            }
          );
          console.error("error: ", error);
        }
      };

      fetchData();
    }, []);

    let mostraDirecao;
    if (this.state.codUsu === 50 || this.state.codUsu === 1346) {
      mostraDirecao = (
        <>
          {this.state.simulador.map(
            (
              {
                NUSIM,
                NUSIMCLI,
                DHINC,
                NOMEPARC,
                IMC_CLI,
                M3_CLI,
                SUPERVISOR,
                REPRESENTANTE,
                VLR_FATURAMENTO,
              },
              i
            ) => (
              <div key={i}>
                <Card className="card-chart">
                  <CardBody>
                    <div className="col-md-12" style={centerText}>
                      <div className={"row"} style={marginBottomRow}>
                        <div className="col-4">
                          <p>
                            Nº: <b>{NUSIM}</b>
                          </p>
                        </div>
                        <div className="col-4">
                          <p>{moment(DHINC).format("DD/MM/YYYY HH:mm")}</p>
                        </div>
                        <div className="col-4">
                          <p>
                            {SUPERVISOR} / {REPRESENTANTE}
                          </p>
                        </div>
                      </div>
                      <div className={"row"} style={marginBottomRow}>
                        <div className="col">
                          <p>Cliente: {NOMEPARC}</p>
                        </div>
                      </div>
                      <div className={"row"} style={marginBottomRow}>
                        <div className="col-4">
                          <p>IMC: {IMC_CLI}%</p>
                        </div>
                        <div className="col-4">
                          <p>{formatValue("decimal", M3_CLI ?? 0)} M³</p>
                        </div>
                        <div className="col-4">
                          <p>
                            Faturamento: <b>R$ {VLR_FATURAMENTO}</b>
                          </p>
                        </div>
                      </div>
                      <div className={"row"}>
                        <div className={"col-4"}>
                          <Button
                            className="btn-round"
                            style={{ backgroundImage: "none" }}
                            color="primary"
                            size="sm"
                            onClick={() => SimuladorPDF(NUSIM, NUSIMCLI)}
                          >
                            Ver PDF
                          </Button>
                        </div>
                        <div className={"col-4"}>
                          <Button
                            className="btn-round"
                            style={{ backgroundImage: "none" }}
                            color="danger"
                            size="sm"
                            onClick={() => this.handleModalReprovaSim(NUSIM)}
                          >
                            Reprovar
                          </Button>
                        </div>
                        <div className={"col-4"}>
                          <Button
                            className="btn-round"
                            style={{ backgroundImage: "none" }}
                            color="success"
                            size="sm"
                            onClick={() => this.aprovaPeidodo(NUSIM, NUSIMCLI)}
                          >
                            Aprovar
                          </Button>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            )
          )}
        </>
      );
    } else {
      mostraDirecao = (
        <>
          {
            <Card className="card-chart">
              <CardBody>
                <div className="col-md-12" style={centerText}>
                  <div className={"row"} style={marginBottomRow}>
                    <h3>Você não possui permissão para acessar essa tela</h3>
                  </div>
                </div>
              </CardBody>
            </Card>
          }
        </>
      );
    }

    return mostraDirecao;
  };

  aprovaPeidodo = async (nuSim, nuSimcli) => {
    this.setState({ loading: true });

    let raw = {
      nuSim: nuSim,
      nuSimCli: nuSimcli,
    };
    try {
      const response = await api.put(`simulador-aprova/`, raw, {
        timeout: 60000,
      });

      if (response.data?.statusApi == 1) {
        const { avisos } = response.data;

        toast.success("Simulação aprovada!", { autoClose: 3000 });
        toast.warn(avisos, { autoClose: 3000 });
      } else {
        //console.log(response.data);
        toast.error(response.data.message, {
          autoClose: 3000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        });
      }
      this.setState({
        ...this.state,
        simulador: this.state.simulador.filter(
          (item) => item.NUSIM != nuSim && item.NUSIMCLI != nuSimcli
        ),
      });
    } catch (error) {
      LogAirbrake(error, {
        url: `simulador-aprova/`,
        method: "PUT",
        data: raw,
      });

      // console.error(error);
      toast.error(
        `Não foi possível conectar ao servidor, atualize a página e tente novamente. ${error.message}`,
        {
          autoClose: 5000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        }
      );
    }
    this.setState({ loading: false });
  };

  render() {
    const { loading } = this.state;

    return (
      <>
        <Helmet>
          <title>Simuladores Pendentes | Semalo</title>
        </Helmet>
        <ModalLoading
          loading={loading}
          mensagem={
            "Verificando informações do simulador para gerar o pedido de venda."
          }
        />

        <div className="content">
          <this.ModalSimConf />
          <ModalExtReprovaSim
            toggle={this.handleModalReprovaSim}
            isOpen={this.state.modalReprovaSim}
            nuSim={this.state.nuSimSelecionado}
          />
        </div>
      </>
    );
  }
}
