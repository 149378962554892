import { useGlobalContext } from "contexts/GlobalContext";
import { useSimuladorContext } from "contexts/SimuladorContext";
import React, { useEffect, useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { filterLogistica } from "services/showLogisticaSelec";
import { enterpriseUseDelivery } from "services/utils";

export default function TipoFaturamento(props) {
  const { dataSimulador, setDataSimulador } = useSimuladorContext();
  const { globalContext } = useGlobalContext();

  const [showThis, setShowThis] = useState(false);

  useEffect(() => {
    setShowThis(!enterpriseUseDelivery(dataSimulador.CODEMP));
  }, [dataSimulador]);

  const handleChangeFaturamento = (value) => {
    const selectedTipoFaturamento = globalContext?.tpFaturamento?.find(
      (item) => item.VALOR == value
    );

    if (!selectedTipoFaturamento) {
      setDataSimulador((state) => ({
        ...state,
        TIPOFAT: null,
        NROREG: null,
        comLogistica: false,
      }));

      return;
    }

    setDataSimulador((state) => ({
      ...state,
      TIPOFAT: selectedTipoFaturamento.VALOR,
      NROREG: !selectedTipoFaturamento?.comLogistica ? null : state.NROREG,
      comLogistica: selectedTipoFaturamento.comLogistica,
    }));
  };

  return (
    <>
      {showThis && (
        <FormGroup>
          <Label for="tipoFaturamento">Tipo de Faturamento</Label>
          <Input
            type="select"
            id="tipoFaturamento"
            name="tipoFaturamento"
            onChange={(e) => handleChangeFaturamento(e.target.value)}
            style={{ cursor: "pointer" }}
            disabled={
              globalContext?.simulador?.STATUS != 0 ||
              globalContext.setorUsuario == 1
            }
            required
          >
            <option value="">{"< Selecione Tipo de Faturamento >"}</option>
            {globalContext?.tpFaturamento
              ?.filter((item) =>
                filterLogistica(
                  item,
                  dataSimulador.CODEMP,
                  item.VALOR,
                  item.comLogistica
                )
              )
              .map((faturamento, index) => {
                return (
                  <option
                    key={index}
                    value={faturamento.VALOR}
                    selected={dataSimulador?.TIPOFAT == faturamento.VALOR}
                  >
                    {faturamento.OPCAO}
                  </option>
                );
              })}
          </Input>
        </FormGroup>
      )}
    </>
  );
}
