import React from "react";
import { toast } from "react-toastify";

export const getMessageFromApiResponse = (mensagemBase64) => {
  try {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(mensagemBase64, "text/xml");

    return atob(
      xmlDoc.getElementsByTagName("statusMessage")[0].childNodes[0].nodeValue
    );
  } catch (error) {
    return "";
  }
};

export const enterpriseUseDelivery = (id) => {
  return (
    Number(id) === 6 ||
    Number(id) === 11 ||
    Number(id) === 12 ||
    Number(id) === 13 ||
    Number(id) === 14 ||
    Number(id) === 10
  );
};

export const getUserIdFromSession = (id) => {
  if (!id) {
    toast.error(
      "Não foi possível identificar o código do usuário na sessão, faça login novamente!",
      {
        autoClose: 17000,
        icon: () => <i className="tim-icons icon-simple-remove" />,
      }
    );
  }
  return id;
};
export const getValorDescarga = ({
  AD_DESFECHADA,
  AD_DESPALET,
  AD_DESVOLUME,
  AD_VLRDESFECHADA,
  QTD_NEG,
  AD_VLRDESVOLUME,
  QTD_PALET,
  AD_VLRDESPALET,
}) => {
  if (AD_DESFECHADA == "S" && AD_DESPALET != "S" && AD_DESVOLUME != "S")
    return AD_VLRDESFECHADA;

  if (AD_DESVOLUME == "S" && AD_DESPALET != "S" && AD_DESFECHADA != "S")
    return QTD_NEG * AD_VLRDESVOLUME;

  if (AD_DESPALET == "S" && AD_DESVOLUME != "S" && AD_DESFECHADA != "S")
    return QTD_PALET * AD_VLRDESPALET;

  if (AD_DESPALET == "S" && AD_DESVOLUME == "S" && AD_DESFECHADA != "S")
    return QTD_PALET * AD_VLRDESPALET + QTD_NEG * AD_VLRDESVOLUME;

  return 0;
};

export const userCantInclude = (sector) => {
  return sector == 11 || sector == 8 || sector == 1;
};

export const getStatusDescription = (idStatus) => {
  return StatusTypes.find((item) => item.id == idStatus);
};

export const StatusTypes = [
  { id: 0, description: "Em Preparação" },
  { id: 1, description: "Aguardando Aprovação" },
  { id: 2, description: "Aprovado" },
  { id: 3, description: "Pedido Gerado" },
  // { id: 4, description: "Pedido Faturado" },
];
