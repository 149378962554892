import { createContext, useContext, useEffect, useState } from "react";
import React from "react";
export const ProductContext = createContext();

export const ProductContextProvider = ({ children }) => {
  const [dataProducts, setDataProducts] = useState([]);
  const [m3Cliente, setM3Cliente] = useState(0);
  const [m3Simulador, setM3Simulador] = useState(0);
  const [vlrFaturado, setVlrFaturado] = useState(0);

  return (
    <ProductContext.Provider
      value={{
        dataProducts,
        setDataProducts,
        m3Cliente,
        setM3Cliente,
        m3Simulador,
        setM3Simulador,
        vlrFaturado,
        setVlrFaturado,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export const useProductContext = () => {
  const context = useContext(ProductContext);

  if (!context) {
    throw new Error("useFiles must be used within FileProvider");
  }

  return context;
};
