import React from "react";
import { Card, CardBody, CardHeader, Collapse } from "reactstrap";

import ManipulaVerba from "./ManipulaVerba";
import CardVerbaAutomatica from "./Cards/CardVerbaAutomatica";
// lidar com criptografias MD5
// eslint-disable-next-line no-undef
const md5 = require("md5");

export default class SimuladorVerbas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openedCollapses: [""],
      tipoPagamento: "P",
      idUsuario: sessionStorage.getItem(md5("CODUSU")),
    };
  }

  collapsesToggle(collapse) {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: [],
      });
    } else {
      this.setState({
        openedCollapses: [collapse],
      });
    }
  }

  render() {
    const {
      getCabNuSim,
      getCabNuSimCli,
      getVerbas,
      getCabStatusSimulacao,
      getOptionTipoTitulo,
      /////////////////
      setVerbas,
      setValidacoesVerba,
      setCabImcGeral,
    } = this.props;
    return (
      <>
        <div
          aria-multiselectable={true}
          className="card-collapse"
          id="accordion"
          role="tablist"
        >
          {getVerbas?.map((verba, index) => {
            return (
              <Card
                key={index}
                className="card-plain"
                style={{
                  marginBottom: "0px",
                  borderBottom: "1px solid rgb(76 79 93)",
                  borderRadius: "0px",
                }}
              >
                <CardHeader role="tab">
                  <a
                    aria-expanded={this.state.openedCollapses.includes(
                      `collapse-${index}`
                    )}
                    style={{ cursor: "pointer" }}
                    data-parent="#accordion"
                    data-toggle="collapse"
                    onClick={(e) => {
                      e.preventDefault();
                      this.collapsesToggle(`collapse-${index}`);
                    }}
                  >
                    {verba.IDTIPONEG} - {verba.DESCRNEG}
                    {"ㅤ"}
                    <i className="tim-icons icon-minimal-down" />
                  </a>
                </CardHeader>
                <Collapse
                  role="tabpanel"
                  isOpen={this.state.openedCollapses.includes(
                    `collapse-${index}`
                  )}
                >
                  {verba.TIPOINCLUSAO == "M" ? (
                    <CardBody>
                      <ManipulaVerba
                        getVerbaSel={verba}
                        getCabNuSim={getCabNuSim}
                        getCabNuSimCli={getCabNuSimCli}
                        getCabStatusSimulacao={getCabStatusSimulacao}
                        getOptionTipoTitulo={getOptionTipoTitulo}
                        ///////
                        setVerbas={setVerbas}
                        setValidacoesVerba={setValidacoesVerba}
                        setCabImcGeral={setCabImcGeral}
                        update={true}
                      />
                    </CardBody>
                  ) : (
                    <CardVerbaAutomatica index={index} verba={verba} />
                  )}
                </Collapse>
              </Card>
            );
          })}
        </div>
      </>
    );
  }
}
