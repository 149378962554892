import axios from "axios";
import { host } from "./host";

//timeout em milessegundos

export const api = axios.create({
  baseURL: host + "/api",
  timeout: 90000,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Accept, Authorization, Content-Type",
    "Access-Control-Allow-Methods": "PUT, POST, DELETE, GET, OPTIONS",
  },
});
