import React, { useState } from "react";
// lida com as requisições
import { api } from "services/api";
// lidar com criptografias MD5
const md5 = require("md5");
// lida com as toast/alertas
import { toast } from "react-toastify";
// reactstrap components
import { decode as base64_decode } from "base-64";
import { Button, Modal, ModalBody, Table } from "reactstrap";
import { formatValue } from "services/globals";
import { proxyApi } from "services/proxyApi";

function ModalSimCli(props) {
  const [simuladorCli, setSimuladorCli] = useState([""]);
  const idUsuario = sessionStorage.getItem(md5("CODUSU"));

  const fetchData = async () => {
    if (props.nuSimSelecionado > 0) {
      try {
        const response = await proxyApi.get(
          `simulador/${idUsuario}/${props.nuSimSelecionado}`
        );
        if (response.data?.statusApi == 1) {
          setSimuladorCli(response.data.simuladorCli);
        } else {
          toast.error(response?.data?.statusMessage, {
            autoClose: 7000,
            icon: () => <i className="tim-icons icon-simple-remove" />,
          });
          //console.log("teste",response.data)
        }
      } catch (error) {
        console.log("error 7", error);
        toast.error(
          `Não foi possível conectar ao servidor, atualize a página e tente novamente. //ListSim-x1 ${error.message}`,
          {
            autoClose: 7000,
            icon: () => <i className="tim-icons icon-simple-remove" />,
          }
        );
        console.error("error: ", error);
      }
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [props.getCabNuSim]);

  function handleSelectParcSim(nuSimCli) {
    // //console.log(nuSimCli);
    props.setCabNuSimCli(nuSimCli);
    window.location.reload(false);
  }

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={() => props.toggle()}
      backdrop="static"
      size="xl"
      modalClassName="modal-black"
    >
      <div className="modal-header justify-content-center">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => props.toggle()}
        >
          <i className="tim-icons icon-simple-remove"></i>
        </button>
        <h4>
          <b>Clientes da Simulação - {props.getCabNuSim}</b>
        </h4>
      </div>
      <ModalBody>
        <Table striped>
          <thead>
            <tr>
              <th className="text-center">Cód. Emp.</th>
              <th className="text-center">Cód. Parc</th>
              <th className="text-left">Razão Social</th>
              <th className="text-left">Nome Fantasia</th>
              <th className="text-left">Cidade</th>
              <th className="text-center">%IMC</th>
              <th className="text-right"> </th>
            </tr>
          </thead>
          <tbody>
            {simuladorCli.map((item, index) => {
              return (
                <tr key={index}>
                  <td className="text-center">{item.CODEMP}</td>
                  <td className="text-center">{item.CODPARC}</td>
                  <td className="text-left">{item.RAZAO}</td>
                  <td className="text-left">{item.FANTASIA}</td>
                  <td className="text-center">{item.NOMECID}</td>
                  <td className="text-center">
                    {item.IMC_CLI != null
                      ? formatValue("decimal", item.IMC_CLI)
                      : 0}{" "}
                    %
                  </td>
                  <td className="text-right">
                    <Button
                      className="btn-icon btn-round"
                      color="primary"
                      onClick={() => handleSelectParcSim(item.NUSIMCLI)}
                      size="sm"
                    >
                      <i className="fa fa-edit"></i>
                    </Button>
                    {` `}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </ModalBody>
    </Modal>
  );
}

export default ModalSimCli;
