/* eslint-disable no-undef */
import React from "react";
// lida com as requisições
import { api } from "services/api";
//lidar com criptografias
const md5 = require("md5");
// lidar com criptografias Base64
import { encode as base64_encode } from "base-64";

// reactstrap components
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { toast } from "react-toastify";
// import { toast } from "react-toastify";
// import ModalLoading from 'components/Loading/ModalLoading';
import TabGeral from "./Tabs/TabGeral";

export default class ClienteSelecionado extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idUsuario: sessionStorage.getItem(md5("CODUSU")),
      activeTab: "geral",
      cliente: [],
    };
  }

  async componentDidMount() {
    const { idUsuario } = this.state;
    const { cabCodParc } = this.props;
    try {
      const { data } = await api.get(
        `cliente/${idUsuario}/${base64_encode(cabCodParc)}`
      );
      if (data?.statusApi == "1") {
        //console.log(data.cliente);
        this.setState({
          cliente: data.cliente,
        });
      } else {
        toast.error(data.message, {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        });
        console.error(data);
      }
    } catch (error) {
      console.log("error 2", error);
      toast.error(
        `Não foi possível conectar ao servidor, atualize a página e tente novamente. ${error.message}`,
        {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        }
      );
      console.error("error: ", error);
    }
  }

  handleToggleTab(tab) {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  }

  render() {
    const { activeTab, cliente } = this.state;

    return (
      <>
        <div>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab == "geral" ? "active" : ""}
                onClick={() => this.handleToggleTab("geral")}
              >
                Geral
              </NavLink>
            </NavItem>
            {/* <NavItem>
            <NavLink
              className={(activeTab == "financeiro") ? "active" : ""}
              onClick={()=>this.handleToggleTab("financeiro")}
            >
              Financeiro
            </NavLink>
          </NavItem> */}
            <NavItem>
              <NavLink
                className={activeTab == "historico" ? "active" : ""}
                onClick={() => this.handleToggleTab("historico")}
              >
                Ult. Pedidos
              </NavLink>
            </NavItem>
            {/* <NavItem>
            <NavLink
              className={(activeTab == "contrato") ? "active" : ""}
              onClick={()=>this.handleToggleTab("contrato")}
            >
              Contratos
            </NavLink>
          </NavItem> */}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="geral">
              <TabGeral cabCliente={cliente} />
            </TabPane>
            {/* <TabPane tabId="financeiro">
            {
              (activeTab == "financeiro")
              ? <TabFinanceiro cabCodParc={cabCodParc}/>
              : null
            }
          </TabPane> */}
            <TabPane tabId="historico">
              <Row>
                <Col sm="12">
                  <h4>Histórico</h4>
                </Col>
              </Row>
            </TabPane>
            {/* <TabPane tabId="contrato">
            <Row>
              <Col sm="12">
                <h4>
                  Contratos
                </h4>
              </Col>
            </Row>
          </TabPane> */}
          </TabContent>
        </div>
      </>
    );
  }
}
