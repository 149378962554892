import React, { useState, useEffect } from "react";
import { Row, Col, Input, Label } from "reactstrap";
import { formatValue } from "services/globals";
import { decode as base64_decode } from "base-64";
import md5 from "md5";
import { api } from "../../../services/api";
import { toast } from "react-toastify";
import { useSimuladorContext } from "../../../contexts/SimuladorContext";
import { proxyApi } from "services/proxyApi";

function SimuladorCliente(props) {
  const defaultState = {
    idUsuario: sessionStorage.getItem(md5("CODUSU")),
    getCabcargaCompartilhadaValor: null,
  };

  const [state, setState] = useState({ ...defaultState });

  const { setDataSimulador } = useSimuladorContext();

  useEffect(() => {
    const fetchData = async () => {
      if (
        base64_decode(sessionStorage.getItem(md5("SIMULADOR_NUSIMCLI"))) > 0
      ) {
        const response = await proxyApi.get(
          `simulador-cliente/${state.idUsuario}/${base64_decode(
            sessionStorage.getItem(md5("SIMULADOR_NUSIMCLI"))
          )}`,
          { codParc: props.getCabCodParc }
        );

        // console.log("aqui3--->");

        if (response.data?.statusApi === 1) {
          // console.log('rest4', response.data)

          const { simulador, simuladorCli } = response.data;

          setDataSimulador({ ...simuladorCli });

          setState({
            ...state,
            getCabcargaCompartilhadaValor: simulador.CARGACOMPARTILHADA,
          });
        } else {
          toast.error(response.data.message, {
            autoClose: 7000,
            icon: () => <i className="tim-icons icon-simple-remove" />,
          });
        }
      }
    };
    fetchData();
  }, []);

  const { getCabStatusSimulacao, getCabImcGeral, setCabStatusSimulacao } =
    props;

  return (
    <Row style={{ padding: "15px 0" }}>
      <Col md={4} lg={2}>
        <Label for="statusSimulacao">Status da Simulação</Label>
        <Input
          type="select"
          id="statusSimulacao"
          disabled={getCabStatusSimulacao != 1}
          onChange={(e) => {
            setCabStatusSimulacao(e.target.value);
            setDataSimulador((state) => ({
              ...state,
              STATUS: e.target.value,
              status: e.target.value,
            }));
          }}
        >
          <option value={0} selected={getCabStatusSimulacao == 0}>
            Em Preparação
          </option>
          <option value={1} selected={getCabStatusSimulacao == 1}>
            Aguardando Aprovação
          </option>
          <option value={2} selected={getCabStatusSimulacao == 2} disabled>
            Aprovado
          </option>
          <option value={3} selected={getCabStatusSimulacao == 3} disabled>
            Pedido Gerado
          </option>
          <option value={4} selected={getCabStatusSimulacao == 4} disabled>
            Pedido Faturado
          </option>
        </Input>
      </Col>
      <Col md={4} lg={2}>
        <Label for="imcGeral2">%IMC da Simulação</Label>
        <Input
          id="imcGeral2"
          value={formatValue("decimal", getCabImcGeral)}
          disabled
        />
      </Col>
      <Col md={4} lg={2}>
        <Label for="cargaCompartilhada">Carga Compartilhada</Label>
        <Input
          type="select"
          id="cargaCompartilhada"
          name="cargaCompartilhada"
          onChange={(e) => props.setCabCargaCompartilhada(e.target.value)}
          required
        >
          <option
            value={"N"}
            selected={state.getCabcargaCompartilhadaValor === "N"}
          >
            Não
          </option>
          <option
            value={"S"}
            selected={state.getCabcargaCompartilhadaValor === "S"}
          >
            Sim
          </option>
        </Input>
      </Col>
    </Row>
  );
}

export default SimuladorCliente;
