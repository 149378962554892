/* eslint-disable no-undef */
import React, { useState } from "react";
// lida com as requisições
import { api } from "services/api";
// lida com as toast/alertas
import { toast } from "react-toastify";
// lidar com criptografias
import { encode as base64_encode } from "base-64";
import { decode as base64_decode } from "base-64";

import ModalLoading from "components/Loading/ModalLoading";
const md5 = require("md5");
// reactstrap components
import {
  Button,
  Col,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table,
  ModalHeader,
} from "reactstrap";
// lida com rotas
import { Redirect } from "react-router-dom";
import { proxyApi } from "services/proxyApi";
import { userCantInclude } from "services/utils";
import { LogAirbrake } from "services/log.airbrake";

export default function ModalExtSimCli(props) {
  // eslint-disable-next-line no-undef
  const [simulador, setSimulador] = useState([]);
  const [simuladorCli, setSimuladorCli] = useState([]);
  const [redirectToPage, setRedirectToPage] = useState(null);
  const idUsuario = sessionStorage.getItem(md5("CODUSU"));
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [copyData, setCopyData] = useState(null);
  const [modalCopyMessage, setModalCopyMessage] = useState(
    "Copiando simulador..."
  );
  const userSector = base64_decode(sessionStorage.getItem(md5("NUSETOR")));

  React.useEffect(() => {
    const fetchData = async () => {
      if (props.nuSim > 0) {
        try {
          const response = await proxyApi.get(
            `simulador/${idUsuario}/${props.nuSim}`
          );
          if (response.data?.statusApi == 1) {
            setSimulador({
              cargaCompartilhada:
                response.data.simulador.CARGACOMPARTILHADA == "S"
                  ? "Sim"
                  : "Não",
              status:
                response.data.simulador.STATUS == 0
                  ? "Em Preparação"
                  : response.data.simulador.STATUS == 1
                  ? "Aguardando Aprovação"
                  : response.data.simulador.STATUS == 2
                  ? "Aprovado"
                  : response.data.simulador.STATUS == 3
                  ? "Pedido Gerado"
                  : response.data.simulador.STATUS == 4
                  ? "Pedido Faturado"
                  : "2",
              imcGeral: response.data.simulador.IMCGERAL,
              usuarioInc: response.data.simulador.NOMEUSUINC,
              usuarioAlt: response.data.simulador.NOMEUSUALT,
              codUsuInc: response.data.simulador.CODUSUINC,
              codUsuAlt: response.data.simulador.CODUSUALT,
            });
            setSimuladorCli(response.data.simuladorCli);
          } else {
            toast.error(response?.data?.statusMessage, {
              autoClose: 7000,
              icon: () => <i className="tim-icons icon-simple-remove" />,
            });
          }
        } catch (error) {
          toast.error(
            `Não foi possível conectar ao servidor, atualize a página e tente novamente. //ListSim-x1 ${error.message}`,
            {
              autoClose: 7000,
              icon: () => <i className="tim-icons icon-simple-remove" />,
            }
          );
          console.error("error: ", error);
        }
      }
    };
    fetchData();
  }, [props.nuSim]);

  function handleSelectParcSim(nuSimCli) {
    sessionStorage.setItem(md5("SIMULADOR_NUSIM"), base64_encode(props.nuSim));
    sessionStorage.setItem(md5("SIMULADOR_NUSIMCLI"), base64_encode(nuSimCli));
    setRedirectToPage("/admin/simulacao-de-vendas");
  }

  const askCopy = async (nuSim, nuSimCli) => {
    setShow(true);
    setCopyData({ nuSim, nuSimCli });
  };

  const handleCopy = async ({ nuSim, nuSimCli }) => {
    setShow(false);
    setLoading(true);
    const response2 = await api.get(`simulador/${idUsuario}/${nuSim}`);

    const newSimulator = {
      ...response2.data.simulador,
    };

    delete newSimulator.NUSIM;

    const newSimulatorCustomer = {
      ...response2.data.simuladorCli,
    };

    // fazer loop pegar todos simcli

    // criação do simulador
    const newSimulatorResponse = await api.post(`simulador/${idUsuario}`, {
      codUsuInc: base64_decode(sessionStorage.getItem(md5("CODUSU"))),
      cargaCompartilhada: newSimulator.CARGACOMPARTILHADA,
      statusSimulador: 0,
      TELAPEDIDO: newSimulator?.TELAPEDIDO,
    });
    const newNUSIM = newSimulatorResponse.data.simulador.NUSIM;

    // criação simulador cliente
    const customerSimulatorLoop = async (idx) => {
      try {
        const data = newSimulatorCustomer[idx];
        if (!data) return;

        setModalCopyMessage(
          `Copiando simulador ${idx}/${
            Object.keys(newSimulatorCustomer)?.length
          } - ${nuSim}/${nuSimCli}`
        );

        const response = await proxyApi.get(
          `simulador-cliente/${idUsuario}/${data.NUSIMCLI}`,
          { codParc: data?.CODPARC }
        );

        const oldSimulatorCustomerData = response?.data?.simuladorCli;
        delete oldSimulatorCustomerData.NUSIM;
        delete oldSimulatorCustomerData.NUSIMCLI;

        const dataSimulator = {
          codUser: Number(base64_decode(sessionStorage.getItem(md5("CODUSU")))),
          codVend: Number(
            base64_decode(sessionStorage.getItem(md5("CODVEND")))
          ),
          codUsuInc: Number(
            base64_decode(sessionStorage.getItem(md5("CODUSU")))
          ),
          nuSim: newNUSIM,
          codParc: Number(oldSimulatorCustomerData?.CODPARC),
          codEmp: Number(oldSimulatorCustomerData?.CODEMP),
          nroReg: null, //oldSimulatorCustomerData?.NROREG,
          tipoPagDescarga: oldSimulatorCustomerData?.TIPOPAGDESCARGA,
          tipoFaturamento: null, //oldSimulatorCustomerData?.TIPOFAT,
          vlrDegustacao: Number(oldSimulatorCustomerData?.DEGUSTACAO),
          vlrDescarga: Number(oldSimulatorCustomerData?.DESCARGA),
          observacao: oldSimulatorCustomerData?.OBSERVACAO,
          ordemCompra: oldSimulatorCustomerData?.ORDEMCOMPRA,
          codTipVenda: Number(oldSimulatorCustomerData?.CODTIPNEG),
          descrTipVenda: oldSimulatorCustomerData?.DESCRTIPVENDA,
          prazoMedio: Number(oldSimulatorCustomerData?.PRAZOMEDIO),
          cargaCompartilhada: oldSimulatorCustomerData?.CARGACOMPARTILHADA,
          TIPOENTREGA: Number(oldSimulatorCustomerData?.TIPOENTREGA),
          VLR_ENTREGA: Number(oldSimulatorCustomerData?.VLR_ENTREGA),
          PER_ENTREGA: Number(oldSimulatorCustomerData?.PER_ENTREGA),
          isCopy: true,
          CODLGT:
            Number(oldSimulatorCustomerData?.CODLGT) > 0
              ? Number(oldSimulatorCustomerData?.CODLGT)
              : null,
          PERLGT:
            Number(oldSimulatorCustomerData?.PERLGT) > 0
              ? Number(oldSimulatorCustomerData?.PERLGT)
              : null,
        };

        const newSimulatorCustomerResponse = await proxyApi.post(
          `simulador-cliente/${idUsuario}`,
          dataSimulator
        );

        const newNUSIMCLI =
          newSimulatorCustomerResponse?.data?.simuladorCli?.NUSIMCLI;

        const newItens = newSimulatorCustomerResponse?.data?.simuladorIte;

        const oldSimulatorItems = await proxyApi.get(
          `simulador-item/${idUsuario}/${data.NUSIM}/${data.NUSIMCLI}/0`
        );

        const oldItens = oldSimulatorItems.data?.simuladorIte;

        const row = {
          nuSim: newNUSIM.toString(),
          nuSimCli: newNUSIMCLI.toString(),
          codVend: base64_decode(sessionStorage.getItem(md5("CODVEND"))),
          produtos: newItens?.map((product) => {
            const { QTDNEG, PRECONEG, VLRTOTAL } = oldItens.find(
              (newProduct) =>
                Number(newProduct.CODPROD) === Number(product.CODPROD)
            ) || { QTDNEG: 0, PRECONEG: 0, VLRTOTAL: 0 };

            return {
              ...product,
              CUSTOUNPRODUCAO: Number(product.CUSTOUNPRODUCAO),
              M3: Number(product.M3),
              M3TOTAL: Number(product.M3TOTAL),
              PERCRCA: Number(product.PERCRCA),
              VLRTABTRANSF: Number(product.VLRTABTRANSF),
              PERCRCAMAX: Number(product.PERCRCAMAX),
              QTDNEG: Number(QTDNEG),
              PRECONEG: Number(PRECONEG),
              VLRTOTAL: Number(VLRTOTAL),
              updated: Number(QTDNEG) > 0,
            };
          }),
        };

        if (row?.produtos?.length > 0) {
          await api.put(`simulador-item/${idUsuario}`, row);
          // await api.put(`simulador-item/${idUsuario}`, row); // código duplicado porque da primeira vez calcula errado, será solucionado quando migrar para o node
        }
        await customerSimulatorLoop(idx + 1);
      } catch (error) {
        setModalCopyMessage("");
        LogAirbrake(error, {
          url: `modalExtSimCli`,
          method: "copy",
          data: error,
        });
      }
    };
    await customerSimulatorLoop(0);
    setModalCopyMessage("");

    props.toggle();

    await props.copyRefresh();
    // await props.handlePerPage(this.state.per_page);

    setLoading(false);
  };

  return (
    <>
      {redirectToPage != null ? <Redirect to={redirectToPage} /> : null}
      <Modal
        isOpen={props.isOpen}
        toggle={() => props.toggle()}
        backdrop="static"
        size="xl"
        modalClassName="modal-black"
      >
        <ModalHeader className="justify-content-center text-center">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => props.toggle()}
          >
            <i className="tim-icons icon-simple-remove"></i>
          </button>
          <h4>
            <b>Clientes da Simulação - {props.nuSim}</b>
          </h4>
          <p className="text-center">
            Usuário Inc.: {simulador.codUsuInc + " - " + simulador.usuarioInc}
            {"ㅤ"}/{"ㅤ"}Usuário Alt.:{" "}
            {simulador.codUsuAlt + " - " + simulador.usuarioAlt}
          </p>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={4}>
              <Label for="statusSimulacao">Status da Simulação</Label>
              <Input
                type="text"
                id="statusSimulacao"
                disabled
                value={simulador.status}
              />
            </Col>
            <Col md={2}>
              <Label for="imcGeral1">%IMC da Simulação</Label>
              <Input
                type="text"
                id="imcGeral1"
                value={simulador.imcGeral}
                disabled
              />
            </Col>
          </Row>
          <Table striped>
            <thead>
              <tr>
                <th className="text-center">Cód. Emp.</th>
                <th className="text-center">Cód. Parc</th>
                <th className="text-left">Razão Social</th>
                <th className="text-left">Nome Fantasia</th>
                <th className="text-left">Cidade</th>
                <th className="text-center">%IMC</th>
                <th className="text-right"> </th>
              </tr>
            </thead>
            <tbody>
              {simuladorCli.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center">{item.CODEMP}</td>
                    <td className="text-center">{item.CODPARC}</td>
                    <td className="text-left">{item.RAZAO}</td>
                    <td className="text-left">{item.FANTASIA}</td>
                    <td className="text-left">
                      {item.UF + " - " + item.NOMECID}
                    </td>
                    <td className="text-center">
                      {item.IMC_CLI != null ? item.IMC_CLI : 0}
                    </td>
                    <td
                      className="text-right"
                      style={{ display: "flex", gap: "8px" }}
                    >
                      {!userCantInclude(userSector) && (
                        <Button
                          className="btn-icon btn-round"
                          color="primary"
                          onClick={() => askCopy(props.nuSim, item.NUSIMCLI)}
                          size="sm"
                        >
                          <i className="fa fa-copy"></i>
                        </Button>
                      )}
                      <Button
                        className="btn-icon btn-round"
                        color="primary"
                        onClick={() => handleSelectParcSim(item.NUSIMCLI)}
                        size="sm"
                      >
                        <i className="fa fa-share"></i>
                      </Button>
                      {` `}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <ModalLoading loading={loading} mensagem={modalCopyMessage} />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={show}
        toggle={() => setShow(false)}
        backdrop="static"
        size="sm"
        modalClassName="modal-black"
      >
        <ModalHeader closeButton>
          <div>Confirmação</div>
        </ModalHeader>
        <ModalBody>Confirma cópia do simulador?</ModalBody>
        <ModalFooter
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "8px",
            gap: "8px",
          }}
        >
          <Button
            color="danger"
            onClick={() => {
              setShow(false);
              setCopyData(null);
            }}
          >
            Não
          </Button>
          <Button color="success" onClick={() => handleCopy(copyData)}>
            Sim
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
