import { useGlobalContext } from "contexts/GlobalContext";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Card } from "reactstrap";
import BuscaCliente from "views/Clientes/BuscaCliente";
import ClienteSelecionado from "./ClienteSelecionado";

export default function ClientesListagem(props) {
  const { globalContext } = useGlobalContext();

  const defaultState = {
    idUsuario: globalContext.idUsuario,
    cabCodParc: null,
    cabClientes: [],
  };

  const [state, setState] = useState({ ...defaultState });

  const handleSetCabCodParc = (cabCodParc) => {
    setState((state) => ({
      ...state,
      cabCodParc: cabCodParc,
      cabClientes: [],
    }));
  };
  const handleSetCabClientes = (cabClientes) => {
    setState((state) => ({ ...state, cabClientes: cabClientes }));
  };

  return (
    <>
      <Helmet>
        <title>Clientes | Semalo</title>
      </Helmet>
      <div className="content">
        <Card style={{ padding: "1em 1em 0 1em" }}>
          <BuscaCliente
            setCabCodParc={handleSetCabCodParc}
            setCabClientes={handleSetCabClientes}
            getCabClientes={state.cabClientes}
            handleAddSimCli={() => {}}
          />
        </Card>
        {state.cabCodParc != null && state.cabClientes.length == 0 ? (
          <Card style={{ padding: "1em" }}>
            <ClienteSelecionado cabCodParc={state.cabCodParc} />
          </Card>
        ) : null}
      </div>
    </>
  );
}
