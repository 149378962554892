import { createContext, useContext, useEffect, useState } from "react";
import React from "react";
export const SimuladorContext = createContext();
import md5 from "md5";
import { decode as base64_decode } from "base-64";

export const GlobalContextProvider = ({ children }) => {
  const setorUsuario = Number(
    base64_decode(sessionStorage.getItem(md5("NUSETOR")))
  );
  const idUsuario = sessionStorage.getItem(md5("CODUSU"));

  // useEffect(() => {
  //   console.log("globalContext-->", globalContext);
  // }, [globalContext]);

  const [globalContext, setGlobalContext] = useState({
    setorUsuario,
    idUsuario,
  });

  useEffect(() => {
    if (
      globalContext?.tpFaturamento &&
      globalContext?.tpFaturamento[0]?.comLogistica == undefined
    ) {
      const newTipoFaturamento = globalContext?.tpFaturamento?.map((item) => ({
        ...item,
        comLogistica: item.OPCAO.toString()
          .toUpperCase()
          .includes("COM LOGÍSTICA"),
      }));
      setGlobalContext((state) => ({
        ...state,
        tpFaturamento: newTipoFaturamento,
      }));
    }
  }, [globalContext]);

  return (
    <SimuladorContext.Provider
      value={{
        globalContext,
        setGlobalContext,
      }}
    >
      {children}
    </SimuladorContext.Provider>
  );
};

export const useGlobalContext = () => {
  const context = useContext(SimuladorContext);

  if (!context) {
    throw new Error("useFiles must be used within FileProvider");
  }

  return context;
};
