import React, { useState, useEffect } from "react";
import { decode as base64_decode, encode as base64_encode } from "base-64";
const md5 = require("md5");
import { Helmet } from "react-helmet";
import { Card } from "reactstrap";
import SimuladorHeader from "./SimuladorHeader";
import SimuladorBody from "./SimuladorBody";
import ModalListaSimCli from "./Cliente/ModalListaSimCli";
import TabNames from "./Components/TabNames";
import { getUserIdFromSession } from "../../services/utils";
import { useSimuladorContext } from "contexts/SimuladorContext";
import { useGlobalContext } from "contexts/GlobalContext";

export default function SimuladorVenda(props) {
  const defaultState = {
    tituloPagina: "Simulação de Venda",
    idUsuario: getUserIdFromSession(sessionStorage.getItem(md5("CODUSU"))),
    activeTab: TabNames[0],
    iniciouSimulador: false,
    novaVerba: false,
    modalSimCli: false,
    cabCodParc: null, //cadastrado primeiramente na busca cliente e não pode ser modificado e nenhum outro lugar.
    cabNuSim: null, //cadastrado primeiramente no cliente e modificado no cabeçalho e cliente
    cabNuSimCli: null, //cadastrado primeiramente no cliente e modificado no cabeçalho e cliente
    cabCliente: null, //cadastrado primeiramente no cliente e modificado no cabeçalho e cliente
    cabEmpresa: null, //cadastrado primeiramente no cliente e modificado no cabeçalho e cliente
    cabOperLogistico: 0, //cadastrado primeiramente no cliente e modificado no cabeçalho e cliente
    cabTipoPagDescarga: null, //cadastrado primeiramente no cliente e modificado no cabeçalho e cliente
    cabVlrDegustacao: 0, //cadastrado primeiramente no cliente e modificado no cabeçalho e cliente
    cabVlrDescarga: 0, //cadastrado primeiramente no cliente e modificado no cabeçalho e cliente
    cabOptionOperLogistico: null, //cadastrado primeiramente no cliente e modificado no cabeçalho e cliente
    cabOptionEmpresa: null, //cadastrado primeiramente no cliente e modificado no cabeçalho e cliente
    cabOptionTipoPagDescarga: null, //cadastrado primeiramente no cliente e modificado no cabeçalho e cliente
    cabCargaCompartilhada: null, //cadastrado primeiramente no cliente e modificado no cabeçalho e cliente
    cabStatusSimulacao: 0, //cadastrado primeiramente no cliente e modificado no cabeçalho e cliente
    cabImcGeral: 0, //cadastrado primeiramente no cliente e modificado no cabeçalho e cliente
    cabVerbas: null,
    cabProdutos: null,
    cabResume: null,
    cabValidacaoProduto: null,
    cabValidacaoCliente: null,
    cabValidacaoFrete: null,
    cabValidacaoVerba: null,
    cabOptionTipoNegociacao: null,
    cabClientesBusca: null,
    cabObservacao: null,
    cabOrdemCompra: null,
    cabUsaLogistica: null,
  };
  const { setGlobalContext } = useGlobalContext();
  const { setDataSimulador } = useSimuladorContext();
  const [state, setState] = useState({ ...defaultState });

  //manipula o modal de listagem dos clientes do simulador
  const handleModalListaSimCli = (nuSim) => {
    setState({
      modalSimCli: !state.modalSimCli,
      nuSimSelecionado: nuSim,
    });
  };
  // adiciona novo cliente ao simulador
  const handleAddSimCli = () => {
    sessionStorage.removeItem(md5("SIMULADOR_NUSIMCLI"));
    handleSetCabCodParc(null);
    handleSetCabNuSimCli(null);
    handleToggleTab(TabNames[0]);
    setState({ ...defaultState });
    setGlobalContext((state) => ({ ...state, simulador: { STATUS: 0 } }));
    setDataSimulador({});
  };

  useEffect(() => {
    if (
      base64_decode(sessionStorage.getItem(md5("SIMULADOR_NUSIMCLI"))) > 0 &&
      base64_decode(sessionStorage.getItem(md5("SIMULADOR_NUSIM"))) > 0
    ) {
      setState((state) => ({
        ...state,
        iniciouSimulador: true,
        cabNuSim: base64_decode(sessionStorage.getItem(md5("SIMULADOR_NUSIM"))),
        cabNuSimCli: base64_decode(
          sessionStorage.getItem(md5("SIMULADOR_NUSIMCLI"))
        ),
      }));
    }
  }, []);

  const handleGoToProduto = () => {
    setState((state) => ({ ...state, activeTab: TabNames[1] }));
  };

  const handleToggleTab = (tab) => {
    if (state.activeTab !== tab)
      setState((state) => ({ ...state, activeTab: tab }));
  };

  const handleNovaVerba = () => {
    setState((state) => ({ ...state, novaVerba: !state.novaVerba }));
  };

  const handleSetCabCodParc = (codParc) => {
    setState((state) => ({ ...state, cabCodParc: codParc }));
  };

  const handleSetCabResume = (cabResume) => {
    setState((state) => ({ ...state, cabResume: cabResume })); //carrega apenas o código do parceir)o
  };

  const handleSetCabNuSim = (cabNuSim) => {
    cabNuSim == null
      ? sessionStorage.removeItem(md5("SIMULADOR_NUSIM"))
      : sessionStorage.setItem(md5("SIMULADOR_NUSIM"), base64_encode(cabNuSim));
    setState((state) => ({
      ...state,
      iniciouSimulador: true,
      cabNuSim: cabNuSim,
    }));
  };
  const handleSetCabNuSimCli = (cabNuSimCli) => {
    cabNuSimCli == null
      ? sessionStorage.removeItem(md5("SIMULADOR_NUSIMCLI"))
      : sessionStorage.setItem(
          md5("SIMULADOR_NUSIMCLI"),
          base64_encode(cabNuSimCli)
        );
    setState((state) => ({
      ...state,
      cabNuSimCli: cabNuSimCli,
      iniciouSimulador: true,
    }));
  };
  const handleSetCabCliente = (cliente) => {
    setState((state) => ({ ...state, cabCliente: cliente })); //carrega todos os dados do cliente da simulaçã)o
  };
  const handleSetCabEmpresa = (empresa) => {
    setState((state) => ({ ...state, cabEmpresa: empresa }));
  };
  const handleSetCabOperLogistico = (opLogistico) => {
    setState((state) => ({ ...state, cabOperLogistico: opLogistico }));
  };
  const handleSetCabTipoPagDescarga = (tipoPagDescarga) => {
    setState((state) => ({ ...state, cabTipoPagDescarga: tipoPagDescarga }));
  };

  const handleSetCabVlrDegustacao = (vlrDegustacao) => {
    setState((state) => ({ ...state, cabVlrDegustacao: vlrDegustacao }));
  };
  const handleSetCabVlrDescarga = (vlrDescarga) => {
    setState((state) => ({ ...state, cabVlrDescarga: vlrDescarga }));
  };
  const handleSetCabObservacao = (observacao) => {
    setState((state) => ({ ...state, cabObservacao: observacao }));
  };
  const handleSetCabOrdemCompra = (ordemCompra) => {
    setState((state) => ({ ...state, cabOrdemCompra: ordemCompra }));
    setDataSimulador((state) => ({ ...state, ORDEMCOMPRA: ordemCompra }));
  };
  const handleSetCabOptionEmpresa = (optionEmpresas) => {
    setState((state) => ({ ...state, cabOptionEmpresa: optionEmpresas }));
  };
  const handleSetCabOptionOperLogistico = (optionOperLogistico) => {
    setState((state) => ({
      ...state,
      cabOptionOperLogistico: optionOperLogistico,
    }));
  };

  const handleSetCabOptionTipoPagDescarga = (optionTipoPagDescarga) => {
    setState((state) => ({
      ...state,
      cabOptionTipoPagDescarga: optionTipoPagDescarga,
    }));
  };
  const handleSetCabCargaCompartilhada = (cargaCompartilhada) => {
    setState((state) => ({
      ...state,
      cabCargaCompartilhada: cargaCompartilhada,
    }));
    setDataSimulador((state) => ({
      ...state,
      CARGACOMPARTILHADA: cargaCompartilhada,
    }));
  };
  const handleSetCabStatusSimulacao = (statusSimulacao) => {
    setState((state) => ({ ...state, cabStatusSimulacao: statusSimulacao }));
  };
  const handleSetCabImcGeral = (imcGeral) => {
    setState((state) => ({ ...state, cabImcGeral: imcGeral }));
  };
  const handleSetCabVerbas = (verbas) => {
    setState((state) => ({ ...state, cabVerbas: verbas }));
  };
  const handleSetCabProduto = (produtos) => {
    setState((state) => ({ ...state, cabProdutos: produtos }));
  };
  const handleSetCabValidacaoProduto = (validacaoProduto) => {
    setState((state) => ({ ...state, cabValidacaoProduto: validacaoProduto }));
  };
  const handleSetCabValidacaoCliente = (validacaoCliente) => {
    setState((state) => ({ ...state, cabValidacaoCliente: validacaoCliente }));
  };
  const handleSetCabValidacaoFrete = (validacaoFrete) => {
    if (Array.isArray(validacaoFrete)) {
      setState((state) => ({ ...state, cabValidacaoFrete: validacaoFrete }));
    } else {
      const valida1 = validacaoFrete?.["1"];
      const valida2 = validacaoFrete?.["2"];
      const validacoes = [];
      if (valida1) {
        validacoes.push(valida1);
      }
      if (valida2) {
        validacoes.push(valida2);
      }
      setState((state) => ({ ...state, cabValidacaoFrete: validacoes }));
    }
  };
  const handleSetCabValidacaoVerba = (validacaoVerba) => {
    setState((state) => ({ ...state, cabValidacaoVerba: validacaoVerba }));
  };
  const handleSetCabClientesBusca = (clientes) => {
    setState((state) => ({ ...state, cabClientesBusca: clientes }));
  };
  const handleSetCabOptionTipoNegociacao = (optionTipoNegociacao) => {
    setState((state) => ({
      ...state,
      cabOptionTipoNegociacao: optionTipoNegociacao,
    }));
  };

  return (
    <>
      <div>
        <title>{state.tituloPagina} | Semalo</title>
      </div>
      <div className="content">
        <Card>
          <SimuladorHeader
            activeTab={state.activeTab}
            novaVerba={state.novaVerba}
            tabNames={TabNames}
            iniciouSimulador={state.iniciouSimulador}
            cabNuSim={state.cabNuSim}
            cabNuSimCli={state.cabNuSimCli}
            cabCodParc={state.cabCodParc}
            cabCargaCompartilhada={state.cabCargaCompartilhada}
            cabStatusSimulacao={state.cabStatusSimulacao}
            cabImcGeral={state.cabImcGeral}
            cabCliente={state.cabCliente}
            cabEmpresa={state.cabEmpresa}
            cabOptionEmpresa={state.cabOptionEmpresa}
            cabOperLogistico={state.cabOperLogistico}
            cabOptionOperLogistico={state.cabOptionOperLogistico}
            cabTipoPagDescarga={state.cabTipoPagDescarga}
            cabOptionTipoPagDescarga={state.cabOptionTipoPagDescarga}
            cabVlrDescarga={state.cabVlrDescarga}
            cabVlrDegustacao={state.cabVlrDegustacao}
            cabResume={state.cabResume}
            cabObservacao={state.cabObservacao}
            cabOrdemCompra={state.cabOrdemCompra}
            /////
            handleSetCabResume={handleSetCabResume}
            handleSetCabStatusSimulacao={handleSetCabStatusSimulacao}
            handleToggleTab={handleToggleTab}
            handleModalListaSimCli={handleModalListaSimCli}
            handleAddSimCli={handleAddSimCli}
            handleSetCabCargaCompartilhada={handleSetCabCargaCompartilhada}
            handleSetCabEmpresa={handleSetCabEmpresa}
            handleSetCabOperLogistico={handleSetCabOperLogistico}
            handleSetCabTipoPagDescarga={handleSetCabTipoPagDescarga}
            handleSetCabVlrDescarga={handleSetCabVlrDescarga}
            handleSetCabVlrDegustacao={handleSetCabVlrDegustacao}
            handleSetCabObservacao={handleSetCabObservacao}
            handleSetCabOrdemCompra={handleSetCabOrdemCompra}
            handleNovaVerba={handleNovaVerba}
            handleSetCabVerbas={handleSetCabVerbas}
            handleSetCabProduto={handleSetCabProduto}
            handleSetCabImcGeral={handleSetCabImcGeral}
            ////// SET VALIDATION
            handleSetCabValidacaoProduto={handleSetCabValidacaoProduto}
            handleSetCabValidacaoCliente={handleSetCabValidacaoCliente}
            handleSetCabValidacaoFrete={handleSetCabValidacaoFrete}
            handleSetCabValidacaoVerba={handleSetCabValidacaoVerba}
            //////// GET VALIDATION
            cabValidacaoProduto={state.cabValidacaoProduto}
            cabValidacaoCliente={state.cabValidacaoCliente}
            cabValidacaoFrete={state.cabValidacaoFrete}
            cabValidacaoVerba={state.cabValidacaoVerba}
            cabProdutos={state.cabProdutos}
          />

          <SimuladorBody
            activeTab={state.activeTab}
            novaVerba={state.novaVerba}
            tabNames={TabNames}
            iniciouSimulador={state.iniciouSimulador}
            cabNuSim={state.cabNuSim}
            cabNuSimCli={state.cabNuSimCli}
            cabCodParc={state.cabCodParc}
            cabCargaCompartilhada={state.cabCargaCompartilhada}
            cabStatusSimulacao={state.cabStatusSimulacao}
            cabImcGeral={state.cabImcGeral}
            cabCliente={state.cabCliente}
            cabEmpresa={state.cabEmpresa}
            cabOptionEmpresa={state.cabOptionEmpresa}
            cabOperLogistico={state.cabOperLogistico}
            cabOptionOperLogistico={state.cabOptionOperLogistico}
            cabTipoPagDescarga={state.cabTipoPagDescarga}
            cabOptionTipoPagDescarga={state.cabOptionTipoPagDescarga}
            cabVlrDescarga={state.cabVlrDescarga}
            cabVlrDegustacao={state.cabVlrDegustacao}
            cabObservacao={state.cabObservacao}
            cabOrdemCompra={state.cabOrdemCompra}
            cabVerbas={state.cabVerbas}
            cabProdutos={state.cabProdutos}
            cabResume={state.cabResume}
            cabClientesBusca={state.cabClientesBusca}
            cabOptionTipoNegociacao={state.cabOptionTipoNegociacao}
            /////SETS
            handleSetCabResume={handleSetCabResume}
            handleSetCabStatusSimulacao={handleSetCabStatusSimulacao}
            handleGoToProduto={handleGoToProduto}
            handleSetCabNuSim={handleSetCabNuSim}
            handleSetCabNuSimCli={handleSetCabNuSimCli}
            handleSetCabCliente={handleSetCabCliente}
            handleSetCabOptionEmpresa={handleSetCabOptionEmpresa}
            handleSetCabOptionOperLogistico={handleSetCabOptionOperLogistico}
            handleSetCabOptionTipoPagDescarga={
              handleSetCabOptionTipoPagDescarga
            }
            handleSetCabImcGeral={handleSetCabImcGeral}
            handleSetCabCodParc={handleSetCabCodParc}
            handleSetCabCargaCompartilhada={handleSetCabCargaCompartilhada}
            handleSetCabEmpresa={handleSetCabEmpresa}
            handleSetCabOperLogistico={handleSetCabOperLogistico}
            handleSetCabTipoPagDescarga={handleSetCabTipoPagDescarga}
            handleSetCabVlrDescarga={handleSetCabVlrDescarga}
            handleSetCabVlrDegustacao={handleSetCabVlrDegustacao}
            handleSetCabObservacao={handleSetCabObservacao}
            handleSetCabOrdemCompra={handleSetCabOrdemCompra}
            handleNovaVerba={handleNovaVerba}
            handleSetCabVerbas={handleSetCabVerbas}
            handleSetCabProduto={handleSetCabProduto}
            handleSetCabClientesBusca={handleSetCabClientesBusca}
            handleSetCabOptionTipoNegociacao={handleSetCabOptionTipoNegociacao}
            ////// SET VALIDATION
            handleSetCabValidacaoProduto={handleSetCabValidacaoProduto}
            handleSetCabValidacaoCliente={handleSetCabValidacaoCliente}
            handleSetCabValidacaoFrete={handleSetCabValidacaoFrete}
            handleSetCabValidacaoVerba={handleSetCabValidacaoVerba}
            //////// GET VALIDATION
            cabValidacaoProduto={state.cabValidacaoProduto}
            cabValidacaoCliente={state.cabValidacaoCliente}
            cabValidacaoFrete={state.cabValidacaoFrete}
            cabValidacaoVerba={state.cabValidacaoVerba}
            handleAddSimCli={handleAddSimCli}
          />
        </Card>
      </div>
      {state.modalSimCli && (
        <ModalListaSimCli
          toggle={handleModalListaSimCli}
          isOpen={state.modalSimCli}
          getCabNuSim={state.cabNuSim}
          nuSimSelecionado={state.nuSimSelecionado}
          //sets
          setCabNuSimCli={handleSetCabNuSimCli}
        />
      )}
    </>
  );
}
