import React from "react";
// reactstrap components
import { Card, CardHeader, CardBody, Collapse } from "reactstrap";

export default class CollapseValidaCliente extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openedCollapses: [],
    };
  }
  collapsesToggle(collapse) {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: [],
      });
    } else {
      this.setState({
        openedCollapses: [collapse],
      });
    }
  }
  render() {
    const {
      //////// GET VALIDATION
      cabValidacaoCliente,
    } = this.props;

    return (
      <div
        aria-multiselectable={true}
        className="card-collapse"
        id="accordion-cliente"
        role="tablist"
      >
        {cabValidacaoCliente.map((validaCliente, index) => {
          const { titulo, message, solution, value } = validaCliente;
          return (
            <Card
              key={index}
              className="card-plain"
              style={{
                marginBottom: "0px",
                borderBottom: "1px solid rgb(76 79 93)",
              }}
            >
              <CardHeader role="tab">
                <a
                  aria-expanded={this.state.openedCollapses.includes(
                    "cliente-" + index
                  )}
                  style={{ cursor: "pointer" }}
                  data-parent="#accordion-cliente"
                  data-toggle="collapse"
                  onClick={(e) => {
                    e.preventDefault();
                    this.collapsesToggle("cliente-" + index);
                  }}
                >
                  {titulo}
                  {" #"}
                  {index}
                  <i className="tim-icons icon-minimal-down" />
                </a>
              </CardHeader>
              <Collapse
                role="tabpanel"
                isOpen={this.state.openedCollapses.includes("cliente-" + index)}
              >
                <CardBody>
                  <p>
                    <b>Descrição:</b> {message}
                  </p>
                  <p>
                    <b>Solução:</b> {solution}
                  </p>
                  {value != null && value != "" ? (
                    <p>
                      <b>Valor informado:</b> {value}
                    </p>
                  ) : null}
                </CardBody>
              </Collapse>
            </Card>
          );
        })}
      </div>
    );
  }
}
