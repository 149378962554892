import React, { useState, useEffect } from "react";
import md5 from "md5";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { api } from "../services/api";
import { LogAirbrake } from "services/log.airbrake";

export default function ConfigListagem(props) {
  const [idUsuario, setIdUsuario] = useState(
    sessionStorage.getItem(md5("CODUSU"))
  );

  const [enviaEmail, setEnviaEmail] = useState(
    sessionStorage.getItem(md5("ENVIAEMAIL"))
  );

  const [nodejsapi, setNodejsapi] = useState(
    localStorage.getItem("NODEJS_API") || "NO"
  );

  useEffect(() => {
    localStorage.setItem("NODEJS_API", nodejsapi);
  }, [nodejsapi]);

  const handleBotaoConfirma = async (codUser, enviaEmail) => {
    codUser = window.atob(codUser);

    let raw = {
      codUser: codUser,
      enviaEmail: enviaEmail,
    };
    try {
      const response = await api.put(`configuracao/${idUsuario}`, raw);
      if (response.data?.statusApi === 1) {
        toast.success("Configurações Atualizadas!", { autoClose: 7000 });
        sessionStorage.setItem(md5("ENVIAEMAIL"), enviaEmail);
      } else {
        toast.warn(response.data.message, {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        });
      }
    } catch (error) {
      LogAirbrake(error, {
        url: `configuracao/${idUsuario}`,
        method: "PUT",
        data: raw,
      });

      //console.log(error);
      toast.error(
        `Não foi possivel conectar ao servidor, atualize a página e tente novamente. ${error.message}`,
        {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        }
      );
    }
  };
  const handleSendEmail = (confirma) => {
    if (confirma === "S") {
      setEnviaEmail("N");
    } else {
      setEnviaEmail("S");
    }
  };

  return (
    <>
      <Helmet>
        <title>Configuração | Semalo</title>
      </Helmet>
      <div className="content">
        <div className="card col-md-6">
          <div className="card-body">
            <form>
              <div className="form-group"></div>
              <div className="form-check">
                <label className="form-check-label">
                  <input
                    type="checkbox"
                    name="email"
                    id="EnviaEmail"
                    onClick={() => handleSendEmail(enviaEmail)}
                    defaultValue={enviaEmail}
                    defaultChecked={enviaEmail === "S"}
                  />
                  Enviar Email
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </label>
              </div>
              <div className="form-check">
                <label className="form-check-label">
                  <input
                    type="checkbox"
                    name="nodejs"
                    id="EnviaEmail"
                    onClick={() => {
                      setNodejsapi(nodejsapi === "YES" ? "NO" : "YES");
                      window.location.reload();
                    }}
                    defaultValue={nodejsapi === "YES"}
                    defaultChecked={nodejsapi === "YES"}
                  />
                  API NodeJS
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </label>
              </div>

              <button
                type="button"
                className="btn btn-primary float-right"
                onClick={() => handleBotaoConfirma(idUsuario, enviaEmail)}
              >
                Enviar
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
