export default {
    Header: " ",
    columns: [
      {
        Header:"Cod.",
        accessor: "CODPROD",
      },
      {
        Header:"Descrição",
        accessor: "DESCRPROD",
      },
      {
        Header:"Qtd.",
        accessor: "QTDNEG",
        Filter: false,
      },
      {
        Header:"R$ Neg.",
        accessor: "PRECONEG",
        Filter: false,
      },
      {
        Header:"R$ Cliente",
        accessor: "VLRTAB",
        Filter: false,
      },
      {
        Header:"R$ Total",
        accessor: "VLRTOTAL",
        Filter: false,
      },
      {
        Header:"$ Max",
        accessor: "PRECOMAX",
        Filter: false,
      },
      {
        Header:"$ Min",
        accessor: "PRECOMIN",
        Filter: false,
      },
      {
        Header:"% RCA",
        accessor: "PERCRCA",
        Filter: false,
      },
      {
        Header:"% IMC",
        accessor: "IMC_ITEM",
        Filter: false,
      }
    ]
  }