import React, { useEffect, useState } from "react";

import { Row, Col, Input, Label } from "reactstrap";
import { formatValue } from "services/globals";
import { useSimuladorContext } from "../../../contexts/SimuladorContext";
const md5 = require("md5");
import { decode as base64_decode } from "base-64";
import { getValorDescarga } from "services/utils";
import { useProductContext } from "contexts/ProdutosSimuladorContext";
import TipoFaturamento from "../Components/TipoFaturamento";
import TipoLogistica from "../Components/TipoLogistica";
import TipoEntrega from "../Components/TipoEntrega";
import { enterpriseUseDelivery } from "services/utils";

function SimuladorCabCompleto(props) {
  const setorUsuario = base64_decode(sessionStorage.getItem(md5("NUSETOR")));

  const { dataSimulador, setDataSimulador } = useSimuladorContext();

  const { dataProducts } = useProductContext();

  const handleCabVlrDegustacao = (value) => {
    setDataSimulador({ ...dataSimulador, DEGUSTACAO: value });
    setCabVlrDegustacao(value);
  };

  const handleCabVlrDescarga = (value) => {
    setDataSimulador({ ...dataSimulador, DESCARGA: value });
    setCabVlrDescarga(value);
  };

  const handleChangeEmpresa = (index) => {
    const CODEMP = index != "" ? props.getCabOptionEmpresa[index].CODEMP : "";
    const NOMEFANTASIA =
      index != "" ? props.getCabOptionEmpresa[index].NOMEFANTASIA : "";
    props.setCabEmpresa({
      codEmp: NOMEFANTASIA,
      nomeFantasia: NOMEFANTASIA,
    });

    let regraEntrega;

    if (enterpriseUseDelivery(CODEMP)) {
      regraEntrega = { PERLOGISTICA: null, VLRFRETELOG: null };
    } else {
      regraEntrega = { VLR_ENTREGA: null, PER_ENTREGA: null };
    }

    setDataSimulador({
      ...dataSimulador,
      ...regraEntrega,
      CODEMP,
      comLogistita: false,
      NOMEFANTASIA,
    });
  };

  const handleChangeTipoPagDescarga = (index) => {
    let TIPOPAGDESCARGA =
      index != "" ? props.getCabOptionTipoPagDescarga[index].VALOR : "";
    props.setCabTipoPagDescarga(TIPOPAGDESCARGA);

    setDataSimulador({ ...dataSimulador, TIPOPAGDESCARGA });
  };

  const {
    setCabVlrDegustacao,
    setCabVlrDescarga,
    //gets
    getCabOptionEmpresa,
    getCabEmpresa,
    getCabImcGeral,
    getCabCliente,
    getCabVlrDegustacao,
    getCabOptionTipoPagDescarga,
    getCabTipoPagDescarga,
    getCabVlrDescarga,
    getCabStatusSimulacao,
  } = props;

  useEffect(() => {
    let QTD_PALET = 0;
    if (getCabCliente?.AD_DESPALET == "S") {
      QTD_PALET = dataProducts.reduce((acc, current) => {
        return acc + Math.round(current?.QTDNEG / current?.QTDEPALET, -1);
      }, 0);
    }

    let QTD_NEG = 0;
    QTD_NEG = dataProducts.reduce((acc, current) => {
      return acc + Number(current?.QTDNEG);
    }, 0);

    if (QTD_NEG > 0) {
      const valorDescarga = getValorDescarga({
        ...getCabCliente,
        QTD_NEG,
        QTD_PALET,
      });

      // if (getCabVlrDescarga != valorDescarga) {
      //   // setCabVlrDescarga(valorDescarga); // comentado porque os dados nao estao mais cadastrados, tem que ver direito essa rotina, talvez nem tenha que ter isso
      //   setDataSimulador((state) => ({
      //     ...state,
      //     DESCARGA: valorDescarga,
      //   }));
      // }
    }

    // setDescargaEnable(false);
    // comentado porque ainda não foram feitos os cadastros
    // setDescargaEnable(
    //   (getCabCliente?.AD_DESPALET != "S" &&
    //     getCabCliente?.AD_DESVOLUME != "S" &&
    //     getCabCliente?.AD_DESFECHADA != "S") ||
    //     getCabCliente?.USALOGISTICA
    // );
  }, [props]);

  return (
    <>
      <Col style={{ padding: "5px 5px" }}>
        <Row>
          <Col md={6}>
            <Label for="cliente">Cod / Razão Social</Label>
            <Input
              id="cliente"
              value={`${getCabCliente?.CODPARC} - ${getCabCliente?.RAZAO}`}
              disabled
            />
          </Col>
          <Col md={6}>
            <Label for="nome_fantasia">Nome Fantasia</Label>
            <Input
              id="nome_fantasia"
              value={`${getCabCliente?.FANTASIA}`}
              disabled
            />
          </Col>
          <Col md={3}>
            <Label for="inputEmpresaFaturante">Empresa Faturante</Label>
            <Input
              type="select"
              id="inputEmpresaFaturante"
              name="inputEmpresaFaturante"
              onChange={(e) => handleChangeEmpresa(e.target.value)}
              style={{ cursor: "pointer" }}
              disabled={getCabStatusSimulacao != 0 || setorUsuario == "1"}
              required
            >
              <option value="">{"< Selecione uma empresa >"}</option>
              {getCabOptionEmpresa?.map((emp, index) => {
                return (
                  <option
                    key={index}
                    value={index}
                    selected={
                      getCabEmpresa?.codEmp == emp.CODEMP ? true : false
                    }
                  >
                    {emp.CODEMP + " - " + emp.NOMEFANTASIA}
                  </option>
                );
              })}
            </Input>
            <Label for="vlrDegustacao">R$ Degustação</Label>
            <Input
              type="number"
              id="vlrDegustacao"
              name="vlrDegustacao"
              value={getCabVlrDegustacao}
              min={0}
              onChange={(e) => handleCabVlrDegustacao(e.target.value)}
              disabled={getCabStatusSimulacao != 0 || setorUsuario == "1"}
            />
          </Col>
          {!enterpriseUseDelivery(dataSimulador.CODEMP) && (
            <Col md={3}>
              <TipoFaturamento />
              <TipoLogistica />
            </Col>
          )}
          {enterpriseUseDelivery(dataSimulador.CODEMP) && (
            <Col md={3}>
              <TipoEntrega />
            </Col>
          )}
          <Col md={3}>
            <Label for="tipoPagDescarga">Tipo Descarga</Label>
            <Input
              type="select"
              id="tipoPagDescarga"
              name="tipoPagDescarga"
              onChange={(e) => handleChangeTipoPagDescarga(e.target.value)}
              style={{ cursor: "pointer" }}
              disabled={getCabStatusSimulacao != 0 || setorUsuario == "1"}
              required
            >
              <option value="">{"< Selecione Tipo de Descarga >"}</option>
              {getCabOptionTipoPagDescarga?.map((descarga, index) => {
                return (
                  <option
                    key={index}
                    value={index}
                    selected={getCabTipoPagDescarga == descarga.VALOR}
                  >
                    {descarga.OPCAO}
                  </option>
                );
              })}
            </Input>
            <Label for="vlrDescarga">R$ Descarga</Label>
            <Input
              type="number"
              id="vlrDescarga"
              name="vlrDescarga"
              value={getCabVlrDescarga}
              min={0}
              disabled={
                getCabStatusSimulacao != 0 || setorUsuario == "1"
                // &&                descargaEnable
              }
              onChange={(e) => handleCabVlrDescarga(e.target.value)}
            />
          </Col>
          <Col md={3}>
            <Label for="imcGeral">%IMC Geral</Label>
            <Input
              id="imcGeral"
              value={formatValue("decimal", dataSimulador?.IMC_CLI)}
              disabled
            />
            {/* <Button id="salvar">Atualizar Cliente</Button> */}
          </Col>
        </Row>
      </Col>
    </>
  );
}

export default SimuladorCabCompleto;
