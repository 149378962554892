import React from "react";
// alteração no título da página no navegador
import { Helmet } from "react-helmet";
// reactstrap components
import { Row, Col } from "reactstrap";

import { CardRelatorio } from "./Components/CardRelatorio";

function Relatorios() {
  return (
    <>
      <Helmet>
        <title>Relatórios | Semalo</title>
      </Helmet>
      <div className="content">
        <Row>
          <Col md={4}>
            <CardRelatorio
              title="Clientes Inadimplentes"
              subtitle="Listagem"
              description="Apresenta os clientes com pagamentos em aberto até data atual."
              redirect="relatorios/clientes-inadimplentes"
            />
          </Col>
          <Col md={4}>
            <CardRelatorio
              title="Comissões Provisionadas"
              subtitle="Listagem"
              description="Apresenta o provisionamento das comissões."
              redirect="relatorios/comissoes-provisionadas"
              //VGFCOM_PROV_SEMALO
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Relatorios;
