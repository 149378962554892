import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { api } from "services/api";
import { logoBase64 } from "assets/img/logo_base64";
import { formatValue } from "services/globals";
// lidar com criptografias Base64
import { decode as base64_decode } from "base-64";
// lidar com criptografias MD5
const md5 = require("md5");
const moment = require("moment");

async function loadToPrint(cabNuSim, cabNuSimCli) {
  try {
    const response = await api.get(
      `simulador/print-folha-rosto/${sessionStorage.getItem(
        md5("CODUSU")
      )}/${cabNuSim}`
    );
    //console.log(response.data)
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
}

// simulacao.VLR_FATURAMENTO, cliente.VLR_FATURAMENTO, simulacao.VLRFRETE, simulacao.M3_CLI, simulacao.MAIORM3, simulacao.MAIORVLRFRETECID
function calculaFreteComplemento(
  totalFat,
  fat,
  totalFrete,
  totalM3,
  maiorFreteCid,
  maiorValorFreteCid,
  tipoFat
) {
  return 0; // removido em 24/11/22 solicitado para nao mostrar mais o valor do complemento

  // console.log(tipoFat);
  // if (Number(tipoFat) === 1 || Number(tipoFat) === 5 || Number(tipoFat) === 6)
  //   return 0;
  // let m3;
  // let tamanhoCaminhao = 80;
  // if (tamanhoCaminhao < totalM3) {
  //   m3 = totalM3;
  // } else {
  //   m3 = tamanhoCaminhao;
  // }
  // let maiorFrete = m3 * maiorValorFreteCid;
  // let vlrDividir = maiorFrete - totalFrete;
  // let porcentagem = fat / totalFat;
  // return vlrDividir * porcentagem;
}

export async function SimuladorPDFFolhaDeRosto(cabNuSim) {
  const response = await loadToPrint(cabNuSim);

  const { simulador, simulacao, simIte } = response.data;

  // console.log(simulacao, simulador);
  //console.log(simulador.CODPARC)

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const header = {
    columns: [
      {
        stack: [
          {
            image: "logo",
            width: 70,
            margin: [10, 10, 20, 0],
          },
        ],
        width: 100,
      },
      {
        stack: [
          { text: `SEMALO IND E COMERCIO DE ALIMENTOS LTDA`, bold: true },
          { text: `CNPJ: 36.804.268/001-23  I.E.: 282.702.326` },
          { text: `Av. Guaicurus, Nro. 2348  Bairro: JD Itamaracá` },
          { text: `Campo Grande - MS  CEP 79062-146` },
          { text: `Tel: (67) 3348-4700` },
          {
            text: [
              { text: `Usuário: `, bold: true },
              {
                text: `${base64_decode(
                  sessionStorage.getItem(md5("NOMEUSU"))
                )} - ${base64_decode(sessionStorage.getItem(md5("EMAIL")))}`,
              },
            ],
          },
        ],
        width: 530,
      },
      {
        stack: [
          { text: "Simulador de Venda.", bold: true },
          {
            text: `Nro. Simulador: ${cabNuSim}`,
            fontSize: 10,
            margin: [0, 5, 0, 0],
          },
          {
            text: `Lançamento: ${moment(simulacao.DHINC).format(
              "DD/MM/YYYY HH:mm:ss"
            )}\nAlteração: ${moment(simulacao.DHALTER).format(
              "DD/MM/YYYY HH:mm:ss"
            )}`,
            fontSize: 8,
            margin: [0, 2, 0, 0],
          },
        ],
        width: 200,
        fontSize: 12,
        alignment: "center",
      },
    ],
  };

  const totalComplemento = () => {
    let total = 0;
    simulador.forEach((item) => {
      total =
        total +
        calculaFreteComplemento(
          simulacao.VLR_FATURAMENTO,
          item.VLR_FATURAMENTO,
          simulacao.VLRFRETE,
          simulacao.M3_CLI,
          simulacao.MAIORM3,
          simulacao.MAIORVLRFRETECID,
          item.TIPOFAT
        );
    });
    return total;
  };

  const itens2 = simIte.map((item) => {
    return [
      item?.DESCRPROD,
      Number(item?.QTDNEG),
      `${formatValue("decimal", item?.M3TOTAL)}`,
      `R$ ${formatValue("decimal", item?.PRECONEG)}`,
      `R$ ${formatValue("decimal", item.VLRTOTAL)}`,
      Number(item?.IMC_ITEM).toFixed(2),
      Number(item?.PERCRCA).toFixed(2),
    ];
  });

  const itens2TotalQuantity = simIte.reduce(
    (soma, item) => soma + (Number(item?.QTDNEG) || 0),
    0
  );

  const clientes = simulador.map((cliente) => {
    return [
      `${cliente.CODPARC} - ${cliente.NOMEPARC}`,
      `${cliente.NOMECID} - ${cliente.UF}`,
      "R$ " + `${formatValue("decimal", cliente.VLR_FATURAMENTO)}`,
      `${formatValue("decimal", cliente.M3_CLI)}`,
      // "R$ " + `${formatValue("decimal", cliente.VLRFRETECID)}`,
      "R$ " + `${formatValue("decimal", cliente.VLRFRETE)}`,
      "R$ " +
        `${formatValue(
          "decimal",
          calculaFreteComplemento(
            simulacao.VLR_FATURAMENTO,
            cliente.VLR_FATURAMENTO,
            simulacao.VLRFRETE,
            simulacao.M3_CLI,
            simulacao.MAIORM3,
            simulacao.MAIORVLRFRETECID,
            cliente.TIPOFAT
          ).toFixed(2)
        )}`,
      "R$ " + `${formatValue("decimal", cliente.VLRFRETELOG)}`,
      "R$ " +
        `${formatValue(
          "decimal",
          (
            parseFloat(cliente.VLRFRETETOTAL) +
            calculaFreteComplemento(
              simulacao.VLR_FATURAMENTO,
              cliente.VLR_FATURAMENTO,
              simulacao.VLRFRETE,
              simulacao.M3_CLI,
              simulacao.MAIORM3,
              simulacao.MAIORVLRFRETECID,
              cliente.TIPOFAT
            )
          ).toFixed(2)
        )}`,
      `${cliente.IMC_CLI}`,
    ];
  });

  const linhaClientes = [
    {
      margin: [0, 10, 0, 0],
      table: {
        headerRows: 1,
        widths: ["*", 110, 50, 33, 50, 55, 45, 55, 30],
        alignment: "center",
        // dontBreakRows: true,
        // keepWithHeaderRows: 1,
        body: [
          [
            { text: "Cliente", bold: true, fillColor: "#dddddd" },
            { text: "Cidade-UF", bold: true, fillColor: "#dddddd" },
            { text: "Faturamento", bold: true, fillColor: "#dddddd" },
            { text: "M³", bold: true, fillColor: "#dddddd" },
            // { text: "Custo M³", bold: true, fillColor: "#dddddd" },
            { text: "Frete.", bold: true, fillColor: "#dddddd" },
            { text: "Complemento", bold: true, fillColor: "#dddddd" },
            { text: "Logística", bold: true, fillColor: "#dddddd" },
            { text: "Total Frete", bold: true, fillColor: "#dddddd" },
            { text: "% IMC", bold: true, fillColor: "#dddddd" },
          ],
          ...clientes,
          [
            { text: "", border: [false] },
            { text: "Totais", fillColor: "#dddddd" },
            {
              text:
                "R$ " + `${formatValue("decimal", simulacao.VLR_FATURAMENTO)}`,
            },
            { text: `${formatValue("decimal", simulacao.M3_CLI)}` },
            { text: "", border: [false] },
            // { text: "R$ " + `${formatValue("decimal", simulacao.VLRFRETE)}` },
            {
              text:
                "R$ " +
                `${formatValue("decimal", totalComplemento().toFixed(2))}`,
            },
            {
              text: "R$ " + `${formatValue("decimal", simulacao.VLRFRETELOG)}`,
            },
            {
              text:
                "R$ " +
                `${formatValue(
                  "decimal",
                  (simulacao.M3_CLI * simulacao.MAIORVLRFRETECID).toFixed(2)
                )}`,
            },
            {
              text: `${formatValue("decimal", simulacao.MEDIAIMC)} Méd`,
              bold: true,
              fillColor: "#dddddd",
              fontSize: 12,
            },
          ],
        ],
      },
    },
  ];
  const linhaItens = [
    {
      margin: [0, 10, 0, 0],
      table: {
        headerRows: 1,
        widths: ["*", 70, 70, 70, 70, 70, 70],
        alignment: "center",
        // dontBreakRows: true,
        // keepWithHeaderRows: 1,
        body: [
          [
            { text: "Descrição do Produto", bold: true, fillColor: "#dddddd" },
            { text: "Quantidade", bold: true, fillColor: "#dddddd" },
            { text: "Total M³", bold: true, fillColor: "#dddddd" },
            { text: "R$ Neg", bold: true, fillColor: "#dddddd" },
            { text: "R$ Faturado", bold: true, fillColor: "#dddddd" },
            { text: "% IMC", bold: true, fillColor: "#dddddd" },
            { text: "% RCA", bold: true, fillColor: "#dddddd" },
          ],
          ...itens2,
          [
            { text: "", bold: true, border: [false] },
            { text: itens2TotalQuantity.toLocaleString("pt-BR"), bold: true },
            { text: "", bold: true },
            { text: "", bold: true },
            { text: "", bold: true },
            { text: "", bold: true },
            { text: "", bold: true },
          ],
        ],
      },
    },
  ];

  const details = [header, linhaClientes, linhaItens];

  const docDefinitions = {
    pageSize: "A4", //A4 (595.28 x 841.89)
    pageOrientation: "landscape",
    pageMargins: [25, 20, 25, 14], //left, top, right, bottom
    defaultStyle: {
      fontSize: 8,
      bold: false,
    },
    content: details,
    images: {
      logo: logoBase64,
    },
    footer: function (currentPage, pageCount) {
      return {
        columns: [
          {
            text: `Página ${currentPage.toString()} de ${pageCount}  -  Simulador: ${cabNuSim}`,
            alignment: "center",
          },
        ],
        margin: [25, 0], // Margem para centralizar o rodapé horizontalmente
      };
    },
  };

  pdfMake.createPdf(docDefinitions).open(); //download()
}
