export const hasLogistics = (tipoFat) => {
  return tipoFat == 1 || tipoFat == 5;
};

export const filterLogistica = (
  item,
  codEmpresa,
  codTipoLogistica,
  comLogistica
) => {
  switch (Number(codEmpresa)) {
    case 2:
      if (
        Number(codTipoLogistica) === 1 ||
        (Number(codTipoLogistica) === 2 && !comLogistica)
      ) {
        return item;
      }
      break;
    case 6:
      if (
        (Number(codTipoLogistica) === 3 && !comLogistica) ||
        (Number(codTipoLogistica) === 4 && !comLogistica) ||
        (Number(codTipoLogistica) === 7 && !comLogistica) ||
        Number(codTipoLogistica) === 5
      ) {
        return item;
      }
      break;
    case 10:
      if (
        (Number(codTipoLogistica) === 4 && !comLogistica) ||
        Number(codTipoLogistica) === 5
      ) {
        return item;
      }
      break;
    default:
      if (
        Number(codTipoLogistica) === 1 ||
        (Number(codTipoLogistica) === 2 && !comLogistica)
      ) {
        return item;
      }
      break;
  }
};
