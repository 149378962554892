import React from "react";
import { NavLink, Link } from "react-router-dom";

// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import { Nav } from "reactstrap";

// Ícones
import {
  BsHouseDoorFill,
  BsFillPersonLinesFill,
  BsLightningFill,
} from "react-icons/bs";
import { IoBarChartSharp, IoLockClosedOutline } from "react-icons/io5";

import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import { decode as base64_decode } from "base-64";
import md5 from "md5";

let ps;

function Sidebar(props) {
  const codUsu = parseInt(base64_decode(sessionStorage.getItem(md5("CODUSU"))));
  const sidebarRef = React.useRef(null);
  const [collapse, setCollapse] = React.useState(false);

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebarRef.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });

  let mostraBotaoPendentes;
  if (codUsu === 50 || codUsu === 1346) {
    mostraBotaoPendentes = (
      <>
        <NavLink
          to="/admin/pendentes"
          className="nav-link"
          onClick={props.toggleSidebar}
        >
          <p>
            <IoLockClosedOutline size="1.2em" />
            {"ㅤ"}Simulações Pendentes
          </p>
        </NavLink>
      </>
    );
  }

  const { logo } = props;
  let logoImg = null;
  let logoText = null;
  if (logo !== undefined) {
    if (logo.outterLink !== undefined) {
      logoImg = (
        <a
          href={logo.outterLink}
          className="simple-text logo-mini"
          target="_blank"
          onClick={props.toggleSidebar}
          rel="noreferrer"
        >
          <div className="logo-img">
            <img src={logo.imgSrc} alt="semalo-logo" />
          </div>
        </a>
      );
      logoText = (
        <a
          href={logo.outterLink}
          className="simple-text logo-normal"
          target="_blank"
          onClick={props.toggleSidebar}
          rel="noreferrer"
        >
          {logo.text}
        </a>
      );
    } else {
      logoImg = (
        <Link
          to={logo.innerLink}
          className="simple-text logo-mini"
          onClick={props.toggleSidebar}
        >
          <div className="logo-img">
            <img src={logo.imgSrc} alt="semalo-logo" />
          </div>
        </Link>
      );
      logoText = (
        <Link
          to={logo.innerLink}
          className="simple-text logo-normal"
          onClick={props.toggleSidebar}
        >
          {logo.text}
        </Link>
      );
    }
  }

  function toggleCollapseTeste(event) {
    event.preventDefault();
    setCollapse(!collapse);
  }

  return (
    <BackgroundColorContext.Consumer>
      {({ color }) => (
        <div className="sidebar" data={color}>
          <div className="sidebar-wrapper" ref={sidebarRef}>
            <div className="logo">
              <center>
                <img src={logo.imgSrc} alt="semalo-logo" width="90px" />
              </center>
            </div>
            <Nav>
              <NavLink
                to="/admin/home"
                className="nav-link"
                onClick={props.toggleSidebar}
              >
                <p>
                  <BsHouseDoorFill size="1.2em" />
                  {"ㅤ"}Home
                </p>
              </NavLink>
              <NavLink
                to="/admin/simulador"
                className="nav-link"
                onClick={props.toggleSidebar}
              >
                <p>
                  <BsLightningFill size="1.2em" />
                  {"ㅤ"}Simulador de Vendas
                </p>
              </NavLink>
              <NavLink
                to="/admin/clientes"
                className="nav-link"
                onClick={props.toggleSidebar}
              >
                <p>
                  <BsFillPersonLinesFill size="1.2em" />
                  {"ㅤ"}Clientes
                </p>
              </NavLink>

              <NavLink
                to="/admin/consultas"
                className="nav-link"
                onClick={props.toggleSidebar}
              >
                <p>
                  <IoBarChartSharp size="1.2em" />
                  {"ㅤ"}Consultas
                </p>
              </NavLink>
              {mostraBotaoPendentes}
              {/* <NavLinkReactStrap
                className="nav-link"
                onClick={(e)=>toggleCollapseTeste(e)}
                style={{cursor:"pointer"}}
              >
                <p><CgPlayListSearch size='1.7em'/>{'ㅤ'}Abre collapse</p>
              </NavLinkReactStrap>
              <Collapse
                isOpen={collapse}
              >
                <NavLinkReactStrap
                  className="nav-link"
                  onClick={()=>linkOnClick()}
                >
                  <p><FaSearch size='1.2em'/>{'ㅤ'}Collapse</p>
                </NavLinkReactStrap>
                <DropdownItem divider tag="li" />
              </Collapse> */}
            </Nav>
          </div>
        </div>
      )}
    </BackgroundColorContext.Consumer>
  );
}

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // if true, then instead of the routes[i].name, routes[i].rtlName will be rendered
  // insde the links of this component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the text of the logo
    text: PropTypes.node,
    // the image src of the logo
    imgSrc: PropTypes.string,
  }),
};

export default Sidebar;
