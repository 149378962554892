import { Notifier } from "@airbrake/browser";

export const LogAirbrake = (error, info = {}) => {
  try {
    let detail;
    try {
      detail = error.response;
    } catch (error) {
      console.log(error);
    }

    let airbrake = new Notifier({
      projectId: 478012,
      projectKey: "8c441861a784f2c2fe89c4448d2576e7",
    });

    airbrake.notify({ error, params: { detail, info } });

    airbrake = null;
  } catch (error) {
    console.log(error);
  }
};
