import React from "react";
import { NavLink as RRNavLink } from "react-router-dom";

// nodejs library that concatenates classes
import classNames from "classnames";
// lidar com criptografias MD5
const md5 = require("md5");
// lidar com criptografias Base64
import { decode as base64_decode } from "base-64";

// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  NavbarText,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  NavbarToggler,
  ModalHeader,
} from "reactstrap";
import { ThemeContext, themes } from "contexts/ThemeContext";
// Ícones
import { FiLogOut } from "react-icons/fi";
import { GiPalette } from "react-icons/gi";
import { IoMdColorPalette } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
function AdminNavbar(props) {
  const [collapseOpen, setcollapseOpen] = React.useState(false);
  const [modalSearch, setmodalSearch] = React.useState(false);
  const [color, setcolor] = React.useState("navbar-transparent");
  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });

  function logOutSystem(e) {
    e.preventDefault();
    sessionStorage.clear();
    window.location = "/";
  }

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 1024 && collapseOpen) {
      setcolor("bg-white");
    } else {
      setcolor("navbar-transparent");
    }
  };
  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setcolor("navbar-transparent");
    } else {
      setcolor("bg-white");
    }
    setcollapseOpen(!collapseOpen);
  };
  // this function is to open the Search modal
  const toggleModalSearch = () => {
    setmodalSearch(!modalSearch);
  };
  return (
    <>
      <Navbar
        className={classNames("navbar-absolute", color)}
        expand="lg"
        style={{ height: "40px" }}
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div
              className={classNames("navbar-toggle d-inline", {
                toggled: props.sidebarOpened,
              })}
            >
              <NavbarToggler onClick={props.toggleSidebar}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </NavbarToggler>
            </div>

            {/* <NavbarBrand href="#" onClick={(e) => e.preventDefault()}> */}

            <NavbarBrand>{props.brandText}</NavbarBrand>
          </div>
          <NavbarToggler onClick={toggleCollapse}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              {/* <InputGroup className="search-bar">
                <Button color="link" onClick={toggleModalSearch}>
                  <i className="tim-icons icon-zoom-split" />
                  <span className="d-lg-none d-md-block">Busca</span>
                </Button>
              </InputGroup> */}
              {base64_decode(sessionStorage.getItem(md5("BASETESTE"))) ==
              "TESTE" ? (
                <NavbarText
                  className="text-navbar"
                  style={{ marginTop: ".3em" }}
                >
                  {`Você está na base Teste ㅤ||ㅤ
                  ${
                    localStorage.getItem("NODEJS_API") === "YES"
                      ? "NodeJS"
                      : "PHP"
                  } ㅤ||ㅤ`}
                </NavbarText>
              ) : null}
              <NavbarText className="text-navbar" style={{ marginTop: ".3em" }}>
                {base64_decode(sessionStorage.getItem(md5("NOMEUSU")))}
              </NavbarText>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <div className="photo">
                    <img
                      alt="..."
                      src={require("assets/img/default-avatar.png").default}
                    />
                  </div>
                  <b className="caret d-none d-lg-block d-xl-block" />
                  <p className="d-lg-none">Menu</p>
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">Perfil</DropdownItem>
                  </NavLink>
                  <NavLink
                    tag={RRNavLink}
                    exact
                    to="/admin/configuracao"
                    className="nav-link"
                  >
                    <DropdownItem className="nav-item">
                      <IoSettingsOutline size="1.7em" />
                      {"ㅤ"}Configurações
                    </DropdownItem>
                  </NavLink>
                  <DropdownItem divider tag="li" />
                  <ThemeContext.Consumer>
                    {({ changeTheme }) => (
                      <NavLink tag="li">
                        <DropdownItem
                          className="nav-item"
                          onClick={() => changeTheme(themes.light)}
                        >
                          <GiPalette size="1.7em" />
                          {"ㅤ"}Tema Claro
                        </DropdownItem>
                        <DropdownItem
                          className="nav-item"
                          onClick={() => changeTheme(themes.dark)}
                        >
                          <IoMdColorPalette size="1.7em" />
                          {"ㅤ"}Tema Escuro
                        </DropdownItem>
                      </NavLink>
                    )}
                  </ThemeContext.Consumer>
                  <DropdownItem divider tag="li" />
                  <NavLink tag="li" onClick={(e) => logOutSystem(e)}>
                    <DropdownItem className="nav-item">
                      <FiLogOut size="1.5em" />
                      {"ㅤ"}Sair do Sistema
                    </DropdownItem>
                  </NavLink>

                  {/* <ThemeContext.Consumer>
                    {({ changeTheme }) => (
                      <>
                        <span className="color-label">MODO CLARO</span>{" "}
                        <Badge
                          className="light-badge mr-2"
                          onClick={() => changeTheme(themes.light)}
                        />{" "}
                        <Badge
                          className="dark-badge ml-2"
                          onClick={() => changeTheme(themes.dark)}
                        />{" "}
                        <span className="color-label">MODO ESCURO</span>{" "}
                      </>
                    )}
                  </ThemeContext.Consumer> */}
                </DropdownMenu>
              </UncontrolledDropdown>
              <li className="separator d-lg-none" />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <Modal
        modalClassName="modal-search"
        isOpen={modalSearch}
        toggle={toggleModalSearch}
      >
        <ModalHeader>
          <Input placeholder="BUSCA" type="text" />
          <button
            aria-label="Close"
            className="close"
            onClick={toggleModalSearch}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
        </ModalHeader>
      </Modal>
    </>
  );
}

export default AdminNavbar;
