/* eslint-disable no-undef */
import React from "react";
// lida com as requisições
import { api } from "services/api";
// lidar com criptografias MD5
const md5 = require("md5");
// reactstrap components
import { Col, Label, Input, Row } from "reactstrap";
import moment from "moment";
import { proxyApi } from "services/proxyApi";

export class SimuladorResumo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idUsuario: sessionStorage.getItem(md5("CODUSU")),
    };
  }

  async componentDidMount() {
    const { idUsuario } = this.state;
    const { cabNuSimCli, handleSetCabResume } = this.props;
    try {
      const response = await proxyApi.get(
        `simulador-resumo/${idUsuario}/${cabNuSimCli}`
      );
      if (response.data?.statusApi == 1) {
        const { resume } = response.data;
        handleSetCabResume(resume);
      } else {
        //console.log("Resumo Error", response.data);
      }
    } catch (error) {
      //console.log(error);
    }
  }

  formatValue(style, value) {
    return Intl.NumberFormat("pt-BR", { style: style, currency: "BRL" }).format(
      value
    );
  }

  render() {
    const { cabResume } = this.props;

    return (
      <>
        {cabResume != null ? (
          <Col style={{ padding: "15px" }}>
            <Row>
              <Col md={3}>
                <Label for={`simulacao-resumo-numero-pedido`}>Nº Pedido</Label>
                <Input
                  disabled
                  value={this.formatValue("decimal", cabResume.NUNOTA ?? null)}
                  id={`simulacao-resumo-numero-pedido`}
                />
              </Col>
            </Row>
            <br />
            <h4>Representante</h4>
            <Row>
              <Col md={3}>
                <Label for={`resumo-representante`}>Representante</Label>
                <Input
                  disabled
                  value={cabResume.REPRESENTANTE ?? null}
                  id={`resumo-representante`}
                />
              </Col>
              <Col md={3}>
                <Label for={`simulacao-resumo-supervisor`}>Supervisor</Label>
                <Input
                  disabled
                  value={cabResume.SUPERVISOR ?? null}
                  id={`simulacao-resumo-supervisor`}
                />
              </Col>
              <Col md={3}>
                <Label for={`simulacao-resumo-valor-faturado`}>
                  Valor Faturado
                </Label>
                <Input
                  value={this.formatValue(
                    "currency",
                    cabResume.VLR_FATURAMENTO ?? null
                  )}
                  disabled
                  id={`simulacao-resumo-valor-faturado`}
                />
              </Col>
              <Col md={3}>
                <Label for={`simulacao-resumo-comissao`}>Comissão R$</Label>
                <Input
                  value={this.formatValue(
                    "currency",
                    cabResume.VLRCOMISSAORCA ?? null
                  )}
                  disabled
                  id={`simulacao-resumo-comissao`}
                />
              </Col>
            </Row>
            <br />
            <h4>Frete / Logística</h4>
            <Row>
              <Col md={4}>
                <Label for={`simulacao-resumo-cidade-tipo-frete`}>
                  Tipo Frete
                </Label>
                <Input
                  disabled
                  value={cabResume.TIPOFRETE ?? null}
                  id={`simulacao-resumo-cidade-tipo-frete`}
                />
              </Col>
              <Col md={4}>
                <Label for={`simulacao-resumo-cidade-cliente`}>
                  Cidade Cliente
                </Label>
                <Input
                  disabled
                  value={
                    (cabResume.UF ?? "") + " - " + (cabResume.NOMECID ?? "")
                  }
                  id={`simulacao-resumo-cidade-cliente`}
                />
              </Col>
              <Col md={4}>
                <Label for={`simulacao-resumo-cidade-logistica`}>
                  Cidade Logística
                </Label>
                <Input
                  disabled
                  value={cabResume.NOMECIDLOG}
                  id={`simulacao-resumo-cidade-logistica`}
                />
              </Col>
              <Col md={4}>
                <Label for={`simulacao-resumo-fretem3-cte2`}>
                  R$ Frete M³ - CTE
                </Label>
                <Input
                  disabled
                  value={this.formatValue(
                    "currency",
                    cabResume.VLRFRETE ?? null
                  )}
                  id={`simulacao-resumo-fretem3-cte2`}
                />
              </Col>
              <Col md={4}>
                <Label for={`simulacao-resumo-fretem3-cte`}>
                  R$ Frete M³ + CTE
                </Label>
                <Input
                  disabled
                  value={this.formatValue(
                    "currency",
                    cabResume.VLRFRETECTE ?? null
                  )}
                  id={`simulacao-resumo-fretem3-cte`}
                />
              </Col>
              <Col md={4}>
                <Label for={`simulacao-resumo-m3total`}>M3 Total</Label>
                <Input
                  disabled
                  value={this.formatValue("decimal", cabResume.M3_CLI ?? null)}
                  id={`simulacao-resumo-m3total`}
                />
              </Col>
            </Row>
            <br />
            <h4>Cliente</h4>
            <Row>
              <Col md={4}>
                <Label for={`simulacao-resumo-perfil`}>Perfil</Label>
                <Input disabled value={null} id={`simulacao-resumo-perfil`} />
              </Col>
              <Col md={4}>
                <Label for={`simulacao-resumo-prazo-medio`}>
                  Prazo Médio (dias)
                </Label>
                <Input
                  disabled
                  value={cabResume.PRAZOMEDIO ?? null}
                  id={`simulacao-resumo-prazo-medio`}
                />
              </Col>
              <Col md={4}>
                <Label for={`simulacao-resumo-tabela-produtos`}>
                  Tabela de Produtos
                </Label>
                <Input
                  disabled
                  value={this.formatValue("decimal", cabResume.NUTAB ?? null)}
                  id={`simulacao-resumo-tabela-produtos`}
                />
              </Col>
            </Row>
            <br />
            <h4>Registros</h4>
            <Row>
              <Col md={3}>
                <Label for={`simulacao-resumo-data-lancamento`}>
                  Dt. Lançamento
                </Label>
                <Input
                  value={moment(cabResume.DHINC).format("DD/MM/YYYY")}
                  disabled
                  id={`simulacao-resumo-data-lancamento`}
                />
              </Col>
              <Col md={3}>
                <Label for={`simulacao-resumo-data-alteracao`}>
                  Dt. Alteração
                </Label>
                <Input
                  value={moment(cabResume.DHALTER).format("DD/MM/YYYY")}
                  disabled
                  id={`simulacao-resumo-data-alteracao`}
                />
              </Col>
              <Col md={3}>
                <Label for={`simulacao-resumo-data-usuario-alteracao`}>
                  Usuário Alteração
                </Label>
                <Input
                  value={cabResume.NOMEUSU ?? null}
                  disabled
                  id={`simulacao-resumo-data-usuario-alteracao`}
                />
              </Col>
            </Row>
          </Col>
        ) : null}
      </>
    );
  }
}
