import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import moment from "moment";

const year = moment().year();

function Footer() {
  return (
    <footer className="footer">
      <Container fluid>
        <div className="copyright">© {year} Semalo Dev.</div>
      </Container>
    </footer>
  );
}

export default Footer;
