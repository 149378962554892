import React, { useState, useEffect } from "react";
// lidar com criptografias MD5
const md5 = require("md5");
// alteração no título da página no navegador
import { Helmet } from "react-helmet";
// reactstrap components
import { Row, Col } from "reactstrap";

// import DashVendasUltimosMeses from 'views/Dashboads/DashVendasUltimosMeses';
import DashAtivosInativos from "views/Dashboads/DashAtivosInativos";
import DashComissaoVendedor from "views/Dashboads/DashComissaoVendedor";
import ModalLoading from "components/Loading/ModalLoading";
// import WhatsAppMensagem from "./WhatsApp/Mensagem";

function Home(props) {
  const [loading, setLoading] = useState(true);
  // const [loadVendasUltimosMeses, setLoadVendasUltimosMeses] = useState(true);
  const [loadAtivosInativos, setLoadAtivosInativos] = useState(true);
  const [loadComissaoVendedor, setLoadComissaoVendedor] = useState(false);

  useEffect(() => {
    if (loadAtivosInativos == false && loadComissaoVendedor == false) {
      setLoading(false);
    }
  }, [loadAtivosInativos, loadComissaoVendedor]);

  return (
    <>
      <Helmet>
        <title>Home | Semalo</title>
      </Helmet>
      <div className="content">
        <Row>
          {/* <Col md={4}>
            <DashVendasUltimosMeses codUsu={sessionStorage.getItem(md5("CODUSU"))} setLoading={setLoadVendasUltimosMeses}/>
          </Col> */}
          <Col md={4}>
            <DashAtivosInativos
              codUsu={sessionStorage.getItem(md5("CODUSU"))}
              setLoading={setLoadAtivosInativos}
            />
          </Col>
          {/* <Col md={4} style={{ display: "none" }}>
            <DashComissaoVendedor
              codUsu={sessionStorage.getItem(md5("CODUSU"))}
              setLoading={setLoadComissaoVendedor}
            />
          </Col> */}
        </Row>
        {/* <Row>
          <Col md={6}>
            <WhatsAppMensagem codUsu={sessionStorage.getItem(md5("CODUSU"))} setLoading={setLoadComissaoVendedor}/>
          </Col>
        </Row> */}
      </div>
      <ModalLoading loading={loading} mensagem={"Carregando Dashboards"} />
    </>
  );
}

export default Home;
