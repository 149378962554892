import { useGlobalContext } from "contexts/GlobalContext";
import { useSimuladorContext } from "contexts/SimuladorContext";
import React, { useEffect, useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { enterpriseUseDelivery } from "services/utils";

export default function TipoEntrega() {
  const { dataSimulador, setDataSimulador } = useSimuladorContext();
  const { globalContext } = useGlobalContext();

  const [showThis, setShowThis] = useState(false);

  useEffect(() => {
    setShowThis(
      dataSimulador?.CODEMP > 0 && enterpriseUseDelivery(dataSimulador?.CODEMP)
    );
  }, [dataSimulador]);

  return (
    <>
      {showThis && (
        <>
          <FormGroup>
            <Label for="tipoEntrega">Tipo Entrega</Label>
            <Input
              type="select"
              id="tipoEntrega"
              name="tipoEntrega"
              onChange={(e) =>
                setDataSimulador((state) => ({
                  ...state,
                  TIPOENTREGA: e.target.value,
                  TIPOFAT: 4,
                }))
              }
              style={{ cursor: "pointer" }}
              required
            >
              <option value="">{"< Selecione Tipo de Entrega >"}</option>
              {globalContext?.tpEntrega?.length > 0 &&
                globalContext?.tpEntrega.map((tipoEntrega, index) => {
                  return (
                    <option
                      key={index}
                      value={tipoEntrega.VALOR}
                      selected={tipoEntrega.VALOR == dataSimulador?.TIPOENTREGA}
                    >
                      {tipoEntrega.OPCAO}
                    </option>
                  );
                })}
            </Input>
          </FormGroup>

          <div style={{ display: "flex", gap: "8px", marginTop: "8px" }}>
            <FormGroup style={{ maxWidth: "70px" }}>
              <Label for="percentualEntrega">% Entrega</Label>
              <Input
                type="number"
                id="percentualEntrega"
                name="percentualEntrega"
                presicion={2}
                step="0.01"
                value={Number(dataSimulador?.PER_ENTREGA)}
                min={0}
                onChange={(e) =>
                  setDataSimulador((state) => ({
                    ...state,
                    PER_ENTREGA: e.target.value,
                    VLR_ENTREGA:
                      Number(e.target.value) > 0 && Number(e.target.value) < 100
                        ? Number(
                            (Number(e.target.value) / 100) *
                              dataSimulador?.VLR_FATURAMENTO
                          ).toFixed(2)
                        : Number(e.target.value) === 0
                        ? 0
                        : state.VLR_ENTREGA,
                  }))
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="vlrEntrega">R$ Entrega</Label>
              <Input
                type="number"
                id="vlrEntrega"
                name="vlrEntrega"
                value={Number(dataSimulador?.VLR_ENTREGA)}
                min={0}
                step="0.01"
                presicion={2}
                onChange={(e) =>
                  setDataSimulador((state) => ({
                    ...state,
                    VLR_ENTREGA: e.target.value,
                    PER_ENTREGA:
                      Number(e.target.value) > 0 &&
                      Number(e.target.value) < dataSimulador?.VLR_FATURAMENTO
                        ? Number(
                            (e.target.value * 100) /
                              dataSimulador?.VLR_FATURAMENTO
                          ).toFixed(2)
                        : Number(e.target.value) === 0
                        ? 0
                        : state.PER_ENTREGA,
                  }))
                }
              />
            </FormGroup>
          </div>
        </>
      )}
    </>
  );
}
