import React from "react";
// reactstrap components
import { NavLink } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Nav,
} from "reactstrap";

export function CardRelatorio(props) {
  return (
    <>
      <Card>
        <CardBody>
          <CardTitle tag="h4">{props.title}</CardTitle>
          <CardSubtitle className="mb-2 text-muted" tag="h6">
            {props.subtitle}
          </CardSubtitle>
          <CardText>{props.description}</CardText>
          <Nav pills className="float-right">
            <NavLink active to={props.redirect}>
              Carregar
            </NavLink>
          </Nav>
        </CardBody>
      </Card>
    </>
  );
}
