import React, { useState, useEffect } from "react";
//lidar com criptografias
// eslint-disable-next-line no-undef
const md5 = require("md5");
// lida com as requisições
import { api } from "services/api";
import { formatValue } from "services/globals";
// alteração no título da página no navegador
import { Helmet } from "react-helmet";
// reactstrap components
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  Table,
  CardBody,
} from "reactstrap";
import ModalLoading from "components/Loading/ModalLoading";

// eslint-disable-next-line no-undef
const moment = require("moment");

export function ClientesInadimplentes() {
  const [loading, setLoading] = useState(true);
  const [clientes, setClientes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(
          `relatorios/clientes-inadimplentes/${sessionStorage.getItem(
            md5("CODUSU")
          )}`
        );
        if (response.data?.statusApi == 1) {
          const { clientesInadimplentes } = response.data;
          setClientes(clientesInadimplentes);
        } else {
          console.error("clientes-inadimplentes->status 2", response.data);
        }
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Relatório | Clientes Inadimplentes</title>
      </Helmet>
      <div className="content">
        <Row>
          <Col>
            {clientes != null ? (
              <Card className="card-chart">
                <CardHeader>
                  <h4>Clientes Inadimplentes</h4>
                  <CardTitle tag="h3">
                    <i className="tim-icons icon-bell-55 text-primary" />
                    {clientes.length} Títulos
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Cliente</th>
                        <th>Título</th>
                        <th className="text-center">Data Vencimento</th>
                        <th className="text-center">Nro. Nota</th>
                        <th className="text-center">R$ Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientes.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.CLIENTE}</td>
                            <td>{item.DESCTIPTITULO}</td>
                            <td className="text-center">
                              {moment(item.DTVENCTO).format("DD/MM/YYYY")}
                            </td>
                            <td className="text-center">{item.NRO_NOTA}</td>
                            <td className="text-center">
                              {formatValue("currency", item.VALOR)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
      <ModalLoading loading={loading} mensagem={"Carregando Relatório"} />
    </>
  );
}
