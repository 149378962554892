import React, { useState } from "react";
// lida com as requisições
import { api } from "services/api";
// lida com as toast/alertas
import { toast } from "react-toastify";
// lidar com criptografias
import { encode as base64_encode } from "base-64";
// lidar com criptografias MD5
const md5 = require("md5");
// reactstrap components
import {
  Button,
  Col,
  Label,
  Input,
  Modal,
  ModalBody,
  Row,
  Table,
  ModalHeader,
} from "reactstrap";
// lida com rotas
import { Redirect } from "react-router-dom";
import { LogAirbrake } from "services/log.airbrake";
import { proxyApi } from "services/proxyApi";

export default function ModalExtReprovaSim(props) {
  const md5 = require("md5");
  const [simulador, setSimulador] = useState([]);
  const [simuladorCli, setSimuladorCli] = useState([]);
  const [redirectToPage, setRedirectToPage] = useState(null);
  const idUsuario = sessionStorage.getItem(md5("CODUSU"));
  const [observacao, setObservacao] = useState("");

  React.useEffect(() => {
    const fetchData = async () => {
      if (props.nuSim > 0) {
        try {
          const response = await proxyApi.get(
            `simulador/${idUsuario}/${props.nuSim}`
          );
          if (response.data?.statusApi == 1) {
            // //console.log(response.data.simulador);
            setSimulador({
              imcGeral: response.data.simulador.IMCGERAL,
              usuarioInc: response.data.simulador.NOMEUSUINC,
              usuarioAlt: response.data.simulador.NOMEUSUALT,
              codUsuInc: response.data.simulador.CODUSUINC,
              codUsuAlt: response.data.simulador.CODUSUALT,
            });
            setSimuladorCli(response.data.simuladorCli);
          } else {
            toast.error(response?.data?.statusMessage, {
              autoClose: 7000,
              icon: () => <i className="tim-icons icon-simple-remove" />,
            });
            //console.log("teste", response.data);
          }
        } catch (error) {
          toast.error(
            `Não foi possível conectar ao servidor, atualize a página e tente novamente. //ListSim-x1 ${error.message}`,
            {
              autoClose: 7000,
              icon: () => <i className="tim-icons icon-simple-remove" />,
            }
          );
          console.error("error: ", error);
        }
      }
    };
    fetchData();
  }, [props.nuSim]);

  let reprovaSim = async (nuSim) => {
    let raw = {
      nuSim: nuSim,
      observacao,
    };
    // //console.log(raw);
    try {
      const response = await api.put(`simulador-reprova`, raw, {
        timeout: 60000,
      });
      if (response.data?.statusApi == 1) {
        toast.warn("O pedido foi reprovado.", { autoClose: 3000 });
        props.toggle(nuSim, true);
      } else {
        // //console.log(response.data);
        toast.error(response.data.message, {
          autoClose: 3000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        });
      }
    } catch (error) {
      // console.error(error);
      LogAirbrake(error, {
        url: `simulador-reprova`,
        method: "PUT",
        data: raw,
      });

      toast.error(
        `Não foi possível conectar ao servidor, atualize a página e tente novamente. ${error.message}`,
        {
          autoClose: 3000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        }
      );
    }
  };

  return (
    <>
      {redirectToPage != null ? <Redirect to={redirectToPage} /> : null}
      <Modal
        isOpen={props.isOpen}
        toggle={() => props.toggle()}
        backdrop="static"
        size="xl"
        modalClassName="modal-black"
      >
        <ModalHeader className="justify-content-center text-center">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => props.toggle()}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <h4>
            <b>Simulaçao - {props.nuSim}</b>
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row
            style={{
              justifyContent: "center",
            }}
          >
            <Col md={10} style={{ marginTop: "5px" }}>
              <Input
                type="text"
                id="ReprovaSimulação"
                value={observacao}
                onChange={(e) => setObservacao(e.target.value)}
                placeholder="Motivo da Reprovação"
              />
            </Col>
            <Col
              md={2}
              style={{
                display: "flex",
                justifyContent: "center",
                minWidth: "120px",
                marginTop: "5px",
              }}
            >
              <Button
                className="btn-round"
                style={{ backgroundImage: "none" }}
                color="danger"
                size="sm"
                onClick={() => reprovaSim(props.nuSim)}
              >
                Reprovar
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}
