import React, { Component } from "react";
import { Col, Row } from "reactstrap";
//funções globais
import { formatCpfCnpj, formatPhone, formatValue } from "services/globals";

export default class TabGeral extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { cabCliente } = this.props;
    return (
      <>
        <Row style={{ marginTop: "1em" }}>
          <Col md={12}>
            <span className="text-color">
              <b>Cód.:</b> {cabCliente.CODPARC ?? "—"}
            </span>
          </Col>
          <Col md={4} style={{ marginTop: ".5em" }} sm={6}>
            <Row>
              <Col>
                <p className="text-bold">Razão Social:</p>
                <p> {cabCliente.RAZAO ?? "—"}</p>
              </Col>
            </Row>
          </Col>
          <Col md={4} sm={6}>
            <Row>
              <Col>
                <p className="text-bold">
                  <b>Nome Fantasia:</b>
                </p>
                <p> {cabCliente.FANTASIA ?? "—"}</p>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row>
              <Col>
                <p className="text-bold">
                  <b>CPF / CNPJ:</b>
                </p>
                <p> {formatCpfCnpj(cabCliente.CGC_CPF) ?? "—"}</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={6}>
            <Row>
              <Col>
                <p className="text-bold">Telefone:</p>
                <p> {formatPhone(cabCliente.FONE) ?? "—"}</p>
              </Col>
            </Row>
          </Col>
          <Col md={4} sm={6}>
            <Row>
              <Col>
                <p className="text-bold">Telefone 2:</p>
                <p> {formatPhone(cabCliente.FONE2) ?? "—"}</p>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row>
              <Col>
                <p className="text-bold">
                  <b>E-mail:</b>
                </p>
                <p> {cabCliente.EMAIL ?? "—"}</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={6}>
            <Row>
              <Col>
                <p className="text-bold">Cidade / UF:</p>
                <p>
                  {cabCliente.NOMECID ?? null} — {cabCliente.UF ?? null}
                </p>
              </Col>
            </Row>
          </Col>
          <Col md={2} sm={6}>
            <Row>
              <Col>
                <p className="text-bold">Tipo Frete:</p>
                <p> {formatPhone(cabCliente.TIPOFRETE) ?? "—"}</p>
              </Col>
            </Row>
          </Col>
          <Col md={2} sm={6}>
            <Row>
              <Col>
                <p className="text-bold">
                  <b>Frete M³ (R$):</b>
                </p>
                <p> {formatValue("currency", cabCliente.VLRFRETECID) ?? "—"}</p>
              </Col>
            </Row>
          </Col>
          <Col md={2} sm={6}>
            <Row>
              <Col>
                <p className="text-bold">
                  <b>Frete Min. (R$):</b>
                </p>
                <p> {formatValue("currency", cabCliente.VLRFRETEMIN) ?? "—"}</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={6}>
            <Row>
              <Col>
                <p className="text-bold">Limite Total:</p>
                <p> {formatValue("currency", cabCliente.LIMITE) ?? "—"}</p>
              </Col>
            </Row>
          </Col>
          <Col md={4} sm={6}>
            <Row>
              <Col>
                <p className="text-bold">Limite Utilizado:</p>
                <p>
                  {" "}
                  {formatValue("currency", cabCliente.LIMITE_UTILIZADO) ?? "—"}
                </p>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row>
              <Col>
                <p className="text-bold">
                  <b>Limite Disponível:</b>
                </p>
                <p>
                  {" "}
                  {formatValue("currency", cabCliente.LIMITE_DISPONIVEL) ?? "—"}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={6}>
            <Row>
              <Col>
                <p className="text-bold">Tipo de Negocição:</p>
                <p>
                  {" "}
                  {cabCliente.CODTIPVENDA ?? null} —{" "}
                  {cabCliente.DESCRTIPVENDA ?? "—"}
                </p>
              </Col>
            </Row>
          </Col>
          <Col md={2} sm={6}>
            <Row>
              <Col>
                <p className="text-bold">Prazo Médio:</p>
                <p> {cabCliente.PRAZOMEDIO ?? "—"}</p>
              </Col>
            </Row>
          </Col>
          <Col md={2} sm={6}>
            <Row>
              <Col>
                <p className="text-bold">
                  <b>Tx. Juros (Dia):</b>
                </p>
                <p>
                  {" "}
                  {formatValue("decimal", cabCliente.TXJURODIASIMUL) ?? "—"}
                </p>
              </Col>
            </Row>
          </Col>
          <Col md={2} sm={6}>
            <Row>
              <Col>
                <p className="text-bold">
                  <b>Nº Tabela Prod.:</b>
                </p>
                <p> {cabCliente.NUTAB ?? "—"}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}
