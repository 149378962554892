import React from "react";
// react plugin used to create charts
import { Bar } from "react-chartjs-2";
// lida com as requisições
import { api } from "services/api";
// lidar com criptografias MD5
// const md5 = require('md5');
// reactstrap components
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import { proxyApi } from "services/proxyApi";

// import chart1_2_options from 'variables/chart1_2_options';

class DashAtivosInativos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboard: null,
    };
  }
  async componentDidMount() {
    let labels = [];
    let data = [];
    let total = 0;
    try {
      //console.log("----->", this.props.codUsu);
      const response = await proxyApi.get(
        `dashboard/ativos-inativos/${this.props.codUsu}`
      );
      if (response.data?.statusApi == 1) {
        const { ativosInativos } = response.data;

        for (let index = 0; index < ativosInativos.length; index++) {
          let valor = parseFloat(ativosInativos[index].QTDE);
          total = parseFloat(total) + parseFloat(valor);
          data[index] = valor;
          labels[index] = ativosInativos[index].CONTACESSO;
        }
      } else {
        console.error("DashAtivosInativos->status 2", response.data);
      }
    } catch (error) {
      console.error(error);
    }
    // total = this.formatCurrency(total);
    this.handleDashboard(labels, data, total);
    this.props.setLoading(false);
  }

  formatCurrency(valor) {
    var formatter = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(valor);
  }

  handleDashboard(labels, data, total) {
    let dashboard = {
      data: (canvas) => {
        let ctx = canvas.getContext("2d");

        let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

        gradientStroke.addColorStop(1, "rgba(72,72,176,0.1)");
        gradientStroke.addColorStop(0.4, "rgba(72,72,176,0.0)");
        gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors

        return {
          labels: labels,
          datasets: [
            {
              label: "Quantidade",
              fill: true,
              backgroundColor: gradientStroke,
              hoverBackgroundColor: gradientStroke,
              borderColor: "#fb6340",
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: data,
            },
          ],
        };
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        tooltips: {
          backgroundColor: "#f5f5f5",
          titleFontColor: "#333",
          bodyFontColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest",
        },
        responsive: true,
        scales: {
          yAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: "rgba(225,78,202,0.1)",
                zeroLineColor: "transparent",
              },
              ticks: {
                suggestedMin: 0,
                suggestedMax: 60,
                padding: 20,
                fontColor: "#9e9e9e",
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: "rgba(225,78,202,0.1)",
                zeroLineColor: "transparent",
              },
              ticks: {
                padding: 20,
                fontColor: "#9e9e9e",
              },
            },
          ],
        },
      },
      total: total,
    };
    this.setState({
      dashboard: dashboard,
    });
  }

  render() {
    const { dashboard } = this.state;
    return (
      <>
        {dashboard != null ? (
          <Card className="card-chart">
            <CardHeader>
              <h4>Carteira de Clientes</h4>
              <CardTitle tag="h3">
                <i className="tim-icons icon-user-run text-primary" />{" "}
                {dashboard.total}
                {/*aqui vai a totalização*/}
              </CardTitle>
            </CardHeader>
            <CardBody>
              <div className="chart-area">
                <Bar data={dashboard.data} options={dashboard.options} />
              </div>
            </CardBody>
          </Card>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default DashAtivosInativos;
