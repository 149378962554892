import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin/Admin.js";
import AuthLayout from "layouts/Auth/Auth.js";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { ProductContextProvider } from "./contexts/ProdutosSimuladorContext";
import { SimuladorContextProvider } from "./contexts/SimuladorContext";

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import { GlobalContextProvider } from "contexts/GlobalContext";

ReactDOM.render(
  <ThemeContextWrapper>
    <BackgroundColorWrapper>
      <GlobalContextProvider>
        <ProductContextProvider>
          <SimuladorContextProvider>
            <BrowserRouter>
              <ToastContainer autoClose={12000} />
              <Switch>
                <Route
                  path="/admin"
                  render={(props) => <AdminLayout {...props} />}
                />
                <Route
                  path="/auth"
                  render={(props) => <AuthLayout {...props} />}
                />
                <Redirect from="/" to="/auth" />
              </Switch>
            </BrowserRouter>
          </SimuladorContextProvider>
        </ProductContextProvider>
      </GlobalContextProvider>
    </BackgroundColorWrapper>
  </ThemeContextWrapper>,
  document.getElementById("root")
);
