import React, { useState, useEffect } from "react";
// lida com as requisições
import { api } from "services/api";
// lidar com criptografias MD5
// eslint-disable-next-line no-undef
const md5 = require("md5");
// lida com as toast/alertas
import { toast } from "react-toastify";
// spinners/loaders do react-spinners (tem vários estilos)
import ModalLoading from "components/Loading/ModalLoading";
// lidar com criptografias
import { decode as base64_decode } from "base-64";
// reactstrap components
import { Button, Col, Form, FormGroup, Input, Label } from "reactstrap";
import { useSimuladorContext } from "../../../contexts/SimuladorContext";
import { LogAirbrake } from "services/log.airbrake";
import TipoEntrega from "../Components/TipoEntrega";
import { useGlobalContext } from "contexts/GlobalContext";
import TipoFaturamento from "../Components/TipoFaturamento";
import TipoLogistica from "../Components/TipoLogistica";
import { useProductContext } from "contexts/ProdutosSimuladorContext";
import { enterpriseUseDelivery } from "services/utils";

import { proxyApi } from "services/proxyApi";
import { userCantInclude } from "services/utils";

function SimuladorCliente(props) {
  const userSector = base64_decode(sessionStorage.getItem(md5("NUSETOR")));
  // console.log("@@##", userSector);

  const { dataSimulador, setDataSimulador, handleUpdateSimuladorCliente } =
    useSimuladorContext();

  const { setGlobalContext } = useGlobalContext();
  const { dataProducts } = useProductContext();

  useEffect(() => {
    if (enterpriseUseDelivery(dataSimulador?.CODEMP)) {
      const VLR_FATURAMENTO = dataProducts
        .filter((product) => product?.QTDNEG > 0)
        .reduce((acc, prev) => {
          return (acc = acc + Number(prev.VLRTOTAL));
        }, 0);
      const VLR_ENTREGA = Number(
        (dataSimulador?.PER_ENTREGA / 100) * VLR_FATURAMENTO
      ).toFixed(2);
      setDataSimulador((state) => ({ ...state, VLR_FATURAMENTO, VLR_ENTREGA }));
    }
  }, [dataProducts]);

  const thisState = {
    loading: false,
    modalLoading: true,
    apenasUpdate: false,
    idUsuario: sessionStorage.getItem(md5("CODUSU")),
  };
  const setorUsuario = base64_decode(sessionStorage.getItem(md5("NUSETOR")));

  const [state, setState] = useState({ ...thisState });

  useEffect(() => {
    // carrega as empresas configuradas ao iniciar a aplicação

    const fetchData = async () => {
      props.setCabOptionEmpresa(
        JSON.parse(base64_decode(sessionStorage.getItem(md5("EMPRESAS"))))
      );
      if (
        props.getCabCodParc != null &&
        !isNaN(props.getCabCodParc) &&
        sessionStorage.getItem(md5("SIMULADOR_NUSIMCLI")) == null
      ) {
        // verifica se o usuário está lançando um novo cliente e carrega as informações do cliente que foi selecionado na busca de cliente
        try {
          const response = await api.post(
            `cliente/simulador/${state.idUsuario}`,
            { codParc: props.getCabCodParc }
          );
          if (response.data?.statusApi === 1) {
            const {
              cliente,
              opLogistica,
              tpFaturamento,
              tpEntrega,
              tpPagDescarga,
              resume,
              validaCliente,
              validaFrete,
              validaProdutos,
              validaVerbas,
              tipoNegociacao,
            } = response.data;

            setGlobalContext((state) => ({
              ...state,
              tpFaturamento,
              tpEntrega,
              tpPagDescarga,
              opLogistica,
            }));

            console.log("cliente", cliente);

            setDataSimulador((state) => ({
              ...state,
              CODTIPVENDA: cliente.CODTIPVENDA,
              CODTIPNEG: cliente.CODTIPVENDA,
              AD_PROSP: cliente.AD_PROSP,
              PRAZOMEDIO: cliente.PRAZOMEDIO,
            }));

            handleValidation(
              validaCliente,
              validaFrete,
              validaProdutos,
              validaVerbas
            );

            props.setCabResume(resume);
            props.setCabCliente(cliente);
            props.setCabOptionOperLogistico(opLogistica);
            props.setCabOptionTipoPagDescarga(tpPagDescarga);
            props.setCabOptionTipoNegociacao(tipoNegociacao.tipoNeg);
          } else {
            toast.error(response.data.message, {
              autoClose: 7000,
              icon: () => <i className="tim-icons icon-simple-remove" />,
            });
          }
        } catch (error) {
          LogAirbrake(error, {
            url: `cliente/simulador/${state.idUsuario}`,
            method: "POST",
            data: { codParc: props.getCabCodParc },
          });

          // console.log("error:", error);
          toast.error(
            console.log(
              "error 9",
              error
            )`Não foi possível conectar ao servidor, atualize a página e tente novamente. //mount-x99 ${error.message}`,
            {
              autoClose: 7000,
              icon: () => <i className="tim-icons icon-simple-remove" />,
            }
          );
        }
      } else if (
        base64_decode(sessionStorage.getItem(md5("SIMULADOR_NUSIMCLI"))) > 0
      ) {
        // verifica se o usuário está tentando editar cliente e carrega as informações do cliente que foi selecionado
        try {
          const response = await proxyApi.get(
            `simulador-cliente/${state.idUsuario}/${base64_decode(
              sessionStorage.getItem(md5("SIMULADOR_NUSIMCLI"))
            )}`,
            { codParc: props.getCabCodParc }
          );

          // console.log("aqui4--->");

          if (response.data?.statusApi === 1) {
            const {
              simulador,
              simuladorCli,
              opLogistica,
              tpFaturamento,
              tpEntrega,
              tpPagDescarga,
              resume,
              validaCliente,
              validaFrete,
              validaProdutos,
              validaVerbas,
              tipoNegociacao,
            } = response.data;

            setGlobalContext((state) => ({
              ...state,
              tpFaturamento,
              tpEntrega,
              tpPagDescarga,
              opLogistica,
              simulador,
            }));

            handleValidation(
              validaCliente,
              validaFrete,
              validaProdutos,
              validaVerbas
            );

            props.setCabResume(resume);
            props.setCabCliente(simuladorCli);
            props.setCabOptionOperLogistico(opLogistica);
            props.setCabOptionTipoPagDescarga(tpPagDescarga);
            props.setCabVlrDegustacao(simuladorCli.DEGUSTACAO);
            props.setCabVlrDescarga(simuladorCli.DESCARGA);
            props.setCabImcGeral(simuladorCli.IMC_CLI);
            props.setCabObservacao(simuladorCli.OBSERVACAO);
            props.setCabOrdemCompra(simuladorCli.ORDEMCOMPRA);

            props.setCabOptionTipoNegociacao(tipoNegociacao.tipoNeg);

            props.setCabEmpresa({
              codEmp: simuladorCli.CODEMP,
              nomeFantasia: simuladorCli.FANTASIA,
            });
            props.setCabTipoPagDescarga(simuladorCli.TIPOPAGDESCARGA); //informa descarga selecionada
            props.setCabStatusSimulacao(simulador.STATUS); //informa status da simulação atual

            // caso tenha logística, informa os dados
            // console.log('simuladorCli', simuladorCli)

            //console.log(simuladorCli.NROREG)
            if (simuladorCli?.NROREG && Number(simuladorCli.NROREG) > 0) {
              //console.log('testeeee', simuladorCli.PERLOGISTICA)
              props.setCabOperLogistico({
                nroReg: Number(simuladorCli.NROREG),
                perlogistica: simuladorCli.PERLOGISTICA,
              });
            }
          } else {
            // setState({cliente: []}) //atualizando o estado para pesquisa atual, vazio pois não teve retorno.
            toast.error(response.data.message, {
              autoClose: 7000,
              icon: () => <i className="tim-icons icon-simple-remove" />,
            });
          }
        } catch (error) {
          console.log("error 10", error);
          toast.error(
            `Não foi possível conectar ao servidor, atualize a página e tente novamente. //mount-x2 ${error.message}`,
            {
              autoClose: 7000,
              icon: () => <i className="tim-icons icon-simple-remove" />,
            }
          );
        }
      } else {
        toast.error(
          "Verifique os dados do cliente selecionado e tente novamente.",
          {
            autoClose: 7000,
            icon: () => <i className="tim-icons icon-simple-remove" />,
          }
        );
      }
      setState({ ...state, modalLoading: false });
    };
    fetchData();
  }, []);

  const handleValidation = (
    validaCliente,
    validaFrete,
    validaProdutos,
    validaVerbas
  ) => {
    const {
      setCabValidacaoProduto,
      setCabValidacaoCliente,
      setCabValidacaoFrete,
      setCabValidacaoVerba,
    } = props;

    //console.log("validaFrete@", validaFrete);
    setCabValidacaoProduto(validaProdutos);
    setCabValidacaoCliente(validaCliente);
    setCabValidacaoFrete(validaFrete);
    setCabValidacaoVerba(validaVerbas);
  };

  const handleChangeEmpresa = (index) => {
    let codEmp = index != "" ? props.getCabOptionEmpresa[index].CODEMP : "";
    let nomeFantasia =
      index != "" ? props.getCabOptionEmpresa[index].NOMEFANTASIA : "";
    props.setCabEmpresa({
      codEmp: codEmp,
      nomeFantasia: nomeFantasia,
    });

    let regraEntrega;

    if (enterpriseUseDelivery(codEmp)) {
      regraEntrega = { PERLOGISTICA: null, VLRFRETELOG: null };
    } else {
      regraEntrega = { VLR_ENTREGA: null, PER_ENTREGA: null };
    }

    setDataSimulador({
      ...dataSimulador,
      ...regraEntrega,
      CODEMP: codEmp,
      TIPOFAT: enterpriseUseDelivery(codEmp)
        ? dataSimulador?.TIPOENTREGA == 1
          ? 1
          : 4
        : dataSimulador?.TIPOFAT,
      NOMEFANTASIA: nomeFantasia,
    });
  };

  const handleChangeTipoNegociacao = (value) => {
    const { CODTIPVENDA, DESCRTIPVENDA, PRAZOMEDIO } =
      props.getCabOptionTipoNegociacao.find(
        (item) => item.CODTIPVENDA == value
      );

    setDataSimulador((state) => ({
      ...state,
      CODTIPNEG: CODTIPVENDA,
      DESCRTIPVENDA,
      PRAZOMEDIO,
    }));
  };

  const handleChangeTipoPagDescarga = (index) => {
    const valor =
      index != "" ? props.getCabOptionTipoPagDescarga[index].VALOR : "";
    props.setCabTipoPagDescarga(valor);

    setDataSimulador({ ...dataSimulador, TIPOPAGDESCARGA: valor });
  };

  const handleCabVlrDescarga = (value) => {
    setDataSimulador({ ...dataSimulador, DESCARGA: value });
    props.setCabVlrDescarga(value);
  };

  const handleCabVlrDegustacao = (value) => {
    setDataSimulador({ ...dataSimulador, DEGUSTACAO: value });
    props.setCabVlrDegustacao(value);
  };

  const handleSetObservacao = (value) => {
    props.setCabObservacao(value);
    setDataSimulador({ ...dataSimulador, OBSERVACAO: value });
  };

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }

    // let liberado = true;

    setState({ ...state, modalLoading: true });

    try {
      const response = await handleUpdateSimuladorCliente({ criaSimulador });

      if (response.data?.statusApi == 1 || response.data?.statusApi == 3) {
        const {
          simulador,
          simuladorCli,
          simuladorIte,
          resume,
          validaCliente,
          validaFrete,
          validaProdutos,
          validaVerbas,
        } = response.data;

        if (response.data?.statusApi == 1) {
          setDataSimulador({
            ...simuladorCli,
            AD_PROSP: props.getCabCliente.AD_PROSP,
          });
        }

        handleValidation(
          validaCliente,
          validaFrete,
          validaProdutos,
          validaVerbas
        );

        props.setCabResume(resume);
        props.setCabProduto(simuladorIte);
        props.setCabNuSimCli(simuladorCli.NUSIMCLI);
        props.setCabImcGeral(simuladorCli.IMC_CLI);
        props.setCabStatusSimulacao(simulador.STATUS); //informa status da simulação atual
        props.setCabCargaCompartilhada(simulador.CARGACOMPARTILHADA);
        props.goToProduto();
      } else {
        if (
          response?.data?.statusMessage == "Cliente já lançado neste simulador."
        ) {
          props.setCabNuSimCli(response.data.simuladorCli.NUSIMCLI);
        }
        toast.error(response?.data?.statusMessage, {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        });
      }
    } catch (error) {
      console.log("error 11", error);
      LogAirbrake(error, {
        url: `simulador-cliente/${state.idUsuario}`,
        method: "POST/PUT",
        data: dataSimulador,
      });

      toast.error(
        `Não foi possível conectar ao servidor, atualize a página e tente novamente. //LanCli-x1 ${error.message}`,
        {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        }
      );
    }

    setState({ ...state, modalLoading: false });
  };

  const criaSimulador = async () => {
    try {
      const response = await api.post(`simulador/${state.idUsuario}`, {
        codUsuInc: base64_decode(sessionStorage.getItem(md5("CODUSU"))),
        cargaCompartilhada: props.getCabCargaCompartilhada,
        statusSimulador: props.getCabStatusSimulacao,
      });
      //console.log(props.getCabStatusSimulacao);
      //console.log(props.getCabCargaCompartilhada);
      if (response.data?.statusApi === 1) {
        props.setCabNuSim(response.data.simulador.NUSIM);
        return response.data.simulador.NUSIM;
      } else {
        toast.error(response.data.message, {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        });
        return null;
      }
    } catch (error) {
      console.log("error 12", error);
      LogAirbrake(error, {
        url: `simulador/${state.idUsuario}`,
        method: "POST",
        data: {
          codUsuInc: base64_decode(sessionStorage.getItem(md5("CODUSU"))),
          cargaCompartilhada: props.getCabCargaCompartilhada,
          statusSimulador: props.getCabStatusSimulacao,
        },
      });

      toast.error(
        `Não foi possível conectar ao servidor, atualize a página e tente novamente. //crSimulador-x1 ${error.message}`,
        {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        }
      );
      return null;
    }
  };

  return (
    <>
      <Col style={{ padding: "15px 0px" }}>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <div className="form-row">
            <FormGroup className="col-md-2">
              <Label for="inputCodEmp">Cód. Empresa</Label>
              <Input
                type="text"
                id="inputCodEmp"
                name="inputCodEmp"
                value={props.getCabEmpresa?.codEmp}
                disabled
              />
            </FormGroup>
            <FormGroup className="col-md-10">
              <Label for="inputEmpresaFaturante">Empresa Faturante*</Label>
              <Input
                type="select"
                id="inputEmpresaFaturante"
                name="inputEmpresaFaturante"
                onChange={(e) => handleChangeEmpresa(e.target.value)}
                style={{ cursor: "pointer" }}
                disabled={
                  props.getCabStatusSimulacao != 0 || setorUsuario == "1"
                }
                required
              >
                <option value="">{"< Selecione uma empresa >"}</option>
                {props.getCabOptionEmpresa?.map((emp, index) => {
                  return (
                    <option
                      key={index}
                      value={index}
                      selected={props.getCabEmpresa?.codEmp == emp.CODEMP}
                    >
                      {emp.CODEMP + " - " + emp.NOMEFANTASIA}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
          </div>
          {/* ////////////////////////////////////////////////////////////////////////////////////////// */}
          <div className="form-row">
            <FormGroup className="col-md-2">
              <Label for="inputCodParc">Cliente</Label>
              <Input
                type="text"
                id="inputCodParc"
                name="inputCodParc"
                value={props.getCabCliente?.CODPARC}
                disabled
              />
            </FormGroup>
            <FormGroup className="col-md-5">
              <Label for="inputRazaoSocial">Razão Social</Label>
              <Input
                type="text"
                id="inputRazaoSocial"
                name="inputRazaoSocial"
                value={props.getCabCliente?.RAZAO}
                disabled
              />
            </FormGroup>
            <FormGroup className="col-md-5">
              <Label for="inputNomeFantasia">Nome Fantasia</Label>
              <Input
                type="text"
                id="inputNomeFantasia"
                name="inputNomeFantasia"
                value={props.getCabCliente?.FANTASIA}
                disabled
              />
            </FormGroup>
          </div>
          {/* ////////////////////////////////////////////////////////////////////////////////////////// */}
          <div className="form-row">
            <FormGroup className="col-md-2">
              <Label for="ordemCompra">Ordem de Compra</Label>
              <Input
                type="text"
                id="ordemCompra"
                name="ordemCompra"
                maxLength={20}
                value={props.getCabOrdemCompra}
                onChange={(e) => props.setCabOrdemCompra(e.target.value)}
              />
            </FormGroup>
            <FormGroup className="col-md-1">
              <Label for="inputCodTipVenda">Negociação</Label>
              <Input
                type="text"
                id="inputCodTipVenda"
                name="inputCodTipVenda"
                value={dataSimulador?.CODTIPNEG}
                disabled
                required
              />
            </FormGroup>
            <FormGroup className="col-md-7">
              <Label for="inputTipoNegociacao">Descrição da Negociação</Label>
              <Input
                type="select"
                id="inputTipoNegociacao"
                name="inputTipoNegociacao"
                onChange={(e) => handleChangeTipoNegociacao(e.target.value)}
                style={{ cursor: "pointer" }}
                disabled={
                  props.getCabStatusSimulacao != 0 || setorUsuario == "1"
                }
                required
              >
                <option value="">
                  {"< Selecione um tipo de negociação >"}
                </option>
                {props.getCabOptionTipoNegociacao?.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={item?.CODTIPVENDA}
                      selected={item?.CODTIPVENDA == dataSimulador?.CODTIPNEG}
                    >
                      {item.CODTIPVENDA + " - " + item.DESCRTIPVENDA}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
            <FormGroup className="col-md-2">
              <Label for="inputTxJurosDiaSim">%Juros Dia</Label>
              <Input
                type="text"
                id="inputTxJurosDiaSim"
                name="inputTxJurosDiaSim"
                value={props.getCabCliente?.TXJURODIASIMUL}
                disabled
              />
            </FormGroup>
          </div>
          {/* ////////////////////////////////////////////////////////////////////////////////////////// */}
          <div className="form-row">
            <FormGroup className="col-md-6">
              <Label for="tipoPagDescarga">Tipo de Descarga*</Label>
              <Input
                type="select"
                id="tipoPagDescarga"
                name="tipoPagDescarga"
                onChange={(e) => handleChangeTipoPagDescarga(e.target.value)}
                style={{ cursor: "pointer" }}
                disabled={
                  props.getCabStatusSimulacao != 0 || setorUsuario == "1"
                }
                required
              >
                <option value="">{"< Selecione Tipo de Descarga >"}</option>
                {props.getCabOptionTipoPagDescarga?.map((descarga, index) => {
                  return (
                    <option
                      key={index}
                      value={index}
                      selected={props.getCabTipoPagDescarga == descarga.VALOR}
                    >
                      {descarga.OPCAO}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
            {!enterpriseUseDelivery(dataSimulador.CODEMP) && (
              <Col className="col-md-6">
                <TipoFaturamento />
              </Col>
            )}
          </div>
          {/* ////////////////////////////////////////////////////////////////////////////////////////// */}
          {dataSimulador?.comLogistica &&
            !enterpriseUseDelivery(dataSimulador?.CODEMP) && (
              <div className="form-row">
                {/* <FormGroup className="col-md-2">
                  <Label for="inputCodLogistica">Região</Label>
                  <Input
                    type="text"
                    id="inputNroReg"
                    name="inputNroReg"
                    value={dataSimulador?.NROREG}
                    disabled
                  />
                </FormGroup> */}
                <Col className="col-md-8">
                  <TipoLogistica />
                </Col>
                <FormGroup className="col-md-2">
                  <Label for="inputPerLogistica">% Logística</Label>
                  <Input
                    type="text"
                    id="inputPerLogistica"
                    name="inputPerLogistica"
                    value={dataSimulador?.PERLOGISTICA}
                    disabled
                  />
                </FormGroup>
              </div>
            )}
          {/* ////////////////////////////////////////////////////////////////////////////////////////// */}
          <div className="form-row">
            <FormGroup className="col-md-6">
              <Label for="vlrDegustacao">R$ Degustação</Label>
              <Input
                type="number"
                id="vlrDegustacao"
                name="vlrDegustacao"
                min="0.00"
                step="0.001"
                presicion={2}
                value={props.getCabVlrDegustacao}
                onChange={(e) => handleCabVlrDegustacao(e.target.value)}
                disabled={
                  props.getCabStatusSimulacao != 0 || setorUsuario == "1"
                }
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label for="vlrDescarga">R$ Descarga</Label>
              <Input
                type="number"
                id="vlrDescarga"
                name="vlrDescarga"
                min="0.00"
                step="0.001"
                presicion={2}
                value={props.getCabVlrDescarga}
                onChange={(e) => handleCabVlrDescarga(e.target.value)}
                disabled={
                  props.getCabStatusSimulacao != 0 || setorUsuario == "1"
                }
              />
            </FormGroup>
            <FormGroup className="col-md-12">
              <TipoEntrega />
            </FormGroup>

            {dataSimulador?.TIPOENTREGA == 1 && (
              <div className="form-row">
                <Col className="col-md-8">
                  <TipoLogistica
                    showByTipoEmprega={dataSimulador?.TIPOENTREGA}
                  />
                </Col>
                <FormGroup className="col-md-2">
                  <Label for="inputPerLogistica">% Logística</Label>
                  <Input
                    type="text"
                    id="inputPerLogistica"
                    name="inputPerLogistica"
                    value={dataSimulador?.PERLOGISTICA}
                    disabled
                  />
                </FormGroup>
              </div>
            )}
          </div>
          <div className="form-row">
            <FormGroup className="col-md-12">
              <Label for="observacao">Observação</Label>
              <Input
                type="textarea"
                id="observacao"
                name="observacao"
                value={props.getCabObservacao}
                autoComplete="off"
                onChange={(e) => handleSetObservacao(e.target.value)}
              />
            </FormGroup>
          </div>
          {!userCantInclude(userSector) && (
            <Button
              color="primary"
              disabled={
                state.modalLoading ||
                props.getCabStatusSimulacao != 0 ||
                setorUsuario == "1"
              }
            >
              {props.getCabNuSimCli > 0
                ? "Atualizar Cliente"
                : "Iniciar Simulação"}
            </Button>
          )}
        </Form>
      </Col>
      <ModalLoading
        loading={state.modalLoading}
        mensagem={"Carregando dados do Simulador"}
      />
    </>
  );
}

export default SimuladorCliente;
