const location = window.location.hostname;
//para não ter que ficar compilando o front várias vezes com hosts diferentes pra acesso interno ou externo
//aqui já faz a identificação de onde vem a requisição e devolve host correto.
const origins = ["representantes.semalo.com.br", "192.168.0.15", "localhost"];
let host = null;
if (location == origins[0]) {
  host = process.env.REACT_APP_HOST_EXTERNO;
} else {
  host = process.env.REACT_APP_HOST_INTERNO;
}
// //console.log(host);

export { host };
