import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ModalLoading from "components/Loading/ModalLoading";
import { useHistory } from "react-router-dom";
import { cpf, cnpj } from "cpf-cnpj-validator";
import { decode as base64_decode } from "base-64";
import md5 from "md5";

import {
  Row,
  Button,
  Col,
  Form,
  Input,
  Label,
  Card,
  FormGroup,
} from "reactstrap";
import { MdSave } from "react-icons/md";
import { Helmet } from "react-helmet";
import AsyncSelect from "react-select/async";
import { apiNode } from "services/apiNode";
import { PatternFormat } from "react-number-format";
import "./style.css";

export default function CadastroCliente(props) {
  const history = useHistory();
  const CODVEND = base64_decode(sessionStorage.getItem(md5("CODVEND")));

  const [sellers, setSellers] = useState([]);

  const [customerData, setCustomerData] = useState({
    TIPPESSOA: "F",
    ATIVO: "S",
    AD_TIPFRETE: "C",
    AD_PROSP: "S",
    CODREG: 340200,
    CODTIPPARC: 10118000,
  });

  useEffect(() => {
    apiNode.get(`sellers/id?CODVEND=${CODVEND}`).then((res) => {
      if (res.data[0]?.TIPVEND === "S") {
        apiNode.get(`sellers/manager?CODGER=${CODVEND}`).then((response) => {
          setSellers([
            { CODVEND: 0, APELIDO: "SELECIONE UMA OPÇÃO" },
            ...response.data,
          ]);

          setCustomerData((state) => ({
            ...state,
            CODASSESSOR: Number(CODVEND),
          }));
        });
      } else {
        setCustomerData((state) => ({
          ...state,
          CODASSESSOR: Number(res.data[0].CODGER),
          CODVEND: Number(res.data[0].CODVEND),
        }));
        setSellers([...res.data]);
      }
    });
  }, []);

  const [loading, setLoading] = useState(false);

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      background: "#1E8C99",
      width: "100%",
      color: "white",
    }),
    control: (provided, state) => ({
      ...provided,
      background: "#1E8C99",
      width: "100%",
      color: "white",
      borderColor: "#2b3553",
    }),

    singleValue: (provided, state) => ({
      ...provided,
      color: "white",
    }),

    input: (provided, state) => ({
      ...provided,
      color: "white",
    }),

    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted gray",
      background: "#1E8C99",
      color: state.isSelected ? "gray" : "white",
    }),
  };

  const handleSubmit = async () => {
    try {
      if (
        !customerData?.NOMEPARC ||
        customerData?.NOMEPARC?.toString()?.trim() === ""
      )
        throw new Error("Informe o nome do cliente.");
      if (customerData.TIPPESSOA === "F" && !cpf.isValid(customerData.CGC_CPF))
        throw new Error("CPF Inválido.");
      if (customerData.TIPPESSOA === "J" && !cnpj.isValid(customerData.CGC_CPF))
        throw new Error("CNPJ Inválido.");
      if (
        !customerData?.TELEFONE ||
        customerData?.TELEFONE.toString().trim() === ""
      )
        throw new Error("Informe um telefone.");
      if (!customerData?.CODCID || !customerData?.CODCID > 0)
        throw new Error("Informe uma cidade.");
      if (!customerData?.CODVEND || !customerData?.CODVEND > 0)
        throw new Error("Informe um vendedor.");
      if (!customerData?.EMAIL || customerData?.EMAIL.toString().trim() === "")
        throw new Error("Informe um email.");

      await apiNode
        .post("customers", customerData)
        .then((res) => {
          toast.success("Cliente cadastrado com sucesso!", { autoClose: 7000 });
          history.push("/admin/clientes");
        })
        .catch((error) => {
          // console.log(`error---`, error);
          if (error instanceof Error) {
            throw new Error(`Cliente já cadastrado!`);
          }
        });
    } catch (error) {
      if (error instanceof Error) {
        toast.error(`${error.message}`, {
          autoClose: 7000,
        });
      }
    }
  };

  const promiseOptions = async (inputValue) =>
    inputValue.length < 3
      ? []
      : apiNode.get(`cities?name=${inputValue}`).then((res) => {
          return res.data.map((city) => ({
            value: city?.CODCID,
            label: `${city?.NOMECID.toString().trim()}-${city?.State?.UF}`,
          }));
        });

  return (
    <>
      <Helmet>
        <title>Clientes | Semalo</title>
      </Helmet>
      <div className="content">
        <Card style={{ padding: "1em 1em 0 1em" }}>
          <Col>
            <Row>
              <Col style={{ padding: "15px 10px" }}>
                <Form>
                  <div className="form-group">
                    <FormGroup>
                      <Row>
                        <Col className="col-3">
                          <Label for="tipoFrete">Tipo Frete</Label>
                          <Input
                            type="select"
                            id="tipoFrete"
                            name="tipoFrete"
                            onChange={(e) =>
                              setCustomerData((state) => ({
                                ...state,
                                AD_TIPFRETE: e.target.value,
                              }))
                            }
                          >
                            <option value={"C"}>CIF</option>
                            <option value={"F"}>FOB</option>
                          </Input>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col className="col-3">
                          <Label for="tipoPesoa">Tipo</Label>
                          <Input
                            type="select"
                            id="tipoPesoa"
                            name="tipoPesoa"
                            onChange={(e) =>
                              setCustomerData((state) => ({
                                ...state,
                                TIPPESSOA: e.target.value,
                              }))
                            }
                          >
                            <option value={"F"}>Física</option>
                            <option value={"J"}>Jurídica</option>
                          </Input>
                        </Col>

                        <Col>
                          <Label for="NOMEPARC">
                            Nome<span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            id="NOMEPARC"
                            name="NOMEPARC"
                            placeholder="Nome"
                            onChange={(e) =>
                              setCustomerData((state) => ({
                                ...state,
                                NOMEPARC: e.target.value
                                  .toString()
                                  .toUpperCase(),
                                RAZAOSOCIAL: e.target.value
                                  .toString()
                                  .toUpperCase(),
                              }))
                            }
                          />{" "}
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col>
                          {" "}
                          <Label for="CGC_CPF">
                            {customerData?.TIPPESSOA === "J" ? "CNPJ" : "CPF"}
                            <span className="text-danger">*</span>
                          </Label>
                          <PatternFormat
                            type="text"
                            placeholder={`Informe o ${
                              customerData?.TIPPESSOA === "F" ? "CPF" : "CNPJ"
                            }`}
                            onChange={(e) =>
                              setCustomerData((state) => ({
                                ...state,
                                CGC_CPF: e.target.value
                                  .toString()
                                  .replaceAll(".", "")
                                  .replaceAll("/", "")
                                  .replaceAll("-", ""),
                              }))
                            }
                            name="cpf"
                            format={`${
                              customerData?.TIPPESSOA === "F"
                                ? "###.###.###-##"
                                : "##.###.###/####-##"
                            }`}
                            className={"form-control"}
                          />
                        </Col>
                        <Col>
                          {" "}
                          <Label for="telefone">
                            Telefone<span className="text-danger">*</span>
                          </Label>
                          <PatternFormat
                            type="text"
                            placeholder={`Informe o telefone`}
                            onChange={(e) =>
                              setCustomerData((state) => ({
                                ...state,
                                TELEFONE: e.target.value
                                  .replaceAll("(", "")
                                  .replaceAll(")", "")
                                  .replaceAll(" ", "")
                                  .replaceAll("-", ""),
                              }))
                            }
                            name="telefone"
                            format={"(##) # ####-####"}
                            className={"form-control"}
                          />
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup>
                      <Label for="cidade">
                        Cidade<span className="text-danger">*</span>
                      </Label>
                      <AsyncSelect
                        placeholder={"Digite o nome da cidade"}
                        styles={customStyles}
                        cacheOptions
                        defaultOptions
                        onChange={(e) =>
                          setCustomerData((state) => ({
                            ...state,
                            CODCID: e.value,
                          }))
                        }
                        loadOptions={promiseOptions}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="vendedor">
                        RCA<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="select"
                        id="rca"
                        name="rca"
                        onChange={(e) =>
                          setCustomerData((state) => ({
                            ...state,
                            CODVEND: Number(e.target.value),
                          }))
                        }
                        style={{ cursor: "pointer" }}
                        disabled={false}
                      >
                        {sellers.map((seller) => (
                          <option key={seller.CODVEND} value={seller.CODVEND}>
                            {seller.APELIDO}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>

                    <FormGroup>
                      <Label for="email">
                        Email<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        id="email"
                        name="email"
                        placeholder="Email"
                        onChange={(e) =>
                          setCustomerData((state) => ({
                            ...state,
                            EMAIL: e.target.value.toString().toUpperCase(),
                          }))
                        }
                      />
                    </FormGroup>
                    <FormGroup></FormGroup>
                  </div>
                </Form>
              </Col>
            </Row>
            <Row style={{ justifyContent: "flex-end", margin: "16px" }}>
              <Button
                onClick={handleSubmit}
                type="submit"
                className="float-right"
                color="primary"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "8px",
                }}
              >
                <MdSave />
                Salvar
              </Button>
            </Row>
          </Col>
          <ModalLoading loading={loading} mensagem={"Buscando Clientes"} />
        </Card>
      </div>
    </>
  );
}
