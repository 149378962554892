import React from "react";
// trabalho as rotas
import { Redirect } from "react-router-dom";

import { useState, useEffect } from "react";
// alteração no título da página no navegador
import { Helmet } from "react-helmet";
// lidar com criptografias
import { encode as base64_encode } from "base-64";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Col,
  Container,
} from "reactstrap";
import logoImg from "assets/img/logo.png";
import NotificationAlert from "react-notification-alert";
import { LogAirbrake } from "services/log.airbrake";
import { proxyApi } from "services/proxyApi";

function Login() {
  //lidar com datas
  const moment = require("moment");
  //lidar com criptografias
  const md5 = require("md5");
  //lidar com as notificações (toasts)
  const notificationAlertRef = React.useRef(null);
  const notify = (place, type, icon, message) => {
    // type => "primary" || "success" || "danger" || "warning" || "info"
    let options = {};
    options = {
      place: place,
      message: message,
      type: type,
      icon: icon,
      autoDismiss: 8,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  // states
  const [usuario, setUsuario] = useState("");
  const [toPage, setToPage] = useState(null);
  const [senha, setSenha] = useState("");
  const [autenticando, setAutenticando] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    setAutenticando(true);
    notify(
      "tc",
      "info",
      "tim-icons icon-badge",
      <div>
        <div>
          Iniciando autênticação do usuário <b>{usuario}</b>.
        </div>
      </div>
    );

    try {
      const response = await proxyApi.post("login", {
        login: usuario,
        senha: base64_encode(senha),
      });

      console.log("response@@##", response);
      if (response.data?.statusApi === 1) {
        //console.log(base64_encode(senha))
        const { emp, user } = response.data;
        // //console.log(response.data.user)
        notify(
          "tc",
          "success",
          "tim-icons icon-spaceship",
          "Usuário autenticado."
        );
        const MINUTOSFIN = user.MINUTOSFIN > 0 ? user.MINUTOSFIN : 300;
        // const MINUTOSFIN = .7; //teste segundos
        // let segundosLimite = (MINUTOSFIN * 60) * 1; // 30minutos * 60segundos = 30min //tempo está em segundos
        let segundosLimite = 60 * 60 * 24; // fixo 24h de login, precisa ajustar bug da quantidade de msg de aviso de desconexão e set novo tempo a cada ação feita
        let dataLimite = moment().add(segundosLimite - 2, "seconds"); //considerando possível delay de 2segundos no navegador

        // //console.log(md5("EMPRESAS"));
        // //console.log(base64_encode(response.data.emp));
        // //console.log(JSON.stringify(response.data.emp));
        // //console.log(response.data.emp);

        sessionStorage.setItem(
          md5("EMPRESAS"),
          base64_encode(JSON.stringify(emp))
        ); //data e hora limite de acesso ao portal;
        sessionStorage.setItem(
          md5("acessoLimite"),
          base64_encode(dataLimite.format())
        ); //data e hora limite de acesso ao portal;
        sessionStorage.setItem(md5("CODEMP"), base64_encode(user.CODEMP)); //código empresa
        sessionStorage.setItem(md5("CODGER"), base64_encode(user.CODGER)); // código do gerente
        sessionStorage.setItem(md5("CODUSU"), base64_encode(user.CODUSU)); // código do usuário
        sessionStorage.setItem(md5("CODVEND"), base64_encode(user.CODVEND)); // código do vendedor do usuário
        sessionStorage.setItem(
          md5("DTACESSOATUAL"),
          base64_encode(user.DTACESSOATUAL)
        ); // data e hora do acesso atual
        sessionStorage.setItem(
          md5("DTULTACESSO"),
          base64_encode(user.DTULTACESSO)
        ); // data e hora do último acesso
        sessionStorage.setItem(
          md5("DTULTIMASENHA"),
          base64_encode(user.DTULTIMASENHA)
        ); // data da ultima troca de senha
        sessionStorage.setItem(md5("EMAIL"), base64_encode(user.EMAIL)); // email do usuário
        sessionStorage.setItem(md5("MINUTOSFIN"), base64_encode(MINUTOSFIN)); // tempo de acesso ao portal
        sessionStorage.setItem(md5("NOMEUSU"), base64_encode(user.NOMEUSU)); // nome do usuário (LOGIN)
        sessionStorage.setItem(
          md5("NOMEUSUCPLT"),
          base64_encode(user.NOMEUSUCPLT)
        ); // nome completo do usuário
        sessionStorage.setItem(md5("NUSETOR"), base64_encode(user.NUSETOR)); // código do setor
        sessionStorage.setItem(md5("TIPVEND"), base64_encode(user.TIPVEND)); // tipo de cadastro vendor/comprador => gerente||supervisor||vededor||comprador||representante...
        sessionStorage.setItem(md5("BASETESTE"), base64_encode(user.BASETESTE)); // tipo de cadastro vendor/comprador => gerente||supervisor||vededor||comprador||representante...
        sessionStorage.setItem(md5("ENVIAEMAIL"), user.AD_ENVIAEMAILSIM);
        //console.log('')
        // let emp = sessionStorage.getItem(md5("EMPRESAS")); //data e hora limite de acesso ao portal;
        // //console.log(JSON.parse(base64_decode(emp)));
        // //console.log(JSON.parse(emp));
        // DTULTIMASENHA //se estiver nulo, precisa obrigar a trocar a senha
        //console.log(user)
        //console.log(user.CODUSU)
        localStorage.setItem("NODEJS_API", "YES");
        if (user.CODUSU === 50) {
          setToPage("/admin/pendentes");
        } else {
          // if (user.DTULTIMASENHA == md5("alterar")) {
          //   setToPage("/auth/primeiro-acesso");
          // } else {
          setToPage("/admin/simulador");
          // }
        }
        setAutenticando(false);
        // navigate('/app/dashboard', { replace: true });
      } else {
        console.log("@@@##", response);
        const { message, solucao } = response.data;
        let messageData = (
          <div>
            <h5>
              <b>Aviso:</b>
              {"ㅤ"}
              {message}
            </h5>
            {/* <p>
              <b>Solução:</b>
              {"ㅤ"}
              {solucao}
            </p> */}
          </div>
        );
        notify("tc", "danger", "tim-icons icon-simple-remove", messageData);
        // //console.log(response.data.message);
        setAutenticando(false);
        //tratar erros
        //resposta está chegando assim ->>> message: senha: ["The senha must not be greater than 6 characters."]
      }
    } catch (error) {
      console.log("error@@##", error);

      LogAirbrake(error, {
        url: "login",
        method: "POST",
        data: {
          login: usuario,
          senha: base64_encode(senha),
        },
      });

      notify(
        "tc",
        "danger",
        "tim-icons icon-simple-remove",
        `Não foi possível conectar ao servidor, atualize a página e tente novamente. ${error.message}`
      );
      setAutenticando(false);
      console.error("error: ", error);
    }
  }

  return (
    <>
      {toPage != null ? <Redirect push from="/auth/login" to={toPage} /> : null}
      <Helmet>
        <title>Login | Semalo</title>
      </Helmet>
      <div className="react-notification-alert-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container>
        <Col
          className="container"
          sm="12"
          md={{ size: 6, offset: 3 }}
          style={{ marginTop: "4rem" }}
        >
          <center>
            <img src={logoImg} style={{ width: "10em", marginBottom: "2em" }} />
          </center>
          <h3 className="title text-center" style={{ marginBottom: "0px" }}>
            Acesso Restrito
          </h3>
          <h4 className="text-center">
            Faça login para ter acesso à plataforma interna
          </h4>
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="usuario">Usuário</Label>
                  <Input
                    type="text"
                    name="usuario"
                    id="usuario"
                    placeholder="Digite seu usuário."
                    onChange={(e) => setUsuario(e.target.value)}
                    required
                  />
                  <Label for="senha">Senha</Label>
                  <Input
                    type="password"
                    name="senha"
                    id="senha"
                    minLength="5"
                    placeholder="Digite sua senha."
                    onChange={(e) => setSenha(e.target.value)}
                    required
                  />
                </FormGroup>
                <Button
                  color="primary"
                  type="submit"
                  block
                  disabled={autenticando}
                >
                  Acessar
                </Button>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </>
  );
}

export default Login;
