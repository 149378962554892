import React, { useState, useEffect } from "react";
// lida com as toast/alertas
import { toast } from "react-toastify";
// lida com as requisições
import { api } from "services/api";
// lidar com criptografias MD5
// eslint-disable-next-line no-undef
const md5 = require("md5");
import { decode as base64_decode } from "base-64";
// reactstrap components
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import { LogAirbrake } from "services/log.airbrake";

function ManipulaVerba(props) {
  const [tipoPagamento, setTipoPagamento] = useState("P");
  const [tipoTitulo, setTipoTitulo] = useState(null);
  const [valor, setValor] = useState(null);
  const [percentual, setPercentual] = useState(null);
  const [contrato, setContrato] = useState(null);
  const [modalExcluir, setModalExcluir] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const idUsuario = sessionStorage.getItem(md5("CODUSU"));
  const { getCabStatusSimulacao } = props;
  const setorUsuario = base64_decode(sessionStorage.getItem(md5("NUSETOR")));

  const toggleModal = (e) => {
    e.preventDefault();
    setModalExcluir(!modalExcluir);
  };

  useEffect(() => {
    if (props.update == true) {
      setTipoPagamento(props.getVerbaSel.TIPOPAGAMENTO);
      setValor(props.getVerbaSel.VALOR);
      setPercentual(props.getVerbaSel.PERCENTUAL);
      setContrato(props.getVerbaSel.NUSIMVER);
      setTipoTitulo(props.getVerbaSel.CODTIPTIT);
    }
    // console.log(deleting);
  }, [props.update]);

  const changeVerbaNull = (e) => {
    e.preventDefault();
    props.setVerbaSel([]);
  };

  const handleTipoPagamento = (tipoPag) => {
    if (tipoPag == "P") {
      setValor(0);
      setTipoPagamento(tipoPag);
    } else if (tipoPag == "V") {
      setPercentual(0);
      setTipoPagamento(tipoPag);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmiting(true);
    setDeleting(true);

    let raw = {
      nuSim: props.getCabNuSim,
      nuSimCli: props.getCabNuSimCli,
      tipoTitulo: tipoTitulo,
      tipoPagamento: tipoPagamento,
      verba: props.getVerbaSel.IDTIPONEG,
      valor: valor,
      percentual: percentual,
    };

    try {
      const response = props.update
        ? await api.put(
            `simulador-verba-manual/${idUsuario}/${props.getVerbaSel.NUSIMVER}`,
            raw
          )
        : await api.post(`simulador-verba-manual/${idUsuario}`, raw);
      if (response.data?.statusApi === 1) {
        // console.log(response.data);

        const { validacoes, simuladorVer } = response.data;
        let adendo = "";
        props.setValidacoesVerba(validacoes);
        props.setVerbas(simuladorVer);
        // props.setImc(imc_cli);
        //console.log(imc_cli);
        //console.log(props.setCabImcGeral);
        //console.log('_________________')
        //console.log(simuladorVer);
        //console.log(props.setValidacoesVerba)

        if (props.update === false) {
          props.setNovaVerba(); //para resetar o state e liberar para listagem
          props.setVerbaSel([]); //para resetar o state da verba selecionada para proxima busca
          // props.setCabImcGeral();
          adendo = "cadastrada";
        } else {
          adendo = "atualizada";
        }
        toast.success(
          `Verba "${props.getVerbaSel.IDTIPONEG} - ${props.getVerbaSel.DESCRNEG}" ${adendo} com sucesso.`
        );
      } else {
        //console.log(response.data);
        toast.error(response.data.message, {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        });
      }
    } catch (error) {
      LogAirbrake(error, {
        url: `simulador-verba-manual/${idUsuario}`,
        method: "POST",
        data: raw,
      });
      // console.error(error);
      toast.error(
        `Não foi possível conectar ao servidor, atualize a página e tente novamente. ${error.message}`,
        {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        }
      );
    }
    setSubmiting(false);
    setDeleting(false);
  };

  const deleteVerba = async (e) => {
    e.preventDefault();
    setSubmiting(true);
    setDeleting(true);
    try {
      const response = await api.delete(
        `simulador-verba-manual/${idUsuario}/${props.getVerbaSel.NUSIMVER}/${props.getCabNuSimCli}`
      );
      if (response.data?.statusApi == 1) {
        props.setValidacoesVerba(response.data.validacoes);
        props.setVerbas(response.data.simuladorVer);
        toggleModal;
        toast.success(
          `Verba "${props.getVerbaSel.IDTIPONEG} - ${props.getVerbaSel.DESCRNEG}" deletada com sucesso.`
        );
      } else {
        //console.log(response.data);
        toast.error(response.data.message, {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        });
      }
    } catch (error) {
      // console.error(error);
      toast.error(
        `Não foi possível conectar ao servidor, atualize a página e tente novamente. ${error.message}`,
        {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        }
      );
    }
    setSubmiting(false);
    setDeleting(false);
  };

  return (
    <>
      <Col style={{ padding: "15px" }}>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={12} style={{ display: "inline-flex" }}>
              {!props.update ? (
                <>
                  <Button color="primary" size="sm" onClick={changeVerbaNull}>
                    <i className="fa fa-retweet"></i>
                  </Button>
                  {` `}
                </>
              ) : null}
              <Input
                value={
                  props.getVerbaSel.IDTIPONEG +
                  " - " +
                  props.getVerbaSel.DESCRNEG
                }
                disabled
                style={!props.update ? { marginLeft: ".8em" } : null}
              />
            </Col>
            <Col md={6}>
              <Label for="contratoDoc">Contrato</Label>
              <Input
                type="number"
                min={0}
                value={contrato}
                // accept=".pdf,image/png, image/jpg, image/jpeg" //verificar a usuabilidade do arquivo para comprovação de contrato. isso usará muito espaço no servidor.
                id="contratoDoc"
                name="contratoDoc"
                disabled
              />
            </Col>
            <Col md={6}>
              <Label for="inputTipoTitulo">Tipo de Título</Label>
              <Input
                type="select"
                id="inputTipoTitulo"
                name="inputTipoTitulo"
                onChange={(e) => setTipoTitulo(e.target.value)}
                style={{ cursor: "pointer" }}
                disabled={
                  props.getCabStatusSimulacao != 0 || setorUsuario == "1"
                }
                required
              >
                <option value="">{"< Selecione um Tipo de Título >"}</option>
                {/* {//console.log(props.getTipoTitulo)} */}
                {props.getOptionTipoTitulo.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={item.CODTIPTIT}
                      selected={tipoTitulo == item.CODTIPTIT}
                    >
                      {item.CODTIPTIT + " - " + item.DESCRTIPTIT}
                    </option>
                  );
                })}
              </Input>
            </Col>
            <Col
              md={4}
              style={
                getCabStatusSimulacao != 0 || setorUsuario == "1"
                  ? { display: "none" }
                  : null
              }
            >
              <Label>Tipo de Pagamento</Label>
              <Col style={{ marginBottom: ".6em", padding: "0" }}>
                <FormGroup check inline className="form-check-radio">
                  <Label
                    className="form-check-label"
                    style={{ fontSize: "12px" }}
                  >
                    <Input
                      type="radio"
                      name="tipoPagamento"
                      id="tipoPagamentoPercentual"
                      onClick={() => handleTipoPagamento("P")}
                      defaultChecked={true}
                      checked={tipoPagamento == "P"}
                      disabled={
                        getCabStatusSimulacao != 0 || setorUsuario == "1"
                      }
                    />
                    % Percentual
                    <span className="form-check-sign"></span>
                  </Label>
                </FormGroup>
                <FormGroup check inline className="form-check-radio">
                  <Label
                    className="form-check-label"
                    style={{ fontSize: "12px" }}
                  >
                    <Input
                      type="radio"
                      name="tipoPagamento"
                      id="tipoPagamentoValor"
                      onClick={() => handleTipoPagamento("V")}
                      checked={tipoPagamento == "V"}
                      disabled={
                        getCabStatusSimulacao != 0 || setorUsuario == "1"
                      }
                    />
                    R$ Valor
                    <span className="form-check-sign"></span>
                  </Label>
                </FormGroup>
              </Col>
            </Col>
            <Col md={4}>
              <Label>% Percentual</Label>
              <Input
                type="number"
                min={0}
                step={0.01}
                value={percentual}
                onChange={(e) => setPercentual(e.target.value)}
                disabled={
                  getCabStatusSimulacao != 0 ||
                  tipoPagamento == "V" ||
                  setorUsuario == "1"
                }
                required={tipoPagamento == "P"}
              />
            </Col>
            <Col md={4}>
              <Label>R$ Valor:</Label>
              <Input
                type="number"
                min={0}
                step={0.01}
                value={valor}
                onChange={(e) => setValor(e.target.value)}
                required={tipoPagamento == "V"}
                disabled={
                  getCabStatusSimulacao != 0 ||
                  tipoPagamento == "P" ||
                  setorUsuario == "1"
                }
              />
            </Col>
          </Row>
          <Col
            style={
              getCabStatusSimulacao != 0 || setorUsuario == "1"
                ? { display: "none" }
                : { marginTop: "1em" }
            }
          >
            {props.update ? (
              <>
                <Button
                  disabled={getCabStatusSimulacao != 0 || setorUsuario == "1"}
                  type="submit"
                  className="float-right"
                  color="primary"
                  style={{ marginLeft: "1em" }}
                >
                  Alterar Verba
                </Button>
                <Button
                  disabled={
                    submiting ||
                    getCabStatusSimulacao != 0 ||
                    setorUsuario == "1"
                  }
                  className="btn-icon btn-round float-right"
                  color="danger"
                  onClick={toggleModal}
                >
                  <i className="fa fa-trash"></i>
                </Button>
              </>
            ) : (
              <Button
                disabled={
                  submiting || getCabStatusSimulacao != 0 || setorUsuario == "1"
                }
                type="submit"
                className="float-right"
                color="primary"
              >
                Salvar Verba
              </Button>
            )}
          </Col>
        </Form>
      </Col>
      {/* modal exluir verba manual */}
      <Modal
        isOpen={modalExcluir}
        toggle={toggleModal}
        backdrop="static"
        modalClassName="modal-black"
      >
        <div className="modal-header justify-content-center">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={toggleModal}
          >
            <i className="tim-icons icon-simple-remove"></i>
          </button>
          <h4>
            <b>Confirmação de Exclusão</b>
          </h4>
        </div>
        <ModalBody>
          <Col>
            <p>
              <b style={{ fontWeight: "bold" }}>Verba: </b>
              {props.getVerbaSel.IDTIPONEG + " - " + props.getVerbaSel.DESCRNEG}
            </p>
            <Col md={6} style={{ display: "inline-flex", padding: "0px" }}>
              <p>
                <b style={{ fontWeight: "bold" }}>Contrato: </b>
                {contrato}
              </p>
            </Col>
            <Col md={6} style={{ display: "inline-flex", padding: "0px" }}>
              <p>
                <b style={{ fontWeight: "bold" }}>Tipo Pagamento: </b>
                {tipoPagamento == "P" ? "Em Percentual" : "Em Valor"}
              </p>
            </Col>
            <Col md={6} style={{ display: "inline-flex", padding: "0px" }}>
              <p>
                <b style={{ fontWeight: "bold" }}>Percentual %: </b>
                {percentual > 0 && percentual != "" && percentual != null
                  ? parseFloat(percentual).toFixed(2)
                  : " - -"}
              </p>
            </Col>
            <Col md={6} style={{ display: "inline-flex", padding: "0px" }}>
              <p>
                <b style={{ fontWeight: "bold" }}>Valor R$: </b>
                {valor > 0 && valor != "" && valor != null
                  ? parseFloat(valor).toFixed(2)
                  : " - -"}
              </p>
            </Col>

            <Button className="float-let" color="danger" onClick={toggleModal}>
              Cancelar
            </Button>
            <Button
              disabled={getCabStatusSimulacao != 0 || setorUsuario == "1"}
              className="float-right"
              color="success"
              onClick={deleteVerba}
              // disabled={deleting}
            >
              Excluir
            </Button>
          </Col>
        </ModalBody>
      </Modal>
    </>
  );
}

export default ManipulaVerba;
