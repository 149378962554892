import React from "react";
import { CardBody, Col, Input, Label, Row } from "reactstrap";
// eslint-disable-next-line no-undef
const moment = require("moment");

export default class SimuladorVerbas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { verba, index } = this.props;
    return (
      <>
        <CardBody key={index}>
          <Row>
            <Col md={6}>
              <Label for={`parceiro-verba-${index}`}>Parceiro</Label>
              <Input
                value={`${verba.CODPARCVERBA} - ${verba.NOMEPARC}`}
                disabled
                id={`parceiro-verba-${index}`}
              />
            </Col>
            <Col md={3}>
              <Label for={`contrato-verba-${index}`}>Cotrato</Label>
              <Input
                value={verba.NUMCONTRATO}
                disabled
                id={`contrato-verba-${index}`}
              />
            </Col>
            <Col md={3}>
              <Label for={`tipo-inclusao-verba-${index}`}>
                Tipo de Inclusão
              </Label>
              <Input
                value={"AUTOMÁTICA"}
                disabled
                id={`tipo-inclusao-verba-${index}`}
              />
            </Col>
            <Col md={4}>
              <Label for={`tipo-pagamento-verba-${index}`}>
                Tipo de Pagamento (Cálculo)
              </Label>
              <Input
                type={"text"}
                value={verba.TIPOPAGAMENTO == "P" ? "Percentual" : "Valor"}
                disabled
                id={`tipo-pagamento-verba-${index}`}
              />
            </Col>
            <Col md={4}>
              <Label for={`percentual-verba-${index}`}>% Percentual</Label>
              <Input
                value={verba.PERCENTUAL}
                disabled
                id={`percentual-verba-${index}`}
              />
            </Col>
            <Col md={4}>
              <Label for={`valor-verba-${index}`}>R$ Valor</Label>
              <Input value={verba.VALOR} disabled id={`valor-verba-${index}`} />
            </Col>
            <Col md={4}>
              <Label for={`data-inicial-verba-${index}`}>Data Inicial</Label>
              <Input
                value={moment(verba.DATA_INICIAL).format("DD/MM/YYYY")}
                disabled
                id={`data-inicial-verba-${index}`}
              />
            </Col>
            <Col md={4}>
              <Label for={`data-final-verba-${index}`}>Data Final</Label>
              <Input
                value={moment(verba.DATA_FINAL).format("DD/MM/YYYY")}
                disabled
                id={`data-final-verba-${index}`}
              />
            </Col>
            <Col md={4}>
              <Label for={`tipo-titulo-verba-${index}`}>Tipo de Título</Label>
              <Input
                value={verba.CODTIPTIT + " - " + verba.DESCRTIPTIT}
                disabled
                id={`tipo-titulo-verba-${index}`}
              />
            </Col>
          </Row>
        </CardBody>
      </>
    );
  }
}
