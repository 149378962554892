export const formatCpfCnpj = (data) => {
  if (data != undefined) {
    if (data.length == 14) {
      return data.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
    } else if (data.length == 7) {
      return data.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else {
      return data;
    }
  } else {
    return data;
  }
};

export const formatPhone = (data) => {
  if (data != undefined) {
    if (data.length == 13) {
      return data.replace(/^(\d{2})(\d{2})(\d{5})(\d{4})/, "+$1 ($2) $3-$4");
    }
    if (data.length == 12) {
      return data.replace(/^(\d{2})(\d{2})(\d{4})(\d{4})/, "+$1 ($2) $3-$4");
    } else if (data.length == 9) {
      return data.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    } else if (data.length == 8) {
      return data.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    } else {
      return data;
    }
  } else {
    return data;
  }
};

export const formatValue = (style, value) => {
  // "compact" | "currency" | "decimal" | "exponentInteger" |
  // "exponentMinusSign" | "exponentSeparator" | "fraction" |
  // "group" | "infinity" | "integer" | "literal" | "minusSign" |
  // "nan" | "plusSign" | "percentSign" | "unit" | "unknown"
  return Intl.NumberFormat("pt-BR", { style: style, currency: "BRL" }).format(
    value
  );
};
export const formatValue2 = (style, value) => {
  // "compact" | "currency" | "decimal" | "exponentInteger" |
  // "exponentMinusSign" | "exponentSeparator" | "fraction" |
  // "group" | "infinity" | "integer" | "literal" | "minusSign" |
  // "nan" | "plusSign" | "percentSign" | "unit" | "unknown"
  return Intl.NumberFormat("pt-BR", {
    style: style,
    currency: "BRL",
    minimumFractionDigits: 2,
  }).format(value);
};
