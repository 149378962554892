import React from "react";
// reactstrap components
import { Card, CardHeader, CardBody, Collapse } from "reactstrap";

export default class CollapseValidaProduto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openedCollapses: [],
    };
  }
  collapsesToggle(collapse) {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: [],
      });
    } else {
      this.setState({
        openedCollapses: [collapse],
      });
    }
  }
  render() {
    const {
      //////// GET VALIDATION
      cabValidacaoProduto,
    } = this.props;

    return (
      <div
        aria-multiselectable={true}
        className="card-collapse"
        id="accordion-produto"
        role="tablist"
      >
        {cabValidacaoProduto.map((validaProduto, index) => {
          const { CODPROD, DESCRPROD, VALIDACAO } = validaProduto;
          return (
            <Card
              key={index}
              className="card-plain"
              style={{
                marginBottom: "0px",
                borderBottom: "1px solid rgb(76 79 93)",
              }}
            >
              <CardHeader role="tab">
                <a
                  aria-expanded={this.state.openedCollapses.includes(
                    "produto-" + CODPROD
                  )}
                  style={{ cursor: "pointer" }}
                  data-parent="#accordion-produto"
                  data-toggle="collapse"
                  onClick={(e) => {
                    e.preventDefault();
                    this.collapsesToggle("produto-" + CODPROD);
                  }}
                >
                  {CODPROD + " - " + DESCRPROD}{" "}
                  <i className="tim-icons icon-minimal-down" />
                </a>
              </CardHeader>
              <Collapse
                role="tabpanel"
                isOpen={this.state.openedCollapses.includes(
                  "produto-" + CODPROD
                )}
              >
                <CardBody>
                  {VALIDACAO.map((item, index) => {
                    const { message, solution, value } = item;
                    return (
                      <div key={index}>
                        <p>
                          <b>Descrição:</b> {message}
                        </p>
                        <p>
                          <b>Solução:</b> {solution}
                        </p>
                        {value != null && value != "" ? (
                          <p>
                            <b>Valor informado:</b> {value}
                          </p>
                        ) : null}
                      </div>
                    );
                  })}
                </CardBody>
              </Collapse>
            </Card>
          );
        })}
      </div>
    );
  }
}
