import React, { useState } from "react";
import { toast } from "react-toastify";
// lida com as requisições
import { api } from "services/api";
// lidar com criptografias Base64
import { decode as base64_decode } from "base-64";
// lidar com criptografias MD5
// eslint-disable-next-line no-undef
const md5 = require("md5");
//lida com components
import { SimuladorPDF } from "Reports/Simulador/SimuladorPDF";
import ModalLoading from "components/Loading/ModalLoading";
// const logoImg = require("assets/img/logo.png");

import { getMessageFromApiResponse } from "../../../services/utils";
// reactstrap components
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { SimuladorPDFFolhaDeRosto } from "../../../Reports/Simulador/SimuladorPDFFolhaDeRosto";
import { useProductContext } from "contexts/ProdutosSimuladorContext";
import { useSimuladorContext } from "./../../../contexts/SimuladorContext";
import { LogAirbrake } from "services/log.airbrake";
import { apiNode } from "services/apiNode";

function MenuSimulador(props) {
  const setorUsuario = base64_decode(sessionStorage.getItem(md5("NUSETOR")));

  const {
    dataProducts,
    setDataProducts,
    setM3Cliente,
    setM3Simulador,
    setVlrFaturado,
  } = useProductContext();
  const { handleUpdateSimuladorCliente, dataSimulador } = useSimuladorContext();

  const idUsuario = sessionStorage.getItem(md5("CODUSU"));
  const verificaTipoVend = sessionStorage.getItem(md5("TIPVEND"));
  const [loading, setLoading] = useState(false);

  const {
    getCabNuSim,
    getCabNuSimCli,
    getCabStatusSimulacao,
    handleSetCabStatusSimulacao,
    handleSetCabResume,
    handleSetCabProduto,
    handleModalListaSimCli,
    handleAddSimCli,
  } = props;

  const handleUpdateSimuladorItem = async () => {
    try {
      const codVend = base64_decode(sessionStorage.getItem(md5("CODVEND")));

      localStorage.setItem(
        "cache-produtos",
        JSON.stringify(
          dataProducts.filter((product) => Number(product.QTDNEG) > 0)
        )
      );

      const dataProductsUpdated = dataProducts.map((product) =>
        Number(product.QTDNEG) > 0
          ? { ...product, updated: true }
          : { ...product }
      );

      let rawItensSimulador = {
        nuSim: getCabNuSim,
        nuSimCli: getCabNuSimCli,
        produtos: dataProductsUpdated.map((product) => ({
          ...product,
          QTDNEG: Number(product.QTDNEG),
          PRECONEG: Number(product.PRECONEG),
          M3: Number(product.M3),
          M3TOTAL: Number(product.M3TOTAL),
          PESOBRUTO: Number(product.PESOBRUTO),
          PESOBRUTOTOTAL: Number(product.PESOBRUTOTOTAL),
          CUSTOUNPRODUCAO: Number(product.CUSTOUNPRODUCAO),
          MC: Number(product.MC),
          MCT: Number(product.MCT),
          IMC_ITEM: Number(product.IMC_ITEM),
          PRECOMIN: Number(product.PRECOMIN),
          PRECOMAX: Number(product.PRECOMAX),
          VLRTABPADRAO: Number(product.VLRTABPADRAO),
          VLRTAB: Number(product.VLRTAB),
          VLRTABTRANSF: Number(product.VLRTABTRANSF),
          VLRTOTAL: Number(product.VLRTOTAL),
          PERCRCA: Number(product.PERCRCA),
          PERCRCAMAX: Number(product.PERCRCAMAX),
          VLRCOMISSAORCA: Number(product.VLRCOMISSAORCA),
          CREDICMS_PER: Number(product.CREDICMS_PER),
          CREDICMS_TOTAL: Number(product.CREDICMS_TOTAL),
          DEBICMS_TOTAL: Number(product.DEBICMS_TOTAL),
          DEBICMS_PER: Number(product.DEBICMS_PER),
          NUSIMITE: Number(product.NUSIMITE),
          NUSIM: Number(product.NUSIM),
          NUSIMCLI: Number(product.NUSIMCLI),
          CODPROD: Number(product.CODPROD),
          CODGRUPAI: Number(product.CODGRUPAI),
        })),
        codVend: codVend,
      };

      // console.log("rawItensSimulador", rawItensSimulador);

      const response = await api.put(
        `simulador-item/${idUsuario}`,
        rawItensSimulador
      );

      // console.log(rawItensSimulador);

      // console.log("MenuSimulador:", response);

      if (response.data?.statusApi == 1) {
        const {
          simuladorIte,
          imc_cli,
          M3_CLI,
          M3_SIM,
          VLR_FATURAMENTO,
          resume,
          validaCliente,
          validaFrete,
          validaProdutos,
          validaVerbas,
        } = response.data;

        setDataProducts([...simuladorIte]);
        setM3Cliente(M3_CLI);
        setM3Simulador(M3_SIM);
        setVlrFaturado(VLR_FATURAMENTO);
        props.handleSetCabImcGeral(imc_cli);
      }
    } catch (error) {
      //console.log("erro ao atualizar itens do simulador:", error);
      setLoading(false);
    }
  };

  const handleGeraPedido = async (pNuSimCli) => {
    setLoading(true);
    const salvouSimulador = await handleUpdateSimuladorCliente();
    if (!salvouSimulador) {
      setLoading(false);
      return;
    }

    if (dataSimulador?.CODEMP == 13 || dataSimulador?.CODEMP == 14) {
      await apiNode.put(`simulator/simulador/${dataSimulador.NUSIM}`, {
        STATUS: 3,
      });

      location.reload(true);
      // toast.warn("Filial não está habilitada para gerar pedidos.", {
      //   autoClose: 7000,
      // });
      setLoading(false);
      return;
    }

    if (dataSimulador?.AD_PROSP === "S") {
      toast.warn("O cliente está em prospecção, não é possível gerar pedido", {
        autoClose: 7000,
      });
      setLoading(false);
      return;
    }

    await handleUpdateSimuladorItem();

    let raw = {
      nuSim: getCabNuSim,
      nuSimCli: pNuSimCli,
    };
    console.log(raw);
    try {
      const response = await api.post(
        `simulador-gera-pedido/${idUsuario}`,
        raw,
        { timeout: 600000 }
      );

      if (response.data?.statusApi == 1) {
        // //console.log(response.data.simuladorVer);
        // //console.log("log api response:", response);
        const { avisos, simulador, simuladorIte, resume } = response.data;

        handleSetCabProduto(simuladorIte);
        handleSetCabResume(resume);
        handleSetCabStatusSimulacao(simulador.STATUS);
        toast.success("Pedido gerado com sucesso.", { autoClose: 7000 });
        //console.log(response.data);
        toast.warn(avisos, { autoClose: 7000 });
      } else {
        //console.log(response.data);
        // //console.log(response.data.responseError.response);

        const mensagemTraduzida = getMessageFromApiResponse(
          response?.data?.responseError?.response
        );

        // const parser = new DOMParser();
        // const xmlDoc = parser.parseFromString(
        //   response.data.responseError.response,
        //   "text/xml"
        // );

        // const mensagemTraduzida = atob(
        //   xmlDoc.getElementsByTagName("statusMessage")[0].childNodes[0]
        //     .nodeValue
        // );

        // //console.log(mensagemTraduzida);

        toast.error(response.data.message + " \n " + mensagemTraduzida, {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        });
        setLoading(false);
      }
    } catch (error) {
      LogAirbrake(error, {
        url: `simulador-gera-pedido/${idUsuario}`,
        method: "POST",
        data: raw,
      });

      setLoading(false);
      toast.error(
        `Não foi possível conectar ao servidor, atualize a página e tente novamente. ${error.message}`,
        {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        }
      );
    }
    setLoading(false);
  };

  const gerarPDF = async (cabNuSim, cabNuSimCli) => {
    // console.log("getCabStatusSimulacao", getCabStatusSimulacao);
    if (getCabStatusSimulacao != 3) {
      setLoading(true);
      await handleUpdateSimuladorItem();
      const salvouSimulador = await handleUpdateSimuladorCliente();
      setLoading(false);
      if (!salvouSimulador) return;
    }
    let catNusim2 = cabNuSim;
    if (!catNusim2)
      catNusim2 = base64_decode(sessionStorage.getItem(md5("SIMULADOR_NUSIM")));

    setLoading(true);
    SimuladorPDF(catNusim2, cabNuSimCli);
    setLoading(false);
  };

  // const gerarPDF = async (cabNuSim, cabNuSimCli) => {
  //   try {
  //

  //     const response = await api.get(
  //       `simulador/print/${sessionStorage.getItem(
  //         md5("CODUSU")
  //       )}/${cabNuSim}/${cabNuSimCli}`
  //     );
  //     // SimuladorPDF(simulador, simuladorCli, simuladorIte, simuladorVer);
  //     //console.log(response.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // //console.log(logoImg);

  const decodeTipoVend = window.atob(verificaTipoVend);

  // //console.log("---------->", getCabStatusSimulacao);

  // let gerapedido;
  // if (decodeTipoVend == "S") {
  const gerapedido = (
    <>
      <DropdownItem divider />
      <DropdownItem
        disabled={
          (getCabStatusSimulacao != 0 &&
            getCabStatusSimulacao != 2 &&
            setorUsuario != "1") ||
          setorUsuario == "1"
        }
        onClick={() => handleGeraPedido()}
      >
        Gerar Pedido
      </DropdownItem>
      {/* <DropdownItem
        disabled={
          (getCabStatusSimulacao != 0 &&
            getCabStatusSimulacao != 2 &&
            setorUsuario != "1") ||
          setorUsuario == "1"
        }
        onClick={() => handleGeraPedido(getCabNuSimCli)}
      >
        Gerar Pedido - {getCabNuSimCli}
      </DropdownItem> */}
    </>
  );
  // }

  return (
    <>
      <ModalLoading
        loading={loading}
        mensagem={
          "Verificando informações do simulador para gerar o pedido de venda."
        }
      />
      <UncontrolledDropdown group>
        <DropdownToggle
          caret
          color="primary"
          data-toggle="dropdown"
          style={{ padding: "5px", marginLeft: "1em" }}
        >
          <i className="fa fa-cog" /> Ações
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={() =>
              handleModalListaSimCli(
                base64_decode(sessionStorage.getItem(md5("SIMULADOR_NUSIM")))
              )
            }
          >
            Clientes da Simulação
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem
            disabled={getCabStatusSimulacao != 0}
            onClick={() => handleAddSimCli()}
          >
            Adicionar Cliente
          </DropdownItem>

          {gerapedido}
          {/* <DropdownItem>Calcular ..Y..</DropdownItem> */}
          <DropdownItem divider />
          <DropdownItem disabled={getCabStatusSimulacao != 0}>
            Excluir Cliente
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={() => gerarPDF(getCabNuSim, getCabNuSimCli)}>
            Imprimir
          </DropdownItem>
          <DropdownItem onClick={() => SimuladorPDFFolhaDeRosto(getCabNuSim)}>
            Folha de Rosto
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
}

export default MenuSimulador;
