import { useGlobalContext } from "contexts/GlobalContext";
import { useSimuladorContext } from "contexts/SimuladorContext";
import React, { useEffect, useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { enterpriseUseDelivery } from "services/utils";

export default function TipoLogistica(props) {
  const { dataSimulador, setDataSimulador } = useSimuladorContext();
  const { globalContext } = useGlobalContext();
  const [showThis, setShowThis] = useState(false);

  useEffect(() => {
    setShowThis(
      (!enterpriseUseDelivery(dataSimulador.CODEMP) &&
        dataSimulador?.comLogistica) ||
        props?.showByTipoEmprega
    );
  }, [dataSimulador]);

  const handleChangeOperadorLogistico = (value) => {
    if (!value) {
      setDataSimulador((state) => ({
        ...state,
        NROREG: null,
        PERLOGISTICA: null,
        VLRFRETE: null,
      }));

      return;
    }

    const selectedOperadorLogistico = globalContext?.opLogistica?.find(
      (item) => item.NROREG == value
    );

    setDataSimulador((state) => ({
      ...state,
      NROREG: selectedOperadorLogistico.NROREG,
      PERLOGISTICA: selectedOperadorLogistico.PERCENTUAL,
      VLRFRETE: selectedOperadorLogistico.VALOR,
    }));
  };

  return (
    <>
      {showThis && (
        <FormGroup>
          <Label for="operadorLogistico">Logística</Label>
          <Input
            type="select"
            id="operadorLogistico"
            name="operadorLogistico"
            onChange={(e) => handleChangeOperadorLogistico(e.target.value)}
            style={!dataSimulador?.comLogistica ? null : { cursor: "pointer" }}
            required={!enterpriseUseDelivery(dataSimulador.CODEMP)}
            disabled={
              (!dataSimulador?.comLogistica ||
                globalContext?.simulador?.STATUS != 0 ||
                globalContext.setorUsuario == 1) &&
              !props?.showByTipoEmprega
            }
          >
            <option value="">{"< Selecione uma logística >"}</option>
            {globalContext?.opLogistica != null
              ? globalContext?.opLogistica.map((logistica, index) => {
                  return (
                    <option
                      key={index}
                      value={logistica.NROREG}
                      selected={dataSimulador?.NROREG == logistica.NROREG}
                    >
                      {logistica.NUMCONTRATO + " - " + logistica.OPERADORLOG}
                    </option>
                  );
                })
              : null}
          </Input>
        </FormGroup>
      )}
    </>
  );
}
