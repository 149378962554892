import { createContext, useContext, useState, useEffect } from "react";
import React from "react";
import { decode as base64_decode } from "base-64";
const md5 = require("md5");
export const SimuladorContext = createContext();
import { api } from "../services/api";
import { toast } from "react-toastify";
import { LogAirbrake } from "services/log.airbrake";
import { enterpriseUseDelivery } from "services/utils";
import { proxyApi } from "services/proxyApi";

export const SimuladorContextProvider = ({ children }) => {
  const [dataSimulador, setDataSimulador] = useState({});

  useEffect(() => {
    // console.log(dataSimulador);
    if (
      dataSimulador?.comLogistica == undefined &&
      dataSimulador?.TIPOFAT > 0
    ) {
      setDataSimulador((state) => ({
        ...state,
        comLogistica: state.NROREG > 0,
      }));
    }
  }, [dataSimulador]);

  const idUsuario = sessionStorage.getItem(md5("CODUSU"));

  const handleUpdateSimuladorCliente = async (props) => {
    let nuSim = base64_decode(sessionStorage.getItem(md5("SIMULADOR_NUSIM")));

    if (!(nuSim > 0)) {
      nuSim = await props?.criaSimulador();
    }

    const dataSim = {
      codUser: base64_decode(sessionStorage.getItem(md5("CODUSU"))),
      codVend: base64_decode(sessionStorage.getItem(md5("CODVEND"))),
      nuSim: nuSim,
      nuSimCli: base64_decode(
        sessionStorage.getItem(md5("SIMULADOR_NUSIMCLI"))
      ),
      codParc: dataSimulador.CODPARC,
      codEmp: dataSimulador.CODEMP,
      nroReg:
        dataSimulador?.TIPOENTREGA > 0 && dataSimulador?.TIPOENTREGA != 1
          ? null
          : dataSimulador?.NROREG,
      tipoPagDescarga: dataSimulador?.TIPOPAGDESCARGA,
      tipoFaturamento: dataSimulador?.TIPOFAT,
      vlrDegustacao: dataSimulador?.DEGUSTACAO,
      vlrDescarga: dataSimulador?.DESCARGA,
      observacao: dataSimulador?.OBSERVACAO,
      ordemCompra: dataSimulador?.ORDEMCOMPRA,
      codTipVenda: dataSimulador?.CODTIPNEG,
      descrTipVenda: dataSimulador?.DESCRTIPVENDA,
      prazoMedio: dataSimulador?.PRAZOMEDIO,
      cargaCompartilhada: dataSimulador?.CARGACOMPARTILHADA,
    };

    try {
      if (
        dataSimulador.CODEMP != 6 &&
        dataSimulador?.comLogistica &&
        !dataSimulador.NROREG
      ) {
        throw new Error(
          "É obrigatório informar uma logística para o tipo de faturamento selecionado!"
        );
      }

      if (
        !enterpriseUseDelivery(dataSimulador.CODEMP) &&
        (!dataSimulador.TIPOFAT || dataSimulador.TIPOFAT == 0)
      ) {
        throw new Error("É obrigatório informar um tipo de faturamento!");
      }

      if (
        enterpriseUseDelivery(dataSimulador.CODEMP) &&
        !dataSimulador.TIPOENTREGA
      ) {
        throw new Error("É obrigatório informar um tipo de entrega!");
      }

      if (!dataSimulador?.CODTIPNEG) {
        throw new Error("É obrigatório informar um tipo de negociação!");
      }

      const dataSimuladorParsed = {
        ...dataSim,
        codUser: Number(dataSim?.codUser),
        codVend: Number(dataSim?.codVend),
        nuSim: Number(dataSim?.nuSim),
        nuSimCli: Number(dataSim?.nuSimCli),
        codParc: Number(dataSim?.codParc),
        codEmp: Number(dataSim?.codEmp),
        prazoMedio: Number(dataSim?.prazoMedio),
        codTipVenda: Number(dataSim?.codTipVenda),
        tipoFaturamento: Number(dataSim?.tipoFaturamento),
        vlrDegustacao: Number(dataSim?.vlrDegustacao),
        vlrDescarga: Number(dataSim?.vlrDescarga),
        TIPOENTREGA: dataSimulador?.TIPOENTREGA
          ? Number(dataSimulador?.TIPOENTREGA)
          : null,
        ...(dataSimulador?.STATUS
          ? { status: Number(dataSimulador.STATUS) }
          : {}),
        VLR_ENTREGA:
          dataSimulador?.VLR_ENTREGA && Number(dataSimulador?.VLR_ENTREGA) > 0
            ? Number(dataSimulador?.VLR_ENTREGA)
            : null,
        PER_ENTREGA:
          dataSimulador?.PER_ENTREGA && Number(dataSimulador?.PER_ENTREGA) > 0
            ? Number(dataSimulador?.PER_ENTREGA)
            : null,
      };

      // console.log("dataSimuladorParsed@@##", dataSimuladorParsed);
      let res;
      if (dataSimuladorParsed?.nuSimCli > 0) {
        res = await api.put(
          `simulador-cliente/${idUsuario}`,
          dataSimuladorParsed
        );
      } else {
        res = await proxyApi.post(
          `simulador-cliente/${idUsuario}`,
          dataSimuladorParsed
        );
      }

      // console.log(res.data.imc.IMC_CLI);
      setDataSimulador((state) => ({
        ...state,
        IMC_CLI: res?.data?.imc?.IMC_CLI,
      }));

      return res;
    } catch (error) {
      LogAirbrake(error, {
        url: `simulador-cliente/${idUsuario}`,
        method: "PUT",
        data: dataSim,
      });

      toast.error(error.message, {
        autoClose: 7000,
        icon: () => <i className="tim-icons icon-simple-remove" />,
      });
      return false;
    }
  };

  return (
    <SimuladorContext.Provider
      value={{
        dataSimulador,
        setDataSimulador,
        handleUpdateSimuladorCliente,
      }}
    >
      {children}
    </SimuladorContext.Provider>
  );
};

export const useSimuladorContext = () => {
  const context = useContext(SimuladorContext);

  if (!context) {
    throw new Error("useFiles must be used within FileProvider");
  }

  return context;
};
